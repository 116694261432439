@import url(https://fonts.googleapis.com/css?family=Asap:400italic,700italic);
@import url(https://fonts.googleapis.com/css?family=Oxygen:700,300);
* {
  box-sizing: border-box;
}
/* html {
  background-color: #f0f0f0;
  color: #222222;
}
body {
  margin: 0;
} */
#gameboy {
  position: relative;
  width: 340px;
  height: 570px;
  overflow: hidden;
  margin: 40px auto;
  line-height: 1;
  border-radius: 15px 15px 170px 170px / 15px 15px 35px 35px;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-mask-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC);
}
#gameboy:hover div {
  opacity: 0.7;
}


#gameboy:hover div[id^="screen-"][id$="-text"] {
  opacity: 0;
}
#gameboy:hover div:not([id="speaker-holes"]):not([id^="cover-"]):not([id="power"]):not([class="capacitors"]):not([id$="-text"]):not([id="canvas"]):not([class="circuit"]):not([id="transistors"]):not([id="joystick-pad"]):not([id="control"]):not([id="start-select-button"]) {
  box-shadow: 0 0 0 2px #222222;
}
#gameboy:hover #cover-vertical,
#gameboy:hover #cover-horizontal {
  opacity: 0.1;
}
#gameboy:hover #gloss {
  opacity: 0.2;
}
#gameboy.transparent:hover #cover-vertical,
#gameboy.transparent:hover #cover-horizontal {
  opacity: 0.4;
}
#gameboy.transparent:hover #gloss {
  opacity: 0.6;
}
@-moz-keyframes canvas {
  0% {
    width: 0;
    height: 120px;
    border-radius: 15px 15px 0 0;
  }
  3% {
    width: 340px;
    height: 120px;
  }
  5.5% {
    height: 520px;
  }
  6.0% {
    border-radius: 15px 15px 0 0;
  }
  7.0% {
    height: 570px;
    border-radius: 15px 15px 170px 170px / 15px 15px 35px 35px;
  }
}
@-webkit-keyframes canvas {
  0% {
    width: 0;
    height: 120px;
    border-radius: 15px 15px 0 0;
  }
  3% {
    width: 340px;
    height: 120px;
  }
  5.5% {
    height: 520px;
  }
  6.0% {
    border-radius: 15px 15px 0 0;
  }
  7.0% {
    height: 570px;
    border-radius: 15px 15px 170px 170px / 15px 15px 35px 35px;
  }
}
@keyframes canvas {
  0% {
    width: 0;
    height: 120px;
    border-radius: 15px 15px 0 0;
  }
  3% {
    width: 340px;
    height: 120px;
  }
  5.5% {
    height: 520px;
  }
  6.0% {
    border-radius: 15px 15px 0 0;
  }
  7.0% {
    height: 570px;
    border-radius: 15px 15px 170px 170px / 15px 15px 35px 35px;
  }
}
#canvas {
  position: absolute;
  width: 340px;
  height: 570px;
  top: 0;
  right: 0;
  border-radius: 15px 15px 170px 170px / 15px 15px 35px 35px;
  background-color: #b0b0b0;
  -webkit-animation: canvas 8s  linear;
  animation: canvas 8s  linear;
  z-index: 1;
}
@-moz-keyframes border {
  0% {
    border-width: 0;
    border-color: transparent;
    width: 0;
    height: 0;
  }
  1% {
    border-width: 4px;
    border-top-color: #808080;
    width: 0;
    height: 10px;
  }
  3% {
    width: 318px;
    border-left-color: transparent;
    height: 10px;
  }
  3.3% {
    width: 318px;
    border-left-color: #808080;
    height: 10px;
  }
  7.0% {
    border-radius: 15px 15px 0 0;
  }
  7.3% {
    height: 526px;
    border-radius: 15px 15px 170px 170px / 15px 15px 35px 35px;
  }
  7.4% {
    border-bottom-color: transparent;
  }
  7.5% {
    border-bottom-color: #808080;
  }
  8.9% {
    border-right-color: transparent;
  }
  9% {
    border-right-color: #808080;
  }
}
@-webkit-keyframes border {
  0% {
    border-width: 0;
    border-color: transparent;
    width: 0;
    height: 0;
  }
  1% {
    border-width: 4px;
    border-top-color: #808080;
    width: 0;
    height: 10px;
  }
  3% {
    width: 318px;
    border-left-color: transparent;
    height: 10px;
  }
  3.3% {
    width: 318px;
    border-left-color: #808080;
    height: 10px;
  }
  7.0% {
    border-radius: 15px 15px 0 0;
  }
  7.3% {
    height: 526px;
    border-radius: 15px 15px 170px 170px / 15px 15px 35px 35px;
  }
  7.4% {
    border-bottom-color: transparent;
  }
  7.5% {
    border-bottom-color: #808080;
  }
  8.9% {
    border-right-color: transparent;
  }
  9% {
    border-right-color: #808080;
  }
}
@keyframes border {
  0% {
    border-width: 0;
    border-color: transparent;
    width: 0;
    height: 0;
  }
  1% {
    border-width: 4px;
    border-top-color: #808080;
    width: 0;
    height: 10px;
  }
  3% {
    width: 318px;
    border-left-color: transparent;
    height: 10px;
  }
  3.3% {
    width: 318px;
    border-left-color: #808080;
    height: 10px;
  }
  7.0% {
    border-radius: 15px 15px 0 0;
  }
  7.3% {
    height: 526px;
    border-radius: 15px 15px 170px 170px / 15px 15px 35px 35px;
  }
  7.4% {
    border-bottom-color: transparent;
  }
  7.5% {
    border-bottom-color: #808080;
  }
  8.9% {
    border-right-color: transparent;
  }
  9% {
    border-right-color: #808080;
  }
}
#border {
  position: absolute;
  width: 318px;
  height: 526px;
  right: 11px;
  top: 20px;
  border: 4px #808080 solid;
  border-radius: 15px 15px 170px 170px / 15px 15px 35px 35px;
  z-index: 2;
  -webkit-animation: border 8s   linear;
  animation: border 8s   linear;
}
#border-top,
#border-bottom {
  position: absolute;
  background-color: #b0b0b0;
  width: 0;
  height: 8px;
  z-index: 3;
}
#border-left,
#border-right {
  position: absolute;
  background-color: #b0b0b0;
  height: 0;
  z-index: 3;
}
@-moz-keyframes border-top {
  0.5% {
    width: 0;
  }
  3.0% {
    width: 318px;
    right: 11px;
  }
  3.5% {
    width: 318px;
    right: 11px;
  }
  5% {
    width: 0;
    right: 309px;
  }
}
@-webkit-keyframes border-top {
  0.5% {
    width: 0;
  }
  3.0% {
    width: 318px;
    right: 11px;
  }
  3.5% {
    width: 318px;
    right: 11px;
  }
  5% {
    width: 0;
    right: 309px;
  }
}
@keyframes border-top {
  0.5% {
    width: 0;
  }
  3.0% {
    width: 318px;
    right: 11px;
  }
  3.5% {
    width: 318px;
    right: 11px;
  }
  5% {
    width: 0;
    right: 309px;
  }
}
#border-top {
  top: 20px;
  right: 11px;
  -webkit-animation: border-top 8s  infinite linear;
  animation: border-top 8s  infinite linear;
}
@-moz-keyframes border-left {
  0% {
    height: 0;
  }
  3% {
    height: 0;
  }
  5% {
    height: 326px;
    top: 20px;
  }
  7.5% {
    height: 0;
    top: 546px;
  }
}
@-webkit-keyframes border-left {
  0% {
    height: 0;
  }
  3% {
    height: 0;
  }
  5% {
    height: 326px;
    top: 20px;
  }
  7.5% {
    height: 0;
    top: 546px;
  }
}
@keyframes border-left {
  0% {
    height: 0;
  }
  3% {
    height: 0;
  }
  5% {
    height: 326px;
    top: 20px;
  }
  7.5% {
    height: 0;
    top: 546px;
  }
}
#border-left {
  left: 11px;
  top: 20px;
  width: 20px;
  -webkit-animation: border-left 8s  infinite linear;
  animation: border-left 8s infinite  linear;
}
@-moz-keyframes border-bottom {
  0% {
    width: 0;
  }
  6.5% {
    width: 0;
  }
  7.4% {
    width: 318px;
    left: 11px;
  }
  9.0% {
    width: 0;
    left: 329px;
  }
}
@-webkit-keyframes border-bottom {
  0% {
    width: 0;
  }
  6.5% {
    width: 0;
  }
  7.4% {
    width: 318px;
    left: 11px;
  }
  9.0% {
    width: 0;
    left: 329px;
  }
}
@keyframes border-bottom {
  0% {
    width: 0;
  }
  6.5% {
    width: 0;
  }
  7.4% {
    width: 318px;
    left: 11px;
  }
  9.0% {
    width: 0;
    left: 329px;
  }
}
#border-bottom {
  top: 516px;
  left: 11px;
  height: 30px;
  -webkit-animation: border-bottom 8s  infinite linear;
  animation: border-bottom 8s infinite  linear;
}
@-moz-keyframes border-right {
  0% {
    height: 0;
  }
  8% {
    height: 0;
  }
  8.5% {
    height: 496px;
    top: 26px;
  }
  9% {
    height: 496px;
  }
  11.5% {
    height: 0;
    top: 26px;
  }
  100% {
    top: 546px;
  }
}
@-webkit-keyframes border-right {
  0% {
    height: 0;
  }
  8% {
    height: 0;
  }
  8.5% {
    height: 496px;
    top: 26px;
  }
  9% {
    height: 496px;
  }
  11.5% {
    height: 0;
    top: 26px;
  }
  100% {
    top: 546px;
  }
}
@keyframes border-right {
  0% {
    height: 0;
  }
  8% {
    height: 0;
  }
  8.5% {
    height: 496px;
    top: 26px;
  }
  9% {
    height: 496px;
  }
  11.5% {
    height: 0;
    top: 26px;
  }
  100% {
    top: 546px;
  }
}
#border-right {
  right: 11px;
  top: 26px;
  width: 8px;
  -webkit-animation: border-right 8s  infinite linear;
  animation: border-right 8s  infinite linear;
}
.screw {
  border-radius: 100%;
  background-color: #222222;
  z-index: 4;
  position: absolute;
}
.screw.small {
  width: 18px;
  height: 18px;
  top: 34px;
}
.screw.large {
  width: 20px;
  height: 20px;
  top: 235px;
}
@-moz-keyframes screw-small-right {
  0% {
    width: 0;
    height: 0;
    right: 34px;
    top: 43px;
  }
  2.5% {
    width: 0;
    height: 0;
    right: 34px;
    top: 43px;
  }
  5.5% {
    width: 18px;
    height: 18px;
    right: 25px;
    top: 34px;
  }
}
@-webkit-keyframes screw-small-right {
  0% {
    width: 0;
    height: 0;
    right: 34px;
    top: 43px;
  }
  2.5% {
    width: 0;
    height: 0;
    right: 34px;
    top: 43px;
  }
  5.5% {
    width: 18px;
    height: 18px;
    right: 25px;
    top: 34px;
  }
}
@keyframes screw-small-right {
  0% {
    width: 0;
    height: 0;
    right: 34px;
    top: 43px;
  }
  2.5% {
    width: 0;
    height: 0;
    right: 34px;
    top: 43px;
  }
  5.5% {
    width: 18px;
    height: 18px;
    right: 25px;
    top: 34px;
  }
}
#screw-small-right {
  right: 25px;
  -webkit-animation: screw-small-right 8s   linear;
  animation: screw-small-right 8s   linear;
}
@-moz-keyframes screw-small-left {
  0% {
    width: 0;
    height: 0;
    left: 34px;
    top: 43px;
  }
  3% {
    width: 0;
    height: 0;
    left: 34px;
    top: 43px;
  }
  6% {
    width: 18px;
    height: 18px;
    left: 25px;
    top: 34px;
  }
}
@-webkit-keyframes screw-small-left {
  0% {
    width: 0;
    height: 0;
    left: 34px;
    top: 43px;
  }
  3% {
    width: 0;
    height: 0;
    left: 34px;
    top: 43px;
  }
  6% {
    width: 18px;
    height: 18px;
    left: 25px;
    top: 34px;
  }
}
@keyframes screw-small-left {
  0% {
    width: 0;
    height: 0;
    left: 34px;
    top: 43px;
  }
  3% {
    width: 0;
    height: 0;
    left: 34px;
    top: 43px;
  }
  6% {
    width: 18px;
    height: 18px;
    left: 25px;
    top: 34px;
  }
}
#screw-small-left {
  left: 25px;
  -webkit-animation: screw-small-left 8s   linear;
  animation: screw-small-left 8s   linear;
}
@-moz-keyframes screw-large-right {
  0% {
    width: 0;
    height: 0;
    right: 13px;
    top: 245px;
  }
  5.5% {
    width: 0;
    height: 0;
    right: 13px;
    top: 245px;
  }
  8.5% {
    width: 20px;
    height: 20px;
    right: 3px;
    top: 235px;
  }
}
@-webkit-keyframes screw-large-right {
  0% {
    width: 0;
    height: 0;
    right: 13px;
    top: 245px;
  }
  5.5% {
    width: 0;
    height: 0;
    right: 13px;
    top: 245px;
  }
  8.5% {
    width: 20px;
    height: 20px;
    right: 3px;
    top: 235px;
  }
}
@keyframes screw-large-right {
  0% {
    width: 0;
    height: 0;
    right: 13px;
    top: 245px;
  }
  5.5% {
    width: 0;
    height: 0;
    right: 13px;
    top: 245px;
  }
  8.5% {
    width: 20px;
    height: 20px;
    right: 3px;
    top: 235px;
  }
}
#screw-large-right {
  right: 3px;
  -webkit-animation: screw-large-right 8s   linear;
  animation: screw-large-right 8s   linear;
}
@-moz-keyframes screw-large-left {
  0% {
    width: 0;
    height: 0;
    left: 13px;
    top: 245px;
  }
  6.5% {
    width: 0;
    height: 0;
    left: 13px;
    top: 245px;
  }
  9.5% {
    width: 20px;
    height: 20px;
    left: 3px;
    top: 235px;
  }
}
@-webkit-keyframes screw-large-left {
  0% {
    width: 0;
    height: 0;
    left: 13px;
    top: 245px;
  }
  6.5% {
    width: 0;
    height: 0;
    left: 13px;
    top: 245px;
  }
  9.5% {
    width: 20px;
    height: 20px;
    left: 3px;
    top: 235px;
  }
}
@keyframes screw-large-left {
  0% {
    width: 0;
    height: 0;
    left: 13px;
    top: 245px;
  }
  6.5% {
    width: 0;
    height: 0;
    left: 13px;
    top: 245px;
  }
  9.5% {
    width: 20px;
    height: 20px;
    left: 3px;
    top: 235px;
  }
}
#screw-large-left {
  left: 3px;
  -webkit-animation: screw-large-left 8s   linear;
  animation: screw-large-left 8s   linear;
}
@-moz-keyframes backboard {
  0% {
    width: 0;
    height: 0;
    left: 170px;
    bottom: 340px;
  }
  4.5% {
    width: 0;
    height: 0;
    left: 170px;
  }
  6.5% {
    width: 36px;
    height: 36px;
    left: 152px;
  }
  10% {
    width: 220px;
    left: 60px;
  }
  10.5% {
    height: 36px;
  }
  14% {
    height: 75px;
  }
}
@-webkit-keyframes backboard {
  0% {
    width: 0;
    height: 0;
    left: 170px;
    bottom: 340px;
  }
  4.5% {
    width: 0;
    height: 0;
    left: 170px;
  }
  6.5% {
    width: 36px;
    height: 36px;
    left: 152px;
  }
  10% {
    width: 220px;
    left: 60px;
  }
  10.5% {
    height: 36px;
  }
  14% {
    height: 75px;
  }
}
@keyframes backboard {
  0% {
    width: 0;
    height: 0;
    left: 170px;
    bottom: 340px;
  }
  4.5% {
    width: 0;
    height: 0;
    left: 170px;
  }
  6.5% {
    width: 36px;
    height: 36px;
    left: 152px;
  }
  10% {
    width: 220px;
    left: 60px;
  }
  10.5% {
    height: 36px;
  }
  14% {
    height: 75px;
  }
}
#backboard {
  z-index: 2;
  width: 220px;
  height: 75px;
  position: absolute;
  left: 60px;
  bottom: 340px;
  background-color: #222222;
  -webkit-animation: backboard 8s   linear;
  animation: backboard 8s   linear;
}
@-moz-keyframes motherboard {
  0% {
    width: 0;
    height: 0;
    left: 170px;
    top: 293px;
  }
  6% {
    width: 0;
    height: 0;
    left: 170px;
    top: 293px;
  }
  8% {
    width: 56px;
    height: 56px;
    left: 142px;
    top: 265px;
  }
  8.5% {
    height: 56px;
    top: 265px;
  }
  10.5% {
    width: 56px;
    height: 400px;
    top: 75px;
  }
  11% {
    width: 56px;
    left: 142px;
  }
  16% {
    width: 270px;
    left: 35px;
  }
}
@-webkit-keyframes motherboard {
  0% {
    width: 0;
    height: 0;
    left: 170px;
    top: 293px;
  }
  6% {
    width: 0;
    height: 0;
    left: 170px;
    top: 293px;
  }
  8% {
    width: 56px;
    height: 56px;
    left: 142px;
    top: 265px;
  }
  8.5% {
    height: 56px;
    top: 265px;
  }
  10.5% {
    width: 56px;
    height: 400px;
    top: 75px;
  }
  11% {
    width: 56px;
    left: 142px;
  }
  16% {
    width: 270px;
    left: 35px;
  }
}
@keyframes motherboard {
  0% {
    width: 0;
    height: 0;
    left: 170px;
    top: 293px;
  }
  6% {
    width: 0;
    height: 0;
    left: 170px;
    top: 293px;
  }
  8% {
    width: 56px;
    height: 56px;
    left: 142px;
    top: 265px;
  }
  8.5% {
    height: 56px;
    top: 265px;
  }
  10.5% {
    width: 56px;
    height: 400px;
    top: 75px;
  }
  11% {
    width: 56px;
    left: 142px;
  }
  16% {
    width: 270px;
    left: 35px;
  }
}
@-moz-keyframes motherboard-before {
  0% {
    height: 0;
    box-shadow: -6px 400px #66947d, 9px 400px #66947d;
  }
  18% {
    height: 0;
  }
  20% {
    height: 100%;
    box-shadow: -6px 400px #66947d, 9px 400px #66947d;
  }
  22% {
    box-shadow: -6px 0 #66947d, 9px 400px #66947d;
  }
  24% {
    box-shadow: -6px 0 #66947d, 9px 0 #66947d;
  }
}
@-webkit-keyframes motherboard-before {
  0% {
    height: 0;
    box-shadow: -6px 400px #66947d, 9px 400px #66947d;
  }
  18% {
    height: 0;
  }
  20% {
    height: 100%;
    box-shadow: -6px 400px #66947d, 9px 400px #66947d;
  }
  22% {
    box-shadow: -6px 0 #66947d, 9px 400px #66947d;
  }
  24% {
    box-shadow: -6px 0 #66947d, 9px 0 #66947d;
  }
}
@keyframes motherboard-before {
  0% {
    height: 0;
    box-shadow: -6px 400px #66947d, 9px 400px #66947d;
  }
  18% {
    height: 0;
  }
  20% {
    height: 100%;
    box-shadow: -6px 400px #66947d, 9px 400px #66947d;
  }
  22% {
    box-shadow: -6px 0 #66947d, 9px 400px #66947d;
  }
  24% {
    box-shadow: -6px 0 #66947d, 9px 0 #66947d;
  }
}
#motherboard {
  z-index: 3;
  position: absolute;
  width: 270px;
  height: 400px;
  left: 35px;
  top: 75px;
  overflow: hidden;
  background-color: #4ca879;
  -webkit-animation: motherboard 8s   linear;
  animation: motherboard 8s   linear;
}
#motherboard::before {
  content: "";
  position: absolute;
  left: 87px;
  width: 2px;
  height: 100%;
  background-color: #66947d;
  box-shadow: -6px 0 #66947d, 9px 0 #66947d;
  -webkit-animation: motherboard-before 8s   linear;
  animation: motherboard-before 8s   linear;
}
.chip {
  z-index: 4;
  background-color: #308d58;
  position: absolute;
}
@-moz-keyframes chip-tall {
  0% {
    width: 0;
    height: 400px;
    right: 35px;
  }
  6.5% {
    width: 0;
  }
  8.5% {
    width: 30px;
  }
}
@-webkit-keyframes chip-tall {
  0% {
    width: 0;
    height: 400px;
    right: 35px;
  }
  6.5% {
    width: 0;
  }
  8.5% {
    width: 30px;
  }
}
@keyframes chip-tall {
  0% {
    width: 0;
    height: 400px;
    right: 35px;
  }
  6.5% {
    width: 0;
  }
  8.5% {
    width: 30px;
  }
}
@-moz-keyframes chip-tall-before {
  0% {
    height: 0;
  }
  10% {
    height: 0;
  }
  11.5% {
    height: 100%;
  }
}
@-webkit-keyframes chip-tall-before {
  0% {
    height: 0;
  }
  10% {
    height: 0;
  }
  11.5% {
    height: 100%;
  }
}
@keyframes chip-tall-before {
  0% {
    height: 0;
  }
  10% {
    height: 0;
  }
  11.5% {
    height: 100%;
  }
}
@-moz-keyframes chip-tall-after {
  0% {
    height: 0;
  }
  11.5% {
    height: 0;
  }
  13% {
    height: 100%;
  }
}
@-webkit-keyframes chip-tall-after {
  0% {
    height: 0;
  }
  11.5% {
    height: 0;
  }
  13% {
    height: 100%;
  }
}
@keyframes chip-tall-after {
  0% {
    height: 0;
  }
  11.5% {
    height: 0;
  }
  13% {
    height: 100%;
  }
}
#chip-tall {
  width: 30px;
  height: 400px;
  top: 75px;
  right: 35px;
  -webkit-animation: chip-tall 8s   linear;
  animation: chip-tall 8s   linear;
}
#chip-tall::before {
  content: "";
  position: absolute;
  right: 8px;
  bottom: 0;
  width: 4px;
  height: 100%;
  background-color: #267146;
  -webkit-animation: chip-tall-before 8s   linear;
  animation: chip-tall-before 8s   linear;
}
#chip-tall::after {
  content: "";
  position: absolute;
  right: 18px;
  bottom: 0;
  width: 4px;
  height: 100%;
  background-color: #267146;
  -webkit-animation: chip-tall-after 8s   linear;
  animation: chip-tall-after 8s   linear;
}
@-moz-keyframes chip-short {
  0% {
    width: 0;
    height: 200px;
    right: 35px;
  }
  6.5% {
    width: 0;
  }
  8.5% {
    width: 30px;
    height: 200px;
  }
  10.5% {
    width: 80px;
    height: 140px;
  }
}
@-webkit-keyframes chip-short {
  0% {
    width: 0;
    height: 200px;
    right: 35px;
  }
  6.5% {
    width: 0;
  }
  8.5% {
    width: 30px;
    height: 200px;
  }
  10.5% {
    width: 80px;
    height: 140px;
  }
}
@keyframes chip-short {
  0% {
    width: 0;
    height: 200px;
    right: 35px;
  }
  6.5% {
    width: 0;
  }
  8.5% {
    width: 30px;
    height: 200px;
  }
  10.5% {
    width: 80px;
    height: 140px;
  }
}
@-moz-keyframes chip-details {
  0% {
    background-color: transparent;
    box-shadow: 6px 0 transparent;
  }
  18% {
    background-color: transparent;
  }
  20% {
    box-shadow: 6px 0 transparent;
  }
  24% {
    background-color: #66947d;
  }
  26% {
    box-shadow: 6px 0 #66947d;
  }
}
@-webkit-keyframes chip-details {
  0% {
    background-color: transparent;
    box-shadow: 6px 0 transparent;
  }
  18% {
    background-color: transparent;
  }
  20% {
    box-shadow: 6px 0 transparent;
  }
  24% {
    background-color: #66947d;
  }
  26% {
    box-shadow: 6px 0 #66947d;
  }
}
@keyframes chip-details {
  0% {
    background-color: transparent;
    box-shadow: 6px 0 transparent;
  }
  18% {
    background-color: transparent;
  }
  20% {
    box-shadow: 6px 0 transparent;
  }
  24% {
    background-color: #66947d;
  }
  26% {
    box-shadow: 6px 0 #66947d;
  }
}
@-moz-keyframes chip-details-diagonal {
  0% {
    background-color: transparent;
    box-shadow: 5px 4px transparent;
  }
  18% {
    background-color: transparent;
  }
  20% {
    box-shadow: 5px 4px transparent;
  }
  24% {
    background-color: #66947d;
  }
  26% {
    box-shadow: 5px 4px #66947d;
  }
}
@-webkit-keyframes chip-details-diagonal {
  0% {
    background-color: transparent;
    box-shadow: 5px 4px transparent;
  }
  18% {
    background-color: transparent;
  }
  20% {
    box-shadow: 5px 4px transparent;
  }
  24% {
    background-color: #66947d;
  }
  26% {
    box-shadow: 5px 4px #66947d;
  }
}
@keyframes chip-details-diagonal {
  0% {
    background-color: transparent;
    box-shadow: 5px 4px transparent;
  }
  18% {
    background-color: transparent;
  }
  20% {
    box-shadow: 5px 4px transparent;
  }
  24% {
    background-color: #66947d;
  }
  26% {
    box-shadow: 5px 4px #66947d;
  }
}
#chip-short {
  width: 80px;
  height: 140px;
  top: 75px;
  right: 35px;
  -webkit-animation: chip-short 8s   linear;
  animation: chip-short 8s   linear;
}
#chip-short::before {
  content: "";
  width: 2px;
  height: 201px;
  top: 199px;
  background-color: #66947d;
  position: absolute;
  left: 36px;
  box-shadow: 6px 0 #66947d;
  -webkit-animation: chip-details 8s   linear;
  animation: chip-details 8s   linear;
}
#chip-short::after {
  content: "";
  width: 2px;
  height: 100%;
  background-color: #66947d;
  position: absolute;
  left: -14px;
  box-shadow: 6px 0 #66947d;
  -webkit-animation: chip-details 8s   linear;
  animation: chip-details 8s   linear;
}
@-moz-keyframes chip-diagonal {
  0% {
    width: 0;
    height: 0;
  }
  8.5% {
    width: 0;
    height: 0;
  }
  10.5% {
    width: 50px;
    height: 78px;
  }
}
@-webkit-keyframes chip-diagonal {
  0% {
    width: 0;
    height: 0;
  }
  8.5% {
    width: 0;
    height: 0;
  }
  10.5% {
    width: 50px;
    height: 78px;
  }
}
@keyframes chip-diagonal {
  0% {
    width: 0;
    height: 0;
  }
  8.5% {
    width: 0;
    height: 0;
  }
  10.5% {
    width: 50px;
    height: 78px;
  }
}
#chip-diagonal {
  width: 50px;
  height: 78px;
  left: 275px;
  bottom: 295px;
  border-bottom-right-radius: 100% 100%;
  -webkit-transform-origin: 0 100%;
  transform-origin: 0 100%;
  -webkit-transform: rotateZ(-40deg);
  transform: rotateZ(-40deg);
  -webkit-animation: chip-diagonal 8s   linear;
  animation: chip-diagonal 8s   linear;
}
#chip-diagonal::before {
  content: "";
  width: 2px;
  height: 100%;
  background-color: #66947d;
  position: absolute;
  left: -11px;
  top: -9px;
  box-shadow: 5px 4px #66947d;
  -webkit-animation: chip-details-diagonal 8s   linear;
  animation: chip-details-diagonal 8s   linear;
}
.capacitors {
  position: absolute;
  z-index: 5;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: transparent;
  box-shadow: 11px 0 1px #ffffff, 11px -35px 1px #ffffff, 31px -35px 1px #ffffff, 21px -24px 1px #ffffff, 21px -11px 1px #ffffff;
}
@-moz-keyframes chip-capacitors {
  0% {
    box-shadow: none;
  }
  12.5% {
    box-shadow: 11px 0 1px transparent;
  }
  13.5% {
    box-shadow: 11px 0 1px #ffffff, 11px -35px 1px transparent, 31px -35px 1px transparent;
  }
  14.5% {
    box-shadow: 11px 0 1px #ffffff, 11px -35px 1px #ffffff, 31px -35px 1px #ffffff, 21px -24px 1px transparent;
  }
  15.5% {
    box-shadow: 11px 0 1px #ffffff, 11px -35px 1px #ffffff, 31px -35px 1px #ffffff, 21px -24px 1px #ffffff, 21px -11px 1px transparent;
  }
  16.5% {
    box-shadow: 11px 0 1px #ffffff, 11px -35px 1px #ffffff, 31px -35px 1px #ffffff, 21px -24px 1px #ffffff, 21px -11px 1px #ffffff;
  }
}
@-webkit-keyframes chip-capacitors {
  0% {
    box-shadow: none;
  }
  12.5% {
    box-shadow: 11px 0 1px transparent;
  }
  13.5% {
    box-shadow: 11px 0 1px #ffffff, 11px -35px 1px transparent, 31px -35px 1px transparent;
  }
  14.5% {
    box-shadow: 11px 0 1px #ffffff, 11px -35px 1px #ffffff, 31px -35px 1px #ffffff, 21px -24px 1px transparent;
  }
  15.5% {
    box-shadow: 11px 0 1px #ffffff, 11px -35px 1px #ffffff, 31px -35px 1px #ffffff, 21px -24px 1px #ffffff, 21px -11px 1px transparent;
  }
  16.5% {
    box-shadow: 11px 0 1px #ffffff, 11px -35px 1px #ffffff, 31px -35px 1px #ffffff, 21px -24px 1px #ffffff, 21px -11px 1px #ffffff;
  }
}
@keyframes chip-capacitors {
  0% {
    box-shadow: none;
  }
  12.5% {
    box-shadow: 11px 0 1px transparent;
  }
  13.5% {
    box-shadow: 11px 0 1px #ffffff, 11px -35px 1px transparent, 31px -35px 1px transparent;
  }
  14.5% {
    box-shadow: 11px 0 1px #ffffff, 11px -35px 1px #ffffff, 31px -35px 1px #ffffff, 21px -24px 1px transparent;
  }
  15.5% {
    box-shadow: 11px 0 1px #ffffff, 11px -35px 1px #ffffff, 31px -35px 1px #ffffff, 21px -24px 1px #ffffff, 21px -11px 1px transparent;
  }
  16.5% {
    box-shadow: 11px 0 1px #ffffff, 11px -35px 1px #ffffff, 31px -35px 1px #ffffff, 21px -24px 1px #ffffff, 21px -11px 1px #ffffff;
  }
}
#chip-capacitors {
  left: 240px;
  top: 130px;
  -webkit-animation: chip-capacitors 8s   linear;
  animation: chip-capacitors 8s   linear;
}
@-moz-keyframes motherboard-capacitors {
  0% {
    box-shadow: none;
  }
  22.5% {
    box-shadow: 11px 0 1px transparent;
  }
  23.5% {
    box-shadow: 11px 0 1px #ffffff, 11px -35px 1px transparent, 31px -35px 1px transparent;
  }
  24.5% {
    box-shadow: 11px 0 1px #ffffff, 11px -35px 1px #ffffff, 31px -35px 1px #ffffff, 21px -24px 1px transparent;
  }
  25.5% {
    box-shadow: 11px 0 1px #ffffff, 11px -35px 1px #ffffff, 31px -35px 1px #ffffff, 21px -24px 1px #ffffff, 21px -11px 1px transparent;
  }
  26.5% {
    box-shadow: 11px 0 1px #ffffff, 11px -35px 1px #ffffff, 31px -35px 1px #ffffff, 21px -24px 1px #ffffff, 21px -11px 1px #ffffff;
  }
}
@-webkit-keyframes motherboard-capacitors {
  0% {
    box-shadow: none;
  }
  22.5% {
    box-shadow: 11px 0 1px transparent;
  }
  23.5% {
    box-shadow: 11px 0 1px #ffffff, 11px -35px 1px transparent, 31px -35px 1px transparent;
  }
  24.5% {
    box-shadow: 11px 0 1px #ffffff, 11px -35px 1px #ffffff, 31px -35px 1px #ffffff, 21px -24px 1px transparent;
  }
  25.5% {
    box-shadow: 11px 0 1px #ffffff, 11px -35px 1px #ffffff, 31px -35px 1px #ffffff, 21px -24px 1px #ffffff, 21px -11px 1px transparent;
  }
  26.5% {
    box-shadow: 11px 0 1px #ffffff, 11px -35px 1px #ffffff, 31px -35px 1px #ffffff, 21px -24px 1px #ffffff, 21px -11px 1px #ffffff;
  }
}
@keyframes motherboard-capacitors {
  0% {
    box-shadow: none;
  }
  22.5% {
    box-shadow: 11px 0 1px transparent;
  }
  23.5% {
    box-shadow: 11px 0 1px #ffffff, 11px -35px 1px transparent, 31px -35px 1px transparent;
  }
  24.5% {
    box-shadow: 11px 0 1px #ffffff, 11px -35px 1px #ffffff, 31px -35px 1px #ffffff, 21px -24px 1px transparent;
  }
  25.5% {
    box-shadow: 11px 0 1px #ffffff, 11px -35px 1px #ffffff, 31px -35px 1px #ffffff, 21px -24px 1px #ffffff, 21px -11px 1px transparent;
  }
  26.5% {
    box-shadow: 11px 0 1px #ffffff, 11px -35px 1px #ffffff, 31px -35px 1px #ffffff, 21px -24px 1px #ffffff, 21px -11px 1px #ffffff;
  }
}
#motherboard-capacitors {
  left: 85px;
  top: 265px;
  -webkit-transform: rotateZ(-90deg);
  transform: rotateZ(-90deg);
  -webkit-animation: motherboard-capacitors 8s   linear;
  animation: motherboard-capacitors 8s   linear;
}
@-moz-keyframes contrast-knob {
  0% {
    width: 0;
    height: 0;
    left: 33px;
    top: 194px;
  }
  14% {
    width: 0;
    height: 0;
    left: 33px;
    top: 194px;
  }
  16.5% {
    width: 28px;
    height: 28px;
    left: 19px;
    top: 180px;
  }
  20% {
    top: 180px;
  }
  24% {
    top: 165px;
  }
}
@-webkit-keyframes contrast-knob {
  0% {
    width: 0;
    height: 0;
    left: 33px;
    top: 194px;
  }
  14% {
    width: 0;
    height: 0;
    left: 33px;
    top: 194px;
  }
  16.5% {
    width: 28px;
    height: 28px;
    left: 19px;
    top: 180px;
  }
  20% {
    top: 180px;
  }
  24% {
    top: 165px;
  }
}
@keyframes contrast-knob {
  0% {
    width: 0;
    height: 0;
    left: 33px;
    top: 194px;
  }
  14% {
    width: 0;
    height: 0;
    left: 33px;
    top: 194px;
  }
  16.5% {
    width: 28px;
    height: 28px;
    left: 19px;
    top: 180px;
  }
  20% {
    top: 180px;
  }
  24% {
    top: 165px;
  }
}
@-moz-keyframes contrast-knob-before {
  0% {
    width: 0;
    height: 0;
    left: -1px;
    top: 14px;
  }
  16% {
    width: 0;
    height: 0;
    left: -1px;
    top: 14px;
  }
  18% {
    width: 32px;
    height: 32px;
    left: -17px;
    top: -2px;
  }
}
@-webkit-keyframes contrast-knob-before {
  0% {
    width: 0;
    height: 0;
    left: -1px;
    top: 14px;
  }
  16% {
    width: 0;
    height: 0;
    left: -1px;
    top: 14px;
  }
  18% {
    width: 32px;
    height: 32px;
    left: -17px;
    top: -2px;
  }
}
@keyframes contrast-knob-before {
  0% {
    width: 0;
    height: 0;
    left: -1px;
    top: 14px;
  }
  16% {
    width: 0;
    height: 0;
    left: -1px;
    top: 14px;
  }
  18% {
    width: 32px;
    height: 32px;
    left: -17px;
    top: -2px;
  }
}
#contrast-knob {
  z-index: 4;
  position: absolute;
  top: 165px;
  left: 19px;
  width: 28px;
  height: 28px;
  background-color: #808080;
  -webkit-animation: contrast-knob 8s   linear;
  animation: contrast-knob 8s   linear;
}
#contrast-knob:before {
  content: '';
  position: absolute;
  top: -2px;
  left: -17px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: #303030;
  -webkit-animation: contrast-knob-before 8s   linear;
  animation: contrast-knob-before 8s   linear;
}
@-moz-keyframes link-port {
  0% {
    width: 0;
    height: 0;
    left: 36px;
  }
  14% {
    width: 0;
    height: 0;
    left: 36px;
  }
  16.5% {
    width: 34px;
    height: 34px;
    left: 19px;
  }
  18.5% {
    left: 19px;
  }
  20.5% {
    left: 3px;
  }
}
@-webkit-keyframes link-port {
  0% {
    width: 0;
    height: 0;
    left: 36px;
  }
  14% {
    width: 0;
    height: 0;
    left: 36px;
  }
  16.5% {
    width: 34px;
    height: 34px;
    left: 19px;
  }
  18.5% {
    left: 19px;
  }
  20.5% {
    left: 3px;
  }
}
@keyframes link-port {
  0% {
    width: 0;
    height: 0;
    left: 36px;
  }
  14% {
    width: 0;
    height: 0;
    left: 36px;
  }
  16.5% {
    width: 34px;
    height: 34px;
    left: 19px;
  }
  18.5% {
    left: 19px;
  }
  20.5% {
    left: 3px;
  }
}
#link-port {
  z-index: 4;
  position: absolute;
  top: 123px;
  left: 3px;
  width: 34px;
  height: 34px;
  background-color: #ffffff;
  -webkit-animation: link-port 8s   linear;
  animation: link-port 8s   linear;
}
.circuit {
  z-index: 4;
  position: absolute;
  box-shadow: inset 0 0 0 1px #ffffff;
  border-radius: 50% / 25%;
  opacity: 0.5;
}
.circuit::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 0;
  width: 50%;
  height: 0%;
  background-color: #4ca879;
}
.circuit::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 50%;
  height: 0%;
  background-color: #4ca879;
}
@-moz-keyframes circuit-bottom {
  0% {
    opacity: 0;
  }
  13.4% {
    opacity: 0;
  }
  13.5% {
    opacity: 0.5;
  }
}
@-webkit-keyframes circuit-bottom {
  0% {
    opacity: 0;
  }
  13.4% {
    opacity: 0;
  }
  13.5% {
    opacity: 0.5;
  }
}
@keyframes circuit-bottom {
  0% {
    opacity: 0;
  }
  13.4% {
    opacity: 0;
  }
  13.5% {
    opacity: 0.5;
  }
}
@-moz-keyframes "circuit-bottom-before" {
  0% {
    height: 100%;
  }
  13.5% {
    height: 100%;
  }
  15.5% {
    height: 0%;
  }
}
@-webkit-keyframes "circuit-bottom-before" {
  0% {
    height: 100%;
  }
  13.5% {
    height: 100%;
  }
  15.5% {
    height: 0%;
  }
}
@keyframes "circuit-bottom-before" {
  0% {
    height: 100%;
  }
  13.5% {
    height: 100%;
  }
  15.5% {
    height: 0%;
  }
}
@-moz-keyframes "circuit-bottom-after" {
  0% {
    height: 100%;
  }
  15.5% {
    height: 100%;
  }
  17.5% {
    height: 0%;
  }
}
@-webkit-keyframes "circuit-bottom-after" {
  0% {
    height: 100%;
  }
  15.5% {
    height: 100%;
  }
  17.5% {
    height: 0%;
  }
}
@keyframes "circuit-bottom-after" {
  0% {
    height: 100%;
  }
  15.5% {
    height: 100%;
  }
  17.5% {
    height: 0%;
  }
}
#circuit-bottom {
  width: 30px;
  height: 60px;
  top: 330px;
  left: 155px;
  -webkit-transform: rotateZ(-90deg);
  transform: rotateZ(-90deg);
  -webkit-animation: circuit-bottom 8s   linear;
  animation: circuit-bottom 8s   linear;
}
#circuit-bottom::before {
  -webkit-animation: circuit-bottom-before 8s   linear;
  animation: circuit-bottom-before 8s   linear;
}
#circuit-bottom::after {
  -webkit-animation: circuit-bottom-after 8s   linear;
  animation: circuit-bottom-after 8s   linear;
}
@-moz-keyframes circuit-top {
  0% {
    opacity: 0;
  }
  16.4% {
    opacity: 0;
  }
  16.5% {
    opacity: 0.5;
  }
}
@-webkit-keyframes circuit-top {
  0% {
    opacity: 0;
  }
  16.4% {
    opacity: 0;
  }
  16.5% {
    opacity: 0.5;
  }
}
@keyframes circuit-top {
  0% {
    opacity: 0;
  }
  16.4% {
    opacity: 0;
  }
  16.5% {
    opacity: 0.5;
  }
}
@-moz-keyframes "circuit-top-before" {
  0% {
    height: 100%;
  }
  16.5% {
    height: 100%;
  }
  18.5% {
    height: 0%;
  }
}
@-webkit-keyframes "circuit-top-before" {
  0% {
    height: 100%;
  }
  16.5% {
    height: 100%;
  }
  18.5% {
    height: 0%;
  }
}
@keyframes "circuit-top-before" {
  0% {
    height: 100%;
  }
  16.5% {
    height: 100%;
  }
  18.5% {
    height: 0%;
  }
}
@-moz-keyframes "circuit-top-after" {
  0% {
    height: 100%;
  }
  18.5% {
    height: 100%;
  }
  20.5% {
    height: 0%;
  }
}
@-webkit-keyframes "circuit-top-after" {
  0% {
    height: 100%;
  }
  18.5% {
    height: 100%;
  }
  20.5% {
    height: 0%;
  }
}
@keyframes "circuit-top-after" {
  0% {
    height: 100%;
  }
  18.5% {
    height: 100%;
  }
  20.5% {
    height: 0%;
  }
}
#circuit-top {
  width: 40px;
  height: 80px;
  top: 100px;
  left: 70px;
  -webkit-animation: circuit-top 8s   linear;
  animation: circuit-top 8s   linear;
}
#circuit-top::before {
  -webkit-animation: circuit-top-before 8s   linear;
  animation: circuit-top-before 8s   linear;
}
#circuit-top::after {
  -webkit-animation: circuit-top-after 8s   linear;
  animation: circuit-top-after 8s   linear;
}
@-moz-keyframes transistors {
  0% {
    opacity: 0;
    box-shadow: none;
  }
  17% {
    opacity: 0;
    box-shadow: 16px 0 transparent;
  }
  19% {
    opacity: 1;
    box-shadow: 16px 0, 32px 0 transparent;
  }
  21% {
    box-shadow: 16px 0, 32px 0, 48px 0 transparent;
  }
  23% {
    box-shadow: 16px 0, 32px 0, 48px 0;
  }
}
@-webkit-keyframes transistors {
  0% {
    opacity: 0;
    box-shadow: none;
  }
  17% {
    opacity: 0;
    box-shadow: 16px 0 transparent;
  }
  19% {
    opacity: 1;
    box-shadow: 16px 0, 32px 0 transparent;
  }
  21% {
    box-shadow: 16px 0, 32px 0, 48px 0 transparent;
  }
  23% {
    box-shadow: 16px 0, 32px 0, 48px 0;
  }
}
@keyframes transistors {
  0% {
    opacity: 0;
    box-shadow: none;
  }
  17% {
    opacity: 0;
    box-shadow: 16px 0 transparent;
  }
  19% {
    opacity: 1;
    box-shadow: 16px 0, 32px 0 transparent;
  }
  21% {
    box-shadow: 16px 0, 32px 0, 48px 0 transparent;
  }
  23% {
    box-shadow: 16px 0, 32px 0, 48px 0;
  }
}
#transistors {
  z-index: 4;
  position: absolute;
  top: 310px;
  left: 50px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #303030;
  box-shadow: 16px 0, 32px 0, 48px 0;
  -webkit-animation: transistors 8s   linear;
  animation: transistors 8s   linear;
}
@-moz-keyframes processor {
  0% {
    width: 0;
    height: 0;
    left: 170px;
    top: 313px;
  }
  12% {
    width: 0;
    height: 0;
    left: 170px;
    top: 313px;
  }
  14% {
    width: 56px;
    height: 56px;
    left: 142px;
    top: 285px;
  }
}
@-webkit-keyframes processor {
  0% {
    width: 0;
    height: 0;
    left: 170px;
    top: 313px;
  }
  12% {
    width: 0;
    height: 0;
    left: 170px;
    top: 313px;
  }
  14% {
    width: 56px;
    height: 56px;
    left: 142px;
    top: 285px;
  }
}
@keyframes processor {
  0% {
    width: 0;
    height: 0;
    left: 170px;
    top: 313px;
  }
  12% {
    width: 0;
    height: 0;
    left: 170px;
    top: 313px;
  }
  14% {
    width: 56px;
    height: 56px;
    left: 142px;
    top: 285px;
  }
}
@-moz-keyframes processor-before {
  0% {
    width: 0;
  }
  16% {
    width: 0;
  }
  18% {
    width: 50px;
  }
}
@-webkit-keyframes processor-before {
  0% {
    width: 0;
  }
  16% {
    width: 0;
  }
  18% {
    width: 50px;
  }
}
@keyframes processor-before {
  0% {
    width: 0;
  }
  16% {
    width: 0;
  }
  18% {
    width: 50px;
  }
}
@-moz-keyframes processor-after {
  0% {
    width: 0;
  }
  18% {
    width: 0;
  }
  20% {
    width: 50px;
  }
}
@-webkit-keyframes processor-after {
  0% {
    width: 0;
  }
  18% {
    width: 0;
  }
  20% {
    width: 50px;
  }
}
@keyframes processor-after {
  0% {
    width: 0;
  }
  18% {
    width: 0;
  }
  20% {
    width: 50px;
  }
}
#processor {
  position: absolute;
  width: 56px;
  height: 56px;
  top: 285px;
  left: 142px;
  z-index: 4;
  background-color: #222222;
  color: #ffffff;
  font-size: 15px;
  letter-spacing: 3px;
  font-weight: 700;
  font-family: 'Oxygen', Helvetica, arial, sans-serif;
  -webkit-animation: processor 8s   linear;
  animation: processor 8s   linear;
}
#processor::before {
  content: "llllll";
  position: absolute;
  width: 50px;
  overflow: hidden;
  text-shadow: -1px 0 0 #808080;
  -webkit-transform: rotateZ(-90deg);
  transform: rotateZ(-90deg);
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
  left: 48px;
  bottom: -9px;
  -webkit-animation: processor-before 8s   linear;
  animation: processor-before 8s   linear;
}
#processor::after {
  content: "llllll";
  position: absolute;
  width: 50px;
  overflow: hidden;
  text-shadow: -1px 0 0 #808080;
  -webkit-transform: rotateZ(90deg) rotateY(180deg);
  transform: rotateZ(90deg) rotateY(180deg);
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
  left: 7px;
  top: 50px;
  -webkit-animation: processor-after 8s   linear;
  animation: processor-after 8s   linear;
}
@-moz-keyframes component {
  0% {
    width: 0;
    height: 0;
    left: 251px;
    top: 310.5px;
  }
  20.5% {
    width: 0;
    height: 0;
    left: 251px;
    top: 310.5px;
  }
  23.5% {
    width: 14px;
    height: 55px;
    left: 244px;
    top: 283px;
  }
  24.5% {
    left: 244px;
    top: 283px;
  }
  28% {
    left: 228px;
    top: 283px;
  }
}
@-webkit-keyframes component {
  0% {
    width: 0;
    height: 0;
    left: 251px;
    top: 310.5px;
  }
  20.5% {
    width: 0;
    height: 0;
    left: 251px;
    top: 310.5px;
  }
  23.5% {
    width: 14px;
    height: 55px;
    left: 244px;
    top: 283px;
  }
  24.5% {
    left: 244px;
    top: 283px;
  }
  28% {
    left: 228px;
    top: 283px;
  }
}
@keyframes component {
  0% {
    width: 0;
    height: 0;
    left: 251px;
    top: 310.5px;
  }
  20.5% {
    width: 0;
    height: 0;
    left: 251px;
    top: 310.5px;
  }
  23.5% {
    width: 14px;
    height: 55px;
    left: 244px;
    top: 283px;
  }
  24.5% {
    left: 244px;
    top: 283px;
  }
  28% {
    left: 228px;
    top: 283px;
  }
}
#component {
  z-index: 4;
  position: absolute;
  top: 283px;
  left: 228px;
  background-color: #222222;
  border-left: 2px #808080 dotted;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  width: 14px;
  height: 55px;
  -webkit-animation: component 8s   linear;
  animation: component 8s   linear;
}
@-moz-keyframes controller {
  0% {
    width: 0;
    height: 110px;
    left: 170px;
    top: 390px;
  }
  12% {
    width: 0;
    left: 170px;
  }
  16% {
    width: 204px;
    left: 68px;
  }
}
@-webkit-keyframes controller {
  0% {
    width: 0;
    height: 110px;
    left: 170px;
    top: 390px;
  }
  12% {
    width: 0;
    left: 170px;
  }
  16% {
    width: 204px;
    left: 68px;
  }
}
@keyframes controller {
  0% {
    width: 0;
    height: 110px;
    left: 170px;
    top: 390px;
  }
  12% {
    width: 0;
    left: 170px;
  }
  16% {
    width: 204px;
    left: 68px;
  }
}
@-moz-keyframes controller-before {
  0% {
    width: 0;
    height: 0;
    left: -9px;
    bottom: 28px;
  }
  18% {
    width: 0;
    height: 0;
    left: -9px;
    bottom: 28px;
  }
  21.5% {
    width: 18px;
    height: 6px;
    left: -18px;
    bottom: 25px;
  }
}
@-webkit-keyframes controller-before {
  0% {
    width: 0;
    height: 0;
    left: -9px;
    bottom: 28px;
  }
  18% {
    width: 0;
    height: 0;
    left: -9px;
    bottom: 28px;
  }
  21.5% {
    width: 18px;
    height: 6px;
    left: -18px;
    bottom: 25px;
  }
}
@keyframes controller-before {
  0% {
    width: 0;
    height: 0;
    left: -9px;
    bottom: 28px;
  }
  18% {
    width: 0;
    height: 0;
    left: -9px;
    bottom: 28px;
  }
  21.5% {
    width: 18px;
    height: 6px;
    left: -18px;
    bottom: 25px;
  }
}
@-moz-keyframes controller-after {
  0% {
    width: 0;
    height: 0;
    left: 102px;
    bottom: 0;
  }
  20% {
    width: 0;
    height: 0;
    left: 102px;
    bottom: 0;
  }
  22.5% {
    width: 114px;
    height: 24px;
    left: 45px;
    bottom: -24px;
  }
}
@-webkit-keyframes controller-after {
  0% {
    width: 0;
    height: 0;
    left: 102px;
    bottom: 0;
  }
  20% {
    width: 0;
    height: 0;
    left: 102px;
    bottom: 0;
  }
  22.5% {
    width: 114px;
    height: 24px;
    left: 45px;
    bottom: -24px;
  }
}
@keyframes controller-after {
  0% {
    width: 0;
    height: 0;
    left: 102px;
    bottom: 0;
  }
  20% {
    width: 0;
    height: 0;
    left: 102px;
    bottom: 0;
  }
  22.5% {
    width: 114px;
    height: 24px;
    left: 45px;
    bottom: -24px;
  }
}
#controller {
  position: absolute;
  z-index: 4;
  width: 204px;
  height: 110px;
  top: 390px;
  left: 68px;
  background-color: #9d9d9d;
  -webkit-animation: controller 8s   linear;
  animation: controller 8s   linear;
}
#controller::before {
  content: "";
  position: absolute;
  background-color: #222222;
  width: 18px;
  height: 6px;
  left: -18px;
  bottom: 25px;
  -webkit-animation: controller-before 8s   linear;
  animation: controller-before 8s   linear;
}
#controller::after {
  content: "";
  position: absolute;
  width: 114px;
  height: 24px;
  bottom: -24px;
  left: 45px;
  background-color: #303030;
  -webkit-animation: controller-after 8s   linear;
  animation: controller-after 8s   linear;
}
@-moz-keyframes speaker {
  0% {
    width: 0;
    height: 0;
    bottom: 90px;
    right: 60px;
  }
  12% {
    width: 0;
    height: 0;
    bottom: 90px;
    right: 60px;
  }
  15.5% {
    width: 80px;
    height: 80px;
    bottom: 50px;
    right: 20px;
  }
  19.5% {
    bottom: 50px;
  }
  26% {
    bottom: 24px;
  }
}
@-webkit-keyframes speaker {
  0% {
    width: 0;
    height: 0;
    bottom: 90px;
    right: 60px;
  }
  12% {
    width: 0;
    height: 0;
    bottom: 90px;
    right: 60px;
  }
  15.5% {
    width: 80px;
    height: 80px;
    bottom: 50px;
    right: 20px;
  }
  19.5% {
    bottom: 50px;
  }
  26% {
    bottom: 24px;
  }
}
@keyframes speaker {
  0% {
    width: 0;
    height: 0;
    bottom: 90px;
    right: 60px;
  }
  12% {
    width: 0;
    height: 0;
    bottom: 90px;
    right: 60px;
  }
  15.5% {
    width: 80px;
    height: 80px;
    bottom: 50px;
    right: 20px;
  }
  19.5% {
    bottom: 50px;
  }
  26% {
    bottom: 24px;
  }
}
#speaker {
  position: absolute;
  z-index: 5;
  width: 80px;
  height: 80px;
  right: 20px;
  bottom: 24px;
  border-radius: 100%;
  background-color: #222222;
  -webkit-animation: speaker 8s   linear;
  animation: speaker 8s   linear;
}
#speaker::after {
  content: "";
  position: absolute;
  z-index: 6;
  width: 42%;
  height: 42%;
  left: 50%;
  margin-left: -21%;
  top: 50%;
  margin-top: -21%;
  border-radius: 100%;
  background-color: #808080;
}
@-moz-keyframes whitescreen {
  0% {
    width: 0;
    height: 0;
    left: 170px;
    top: 105px;
    border: 0px;
  }
  22% {
    width: 0;
    height: 0;
    left: 170px;
    top: 105px;
  }
  26% {
    width: 178px;
    height: 160px;
    left: 81px;
    top: 60px;
    border: 0px transparent;
  }
  28% {
    width: 188px;
    height: 170px;
    border: 5px #808080 solid;
    left: 76px;
    top: 55px;
  }
}
@-webkit-keyframes whitescreen {
  0% {
    width: 0;
    height: 0;
    left: 170px;
    top: 105px;
    border: 0px;
  }
  22% {
    width: 0;
    height: 0;
    left: 170px;
    top: 105px;
  }
  26% {
    width: 178px;
    height: 160px;
    left: 81px;
    top: 60px;
    border: 0px transparent;
  }
  28% {
    width: 188px;
    height: 170px;
    border: 5px #808080 solid;
    left: 76px;
    top: 55px;
  }
}
@keyframes whitescreen {
  0% {
    width: 0;
    height: 0;
    left: 170px;
    top: 105px;
    border: 0px;
  }
  22% {
    width: 0;
    height: 0;
    left: 170px;
    top: 105px;
  }
  26% {
    width: 178px;
    height: 160px;
    left: 81px;
    top: 60px;
    border: 0px transparent;
  }
  28% {
    width: 188px;
    height: 170px;
    border: 5px #808080 solid;
    left: 76px;
    top: 55px;
  }
}
#whitescreen {
  position: absolute;
  z-index: 6;
  width: 188px;
  height: 170px;
  top: 55px;
  left: 76px;
  background-color: #ffffff;
  border: 5px #808080 solid;
  -webkit-animation: whitescreen 8s   linear;
  animation: whitescreen 8s   linear;
}
@-moz-keyframes screen {
  0% {
    width: 0;
    height: 0;
    bottom: 389px;
    left: 170px;
    border-radius: 0;
  }
  16% {
    width: 0;
    height: 0;
    bottom: 389px;
    left: 170px;
    border-radius: 0;
  }
  19.5% {
    width: 36px;
    height: 34px;
    left: 152px;
    bottom: 372px;
    border-radius: 0;
  }
  23.5% {
    bottom: 278px;
    height: 34px;
    border-radius: 0 0 25% 25% / 0 0 10px 10px;
  }
  27.5% {
    height: 263px;
    width: 36px;
    left: 152px;
    border-radius: 0 0 50% 50% / 0 0 20px 20px;
  }
  32.5% {
    width: 294px;
    left: 23px;
    border-radius: 10px 10px 50% 50% / 10px 10px 20px 20px;
  }
}
@-webkit-keyframes screen {
  0% {
    width: 0;
    height: 0;
    bottom: 389px;
    left: 170px;
    border-radius: 0;
  }
  16% {
    width: 0;
    height: 0;
    bottom: 389px;
    left: 170px;
    border-radius: 0;
  }
  19.5% {
    width: 36px;
    height: 34px;
    left: 152px;
    bottom: 372px;
    border-radius: 0;
  }
  23.5% {
    bottom: 278px;
    height: 34px;
    border-radius: 0 0 25% 25% / 0 0 10px 10px;
  }
  27.5% {
    height: 263px;
    width: 36px;
    left: 152px;
    border-radius: 0 0 50% 50% / 0 0 20px 20px;
  }
  32.5% {
    width: 294px;
    left: 23px;
    border-radius: 10px 10px 50% 50% / 10px 10px 20px 20px;
  }
}
@keyframes screen {
  0% {
    width: 0;
    height: 0;
    bottom: 389px;
    left: 170px;
    border-radius: 0;
  }
  16% {
    width: 0;
    height: 0;
    bottom: 389px;
    left: 170px;
    border-radius: 0;
  }
  19.5% {
    width: 36px;
    height: 34px;
    left: 152px;
    bottom: 372px;
    border-radius: 0;
  }
  23.5% {
    bottom: 278px;
    height: 34px;
    border-radius: 0 0 25% 25% / 0 0 10px 10px;
  }
  27.5% {
    height: 263px;
    width: 36px;
    left: 152px;
    border-radius: 0 0 50% 50% / 0 0 20px 20px;
  }
  32.5% {
    width: 294px;
    left: 23px;
    border-radius: 10px 10px 50% 50% / 10px 10px 20px 20px;
  }
}
#glass {
  position: absolute;
  z-index: 14;
  width: 294px;
  height: 263px;
  left: 23px;
  bottom: 278px;
  border-radius: 10px 10px 50% 50% / 10px 10px 20px 20px;
  background-color: #222222;
  -webkit-animation: screen 8s   linear;
  animation: screen 8s   linear;
}
@-moz-keyframes glass-gameboy-text {
  0% {
    width: 0;
  }
  38% {
    width: 0;
  }
  42% {
    width: 95px;
  }
}
@-webkit-keyframes glass-gameboy-text {
  0% {
    width: 0;
  }
  38% {
    width: 0;
  }
  42% {
    width: 95px;
  }
}
@keyframes glass-gameboy-text {
  0% {
    width: 0;
  }
  38% {
    width: 0;
  }
  42% {
    width: 95px;
  }
}
#glass-gameboy-text {
  position: absolute;
  z-index: 15;
  top: 242px;
  left: 90px;
  width: 95px;
  color: #b0b0b0;
  overflow: hidden;
  font-size: 22px;
  font-weight: 400;
  font-style: italic;
  font-family: 'Asap', sans-serif;
  letter-spacing: -2px;
  white-space: nowrap;
  -webkit-transform: skew(-5deg);
  transform: skew(-5deg);
  -webkit-animation: glass-gameboy-text 8s   linear;
  animation: glass-gameboy-text 8s   linear;
}
@-moz-keyframes glass-color-text {
  0% {
    color: transparent;
  }
  42% {
    color: transparent;
  }
  42.5% {
    color: #ff0151;
  }
}
@-webkit-keyframes glass-color-text {
  0% {
    color: transparent;
  }
  42% {
    color: transparent;
  }
  42.5% {
    color: #ff0151;
  }
}
@keyframes glass-color-text {
  0% {
    color: transparent;
  }
  42% {
    color: transparent;
  }
  42.5% {
    color: #ff0151;
  }
}
@-moz-keyframes glass-color-text-before {
  0% {
    color: transparent;
    border-color: transparent;
  }
  42.5% {
    border-color: transparent;
  }
  43% {
    color: transparent;
    border-color: #5151dd;
  }
  43.5% {
    color: #85e367;
  }
}
@-webkit-keyframes glass-color-text-before {
  0% {
    color: transparent;
    border-color: transparent;
  }
  42.5% {
    border-color: transparent;
  }
  43% {
    color: transparent;
    border-color: #5151dd;
  }
  43.5% {
    color: #85e367;
  }
}
@keyframes glass-color-text-before {
  0% {
    color: transparent;
    border-color: transparent;
  }
  42.5% {
    border-color: transparent;
  }
  43% {
    color: transparent;
    border-color: #5151dd;
  }
  43.5% {
    color: #85e367;
  }
}
@-moz-keyframes glass-color-text-after {
  0% {
    color: transparent;
    border-color: transparent;
  }
  43.5% {
    border-color: transparent;
  }
  44% {
    color: transparent;
    border-color: #f9e52e;
  }
  44.5% {
    color: #01b4dd;
  }
}
@-webkit-keyframes glass-color-text-after {
  0% {
    color: transparent;
    border-color: transparent;
  }
  43.5% {
    border-color: transparent;
  }
  44% {
    color: transparent;
    border-color: #f9e52e;
  }
  44.5% {
    color: #01b4dd;
  }
}
@keyframes glass-color-text-after {
  0% {
    color: transparent;
    border-color: transparent;
  }
  43.5% {
    border-color: transparent;
  }
  44% {
    color: transparent;
    border-color: #f9e52e;
  }
  44.5% {
    color: #01b4dd;
  }
}
#glass-color-text {
  z-index: 15;
  color: #ff0151;
  position: absolute;
  top: 242px;
  left: 186px;
  font-family: 'Comic Sans', 'Comic Sans MS', 'ChalkboardSE-Bold', sans-serif, cursive;
  font-size: 23px;
  letter-spacing: -2px;
  font-weight: 700;
  -webkit-animation: glass-color-text 8s linear;
  animation: glass-color-text 8s linear;
}
#glass-color-text::before {
  content: "L";
  position: absolute;
  left: 12px;
  top: 5px;
  line-height: 5px;
  text-indent: 10px;
  color: #85e367;
  border: 4px #5151dd solid;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  -webkit-animation: glass-color-text-before 8s  linear;
  animation: glass-color-text-before 8s  linear;
}
#glass-color-text::after {
  content: "R";
  position: absolute;
  left: 36px;
  top: 5px;
  line-height: 5px;
  text-indent: 10px;
  color: #01b4dd;
  border: 4px #f9e52e solid;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  -webkit-animation: glass-color-text-after 8s   linear;
  animation: glass-color-text-after 8s   linear;
}
@-moz-keyframes glass {
  0% {
    width: 0;
    height: 0;
    top: 86px;
    left: 170px;
  }
  28% {
    width: 0;
    height: 0;
    top: 86px;
    left: 170px;
  }
  30% {
    width: 36px;
    height: 32px;
    top: 70px;
    left: 152px;
  }
  34% {
    top: 130px;
    width: 36px;
    left: 152px;
  }
  37% {
    width: 180px;
    left: 80px;
    height: 32px;
    top: 130px;
  }
  40% {
    height: 160px;
    top: 60px;
  }
}
@-webkit-keyframes glass {
  0% {
    width: 0;
    height: 0;
    top: 86px;
    left: 170px;
  }
  28% {
    width: 0;
    height: 0;
    top: 86px;
    left: 170px;
  }
  30% {
    width: 36px;
    height: 32px;
    top: 70px;
    left: 152px;
  }
  34% {
    top: 130px;
    width: 36px;
    left: 152px;
  }
  37% {
    width: 180px;
    left: 80px;
    height: 32px;
    top: 130px;
  }
  40% {
    height: 160px;
    top: 60px;
  }
}
@keyframes glass {
  0% {
    width: 0;
    height: 0;
    top: 86px;
    left: 170px;
  }
  28% {
    width: 0;
    height: 0;
    top: 86px;
    left: 170px;
  }
  30% {
    width: 36px;
    height: 32px;
    top: 70px;
    left: 152px;
  }
  34% {
    top: 130px;
    width: 36px;
    left: 152px;
  }
  37% {
    width: 180px;
    left: 80px;
    height: 32px;
    top: 130px;
  }
  40% {
    height: 160px;
    top: 60px;
  }
}
#screen {
  position: absolute;
  z-index: 15;
  width: 180px;
  height: 160px;
  left: 80px;
  top: 60px;
  background-color: #f0f0f0;
  -webkit-animation: glass 8s   linear;
  animation: glass 8s   linear;
}
@-moz-keyframes screen-gameboy-text {
  0% {
    color: #f9e52e;
    background-position: -120px;
    opacity: 0;
  }
  63.9% {
    opacity: 0;
  }
  64% {
    color: #f9e52e;
    background-position: -120px;
    opacity: 1;
  }
  66.5% {
    color: #f9e52e;
  }
  67% {
    color: #ff0151;
  }
  69.5% {
    color: #ff0151;
  }
  70% {
    color: #ff52e8;
  }
  72.5% {
    color: #ff52e8;
  }
  73% {
    color: #85e367;
  }
  75.5% {
    color: #85e367;
  }
  76% {
    color: #3232fc;
  }
  78% {
    background-position: 130px;
  }
  87% {
    opacity: 1;
  }
  92% {
    opacity: 0;
  }
}
@-webkit-keyframes screen-gameboy-text {
  0% {
    color: #f9e52e;
    background-position: -120px;
    opacity: 0;
  }
  63.9% {
    opacity: 0;
  }
  64% {
    color: #f9e52e;
    background-position: -120px;
    opacity: 1;
  }
  66.5% {
    color: #f9e52e;
  }
  67% {
    color: #ff0151;
  }
  69.5% {
    color: #ff0151;
  }
  70% {
    color: #ff52e8;
  }
  72.5% {
    color: #ff52e8;
  }
  73% {
    color: #85e367;
  }
  75.5% {
    color: #85e367;
  }
  76% {
    color: #3232fc;
  }
  78% {
    background-position: 130px;
  }
  87% {
    opacity: 1;
  }
  92% {
    opacity: 0;
  }
}
@keyframes screen-gameboy-text {
  0% {
    color: #f9e52e;
    background-position: -120px;
    opacity: 0;
  }
  63.9% {
    opacity: 0;
  }
  64% {
    color: #f9e52e;
    background-position: -120px;
    opacity: 1;
  }
  66.5% {
    color: #f9e52e;
  }
  67% {
    color: #ff0151;
  }
  69.5% {
    color: #ff0151;
  }
  70% {
    color: #ff52e8;
  }
  72.5% {
    color: #ff52e8;
  }
  73% {
    color: #85e367;
  }
  75.5% {
    color: #85e367;
  }
  76% {
    color: #3232fc;
  }
  78% {
    background-position: 130px;
  }
  87% {
    opacity: 1;
  }
  92% {
    opacity: 0;
  }
}
#screen-gameboy-text {
  position: absolute;
  font-family: 'Asap', sans-serif;
  font-size: 32px;
  font-weight: 700;
  font-style: italic;
  letter-spacing: -2px;
  text-align: center;
  opacity: 0;
  width: 500px;
  left: -80px;
  top: 106px;
  -webkit-transform: skew(-5deg);
  transform: skew(-5deg);
  overflow: hidden;
  white-space: nowrap;
  z-index: 16;
  color: #3232fc;
  background: -webkit-linear-gradient(0deg, #3232fc 40%, #85e367 40%, #85e367 45%, #ff52e8 45%, #ff52e8 50%, #ff0151 50%, #ff0151 55%, #f9e52e 55%, #f9e52e 60%, #f0f0f0 60%);
  background-position: 130px;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-animation: screen-gameboy-text 8s   linear;
  animation: screen-gameboy-text 8s   linear;
}

#click-here-text {
    position: absolute;
    font-family: 'Asap', sans-serif;
    font-size: 28px;
    font-weight: 700;
    font-style: italic;
    letter-spacing: -2px;
    text-align: center;
    width: 500px;
    left: -80px;
    top: 126px;
    -webkit-transform: skew(-5deg);
    transform: skew(-5deg);
    overflow: hidden;
    white-space: nowrap;
    z-index: 16;
    color: black; /* Change to black */
    opacity: 1;
    -webkit-animation: blink-text 2s infinite;
    animation: blink-text 2s infinite;
}

@-webkit-keyframes blink-text {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes blink-text {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}




@-moz-keyframes screen-nintendo-text {
  0% {
    opacity: 0;
  }
  56% {
    opacity: 0;
  }
  57% {
    opacity: 1;
  }
  87% {
    opacity: 1;
  }
  92% {
    opacity: 0;
  }
}
@-webkit-keyframes screen-nintendo-text {
  0% {
    opacity: 0;
  }
  56% {
    opacity: 0;
  }
  57% {
    opacity: 1;
  }
  87% {
    opacity: 1;
  }
  92% {
    opacity: 0;
  }
}
@keyframes screen-nintendo-text {
  0% {
    opacity: 0;
  }
  56% {
    opacity: 0;
  }
  57% {
    opacity: 1;
  }
  87% {
    opacity: 1;
  }
  92% {
    opacity: 0;
  }
}
#screen-nintendo-text {
  z-index: 16;
  position: absolute;
  opacity: 0;
  top: 167px;
  left: 138px;
  font-family: Arial Black, sans-serif;
  color: rgb(87, 81, 81);
  font-weight: 700;
  font-size: 13px;
  -webkit-animation: screen-nintendo-text 8s   linear;
  animation: screen-nintendo-text 8s   linear;
}
@-moz-keyframes joystick-pad {
  0% {
    width: 0;
    height: 0;
    left: 66px;
    bottom: 185px;
    border: 0 transparent solid;
    box-shadow: 0 0;
  }
  14% {
    width: 0;
    height: 0;
    left: 66px;
    bottom: 185px;
  }
  18% {
    width: 110px;
    height: 110px;
    left: 11px;
    bottom: 130px;
  }
  18.5% {
    border: 0 transparent solid;
  }
  20% {
    box-shadow: 0 0;
  }
  20.5% {
    border: 5px #808080 solid;
  }
  21.5% {
    border-width: 5px;
    box-shadow: 2px 4px;
  }
  22% {
    border-width: 4px;
    box-shadow: 2px 3px;
  }
}
@-webkit-keyframes joystick-pad {
  0% {
    width: 0;
    height: 0;
    left: 66px;
    bottom: 185px;
    border: 0 transparent solid;
    box-shadow: 0 0;
  }
  14% {
    width: 0;
    height: 0;
    left: 66px;
    bottom: 185px;
  }
  18% {
    width: 110px;
    height: 110px;
    left: 11px;
    bottom: 130px;
  }
  18.5% {
    border: 0 transparent solid;
  }
  20% {
    box-shadow: 0 0;
  }
  20.5% {
    border: 5px #808080 solid;
  }
  21.5% {
    border-width: 5px;
    box-shadow: 2px 4px;
  }
  22% {
    border-width: 4px;
    box-shadow: 2px 3px;
  }
}
@keyframes joystick-pad {
  0% {
    width: 0;
    height: 0;
    left: 66px;
    bottom: 185px;
    border: 0 transparent solid;
    box-shadow: 0 0;
  }
  14% {
    width: 0;
    height: 0;
    left: 66px;
    bottom: 185px;
  }
  18% {
    width: 110px;
    height: 110px;
    left: 11px;
    bottom: 130px;
  }
  18.5% {
    border: 0 transparent solid;
  }
  20% {
    box-shadow: 0 0;
  }
  20.5% {
    border: 5px #808080 solid;
  }
  21.5% {
    border-width: 5px;
    box-shadow: 2px 4px;
  }
  22% {
    border-width: 4px;
    box-shadow: 2px 3px;
  }
}
#joystick-pad {
  position: absolute;
  z-index: 5;
  width: 110px;
  height: 110px;
  border-radius: 100%;
  border: 4px #808080 solid;
  box-shadow: 2px 3px;
  background-color: #f0f0f0;
  left: 11px;
  bottom: 130px;
  -webkit-animation: joystick-pad 8s   linear;
  animation: joystick-pad 8s   linear;
}
@-moz-keyframes joystick {
  0% {
    color: transparent;
    font-size: 12px;
    line-height: 101px;
    text-indent: 54px;
  }
  18% {
    font-size: 12px;
    line-height: 101px;
    text-indent: 54px;
  }
  20% {
    color: transparent;
  }
  23.5% {
    color: #222222;
    font-size: 102px;
    line-height: 44px;
    text-indent: 43px;
  }
}
@-webkit-keyframes joystick {
  0% {
    color: transparent;
    font-size: 12px;
    line-height: 101px;
    text-indent: 54px;
  }
  18% {
    font-size: 12px;
    line-height: 101px;
    text-indent: 54px;
  }
  20% {
    color: transparent;
  }
  23.5% {
    color: #222222;
    font-size: 102px;
    line-height: 44px;
    text-indent: 43px;
  }
}
@keyframes joystick {
  0% {
    color: transparent;
    font-size: 12px;
    line-height: 101px;
    text-indent: 54px;
  }
  18% {
    font-size: 12px;
    line-height: 101px;
    text-indent: 54px;
  }
  20% {
    color: transparent;
  }
  23.5% {
    color: #222222;
    font-size: 102px;
    line-height: 44px;
    text-indent: 43px;
  }
}
@-moz-keyframes joystick-control {
  0% {
    width: 0;
    height: 0;
    padding: 0;
    padding-top: 5px;
    font-size: 0;
    line-height: 1px;
    color: transparent;
    top: 55px;
    left: 55px;
  }
  18% {
    width: 0;
    height: 0;
    padding: 0;
    padding-top: 5px;
    font-size: 0;
    line-height: 1px;
    color: transparent;
    top: 55px;
    left: 55px;
  }
  20% {
    color: transparent;
  }
  23.5% {
    width: 26px;
    height: 86px;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 0;
    font-size: 16px;
    line-height: 21px;
    color: #222222;
    top: 12px;
    left: 42px;
  }
}
@-webkit-keyframes joystick-control {
  0% {
    width: 0;
    height: 0;
    padding: 0;
    padding-top: 5px;
    font-size: 0;
    line-height: 1px;
    color: transparent;
    top: 55px;
    left: 55px;
  }
  18% {
    width: 0;
    height: 0;
    padding: 0;
    padding-top: 5px;
    font-size: 0;
    line-height: 1px;
    color: transparent;
    top: 55px;
    left: 55px;
  }
  20% {
    color: transparent;
  }
  23.5% {
    width: 26px;
    height: 86px;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 0;
    font-size: 16px;
    line-height: 21px;
    color: #222222;
    top: 12px;
    left: 42px;
  }
}
@keyframes joystick-control {
  0% {
    width: 0;
    height: 0;
    padding: 0;
    padding-top: 5px;
    font-size: 0;
    line-height: 1px;
    color: transparent;
    top: 55px;
    left: 55px;
  }
  18% {
    width: 0;
    height: 0;
    padding: 0;
    padding-top: 5px;
    font-size: 0;
    line-height: 1px;
    color: transparent;
    top: 55px;
    left: 55px;
  }
  20% {
    color: transparent;
  }
  23.5% {
    width: 26px;
    height: 86px;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 0;
    font-size: 16px;
    line-height: 21px;
    color: #222222;
    top: 12px;
    left: 42px;
  }
}
#joystick {
  position: absolute;
  z-index: 14;
  width: 110px;
  height: 110px;
  bottom: 130px;
  left: 11px;
  font-size: 102px;
  font-weight: 700;
  font-family: 'Oxygen', Helvetica, arial, sans-serif;
  line-height: 44px;
  text-indent: 43px;
  color: #222222;
  -webkit-animation: joystick 8s   linear;
  animation: joystick 8s   linear;
}
#joystick::before,
#joystick::after {
  position: absolute;
  content: "\025B2\03000\03000\025BC";
  box-sizing: border-box;
  z-index: -1;
  left: 42px;
  top: 12px;
  text-indent: 0;
  width: 26px;
  height: 86px;
  padding-left: 5px;
  padding-right: 5px;
  line-height: 21px;
  border-radius: 3px;
  background-color: #303030;
  word-wrap: break-word;
  text-align: center;
  font-size: 16px;
  color: #222222;
  -webkit-animation: joystick-control 8s   linear;
  animation: joystick-control 8s   linear;
}
#joystick::after {
  -webkit-transform: rotateZ(-90deg);
  transform: rotateZ(-90deg);
}
@-moz-keyframes control {
  0% {
    width: 0;
    height: 0;
    border: 0 transparent solid;
    box-shadow: 0 0;
    right: 86px;
    bottom: 186px;
  }
  16% {
    width: 0;
    height: 0;
    right: 86px;
    bottom: 186px;
    -webkit-transform: rotate(18deg);
    transform: rotate(18deg);
  }
  18.5% {
    width: 122px;
    height: 52px;
    right: 25px;
    bottom: 160px;
    -webkit-transform: rotate(-18deg);
    transform: rotate(-18deg);
  }
  20% {
    border: 0 #808080 solid;
  }
  22% {
    border-width: 6px;
    box-shadow: 0 0;
  }
  24% {
    border-width: 4px;
    box-shadow: 0 4px;
  }
  26% {
    box-shadow: 0 3px;
  }
  100% {
    -webkit-transform: rotate(-18deg);
    transform: rotate(-18deg);
  }
}
@-webkit-keyframes control {
  0% {
    width: 0;
    height: 0;
    border: 0 transparent solid;
    box-shadow: 0 0;
    right: 86px;
    bottom: 186px;
  }
  16% {
    width: 0;
    height: 0;
    right: 86px;
    bottom: 186px;
    -webkit-transform: rotate(18deg);
    transform: rotate(18deg);
  }
  18.5% {
    width: 122px;
    height: 52px;
    right: 25px;
    bottom: 160px;
    -webkit-transform: rotate(-18deg);
    transform: rotate(-18deg);
  }
  20% {
    border: 0 #808080 solid;
  }
  22% {
    border-width: 6px;
    box-shadow: 0 0;
  }
  24% {
    border-width: 4px;
    box-shadow: 0 4px;
  }
  26% {
    box-shadow: 0 3px;
  }
  100% {
    -webkit-transform: rotate(-18deg);
    transform: rotate(-18deg);
  }
}
@keyframes control {
  0% {
    width: 0;
    height: 0;
    border: 0 transparent solid;
    box-shadow: 0 0;
    right: 86px;
    bottom: 186px;
  }
  16% {
    width: 0;
    height: 0;
    right: 86px;
    bottom: 186px;
    -webkit-transform: rotate(18deg);
    transform: rotate(18deg);
  }
  18.5% {
    width: 122px;
    height: 52px;
    right: 25px;
    bottom: 160px;
    -webkit-transform: rotate(-18deg);
    transform: rotate(-18deg);
  }
  20% {
    border: 0 #808080 solid;
  }
  22% {
    border-width: 6px;
    box-shadow: 0 0;
  }
  24% {
    border-width: 4px;
    box-shadow: 0 4px;
  }
  26% {
    box-shadow: 0 3px;
  }
  100% {
    -webkit-transform: rotate(-18deg);
    transform: rotate(-18deg);
  }
}
#control {
  position: absolute;
  z-index: 5;
  background-color: #f0f0f0;
  width: 122px;
  height: 52px;
  bottom: 160px;
  right: 25px;
  border-radius: 20% / 50%;
  border: 4px #808080 solid;
  box-shadow: 0 3px;
  -webkit-transform: rotate(-18deg);
  transform: rotate(-18deg);
  -webkit-animation: control 8s   linear;
  animation: control 8s   linear;
}
.control-button {
  position: absolute;
  z-index: 14;
  width: 40px;
  height: 40px;
  background-color: #303030;
  border-radius: 100%;
  color: transparent;
  text-shadow: 0 0 1px #222222;
  line-height: 38px;
  text-align: center;
  font-size: 25px;
  font-weight: 300;
  font-family: 'Oxygen', Helvetica, arial, sans-serif;
}
@-moz-keyframes control-b {
  0% {
    width: 0;
    height: 0;
    right: 117px;
    bottom: 176px;
    font-size: 0;
  }
  18.5% {
    width: 0;
    height: 0;
    right: 117px;
    bottom: 176px;
  }
  20% {
    font-size: 0;
  }
  21% {
    width: 40px;
    height: 40px;
    right: 97px;
    bottom: 156px;
    font-size: 25px;
  }
}
@-webkit-keyframes control-b {
  0% {
    width: 0;
    height: 0;
    right: 117px;
    bottom: 176px;
    font-size: 0;
  }
  18.5% {
    width: 0;
    height: 0;
    right: 117px;
    bottom: 176px;
  }
  20% {
    font-size: 0;
  }
  21% {
    width: 40px;
    height: 40px;
    right: 97px;
    bottom: 156px;
    font-size: 25px;
  }
}
@keyframes control-b {
  0% {
    width: 0;
    height: 0;
    right: 117px;
    bottom: 176px;
    font-size: 0;
  }
  18.5% {
    width: 0;
    height: 0;
    right: 117px;
    bottom: 176px;
  }
  20% {
    font-size: 0;
  }
  21% {
    width: 40px;
    height: 40px;
    right: 97px;
    bottom: 156px;
    font-size: 25px;
  }
}
#control-b {
  right: 97px;
  bottom: 156px;
  -webkit-animation: control-b 8s   linear;
  animation: control-b 8s   linear;
}
@-moz-keyframes control-a {
  0% {
    width: 0;
    height: 0;
    right: 54px;
    bottom: 196px;
    font-size: 0;
  }
  20% {
    width: 0;
    height: 0;
    right: 54px;
    bottom: 196px;
  }
  21.5% {
    font-size: 0;
  }
  22.5% {
    width: 40px;
    height: 40px;
    right: 34px;
    bottom: 176px;
    font-size: 25px;
  }
}
@-webkit-keyframes control-a {
  0% {
    width: 0;
    height: 0;
    right: 54px;
    bottom: 196px;
    font-size: 0;
  }
  20% {
    width: 0;
    height: 0;
    right: 54px;
    bottom: 196px;
  }
  21.5% {
    font-size: 0;
  }
  22.5% {
    width: 40px;
    height: 40px;
    right: 34px;
    bottom: 176px;
    font-size: 25px;
  }
}
@keyframes control-a {
  0% {
    width: 0;
    height: 0;
    right: 54px;
    bottom: 196px;
    font-size: 0;
  }
  20% {
    width: 0;
    height: 0;
    right: 54px;
    bottom: 196px;
  }
  21.5% {
    font-size: 0;
  }
  22.5% {
    width: 40px;
    height: 40px;
    right: 34px;
    bottom: 176px;
    font-size: 25px;
  }
}
#control-a {
  right: 34px;
  bottom: 176px;
  -webkit-animation: control-a 8s   linear;
  animation: control-a 8s   linear;
}
@-moz-keyframes start-select-box {
  0% {
    width: 0;
    left: 170px;
  }
  18% {
    width: 0;
    left: 170px;
  }
  21.5% {
    width: 100px;
    left: 120px;
  }
}
@-webkit-keyframes start-select-box {
  0% {
    width: 0;
    left: 170px;
  }
  18% {
    width: 0;
    left: 170px;
  }
  21.5% {
    width: 100px;
    left: 120px;
  }
}
@keyframes start-select-box {
  0% {
    width: 0;
    left: 170px;
  }
  18% {
    width: 0;
    left: 170px;
  }
  21.5% {
    width: 100px;
    left: 120px;
  }
}
@-moz-keyframes start-select-pad {
  0% {
    width: 0;
    height: 0;
    left: 21px;
    bottom: 8.5px;
    box-shadow: 58px 0 #9d9d9d;
  }
  22% {
    width: 0;
    height: 0;
    left: 21px;
    bottom: 8.5px;
    box-shadow: 58px 0 #9d9d9d;
  }
  26% {
    width: 40px;
    height: 13px;
    left: 1px;
    bottom: 2px;
    box-shadow: 58px 0 #9d9d9d;
  }
  28.5% {
    left: 5px;
    box-shadow: 50px 0 #9d9d9d;
  }
}
@-webkit-keyframes start-select-pad {
  0% {
    width: 0;
    height: 0;
    left: 21px;
    bottom: 8.5px;
    box-shadow: 58px 0 #9d9d9d;
  }
  22% {
    width: 0;
    height: 0;
    left: 21px;
    bottom: 8.5px;
    box-shadow: 58px 0 #9d9d9d;
  }
  26% {
    width: 40px;
    height: 13px;
    left: 1px;
    bottom: 2px;
    box-shadow: 58px 0 #9d9d9d;
  }
  28.5% {
    left: 5px;
    box-shadow: 50px 0 #9d9d9d;
  }
}
@keyframes start-select-pad {
  0% {
    width: 0;
    height: 0;
    left: 21px;
    bottom: 8.5px;
    box-shadow: 58px 0 #9d9d9d;
  }
  22% {
    width: 0;
    height: 0;
    left: 21px;
    bottom: 8.5px;
    box-shadow: 58px 0 #9d9d9d;
  }
  26% {
    width: 40px;
    height: 13px;
    left: 1px;
    bottom: 2px;
    box-shadow: 58px 0 #9d9d9d;
  }
  28.5% {
    left: 5px;
    box-shadow: 50px 0 #9d9d9d;
  }
}
#start-select-box {
  border-top: 4px #808080 solid;
  border-bottom: 4px #808080 solid;
  position: absolute;
  z-index: 5;
  width: 100px;
  height: 25px;
  bottom: 85px;
  left: 120px;
  background-color: #f0f0f0;
  -webkit-animation: start-select-box 8s   linear;
  animation: start-select-box 8s   linear;
}
#start-select-box::before {
  content: "";
  position: absolute;
  z-index: 6;
  bottom: 2px;
  width: 40px;
  height: 13px;
  border-radius: 10px;
  background-color: #9d9d9d;
  left: 5px;
  box-shadow: 50px 0 #9d9d9d;
  -webkit-animation: start-select-pad 8s   linear;
  animation: start-select-pad 8s   linear;
}
@-moz-keyframes start-select-button {
  0% {
    width: 0;
    height: 0;
    bottom: 97.5px;
    left: 145px;
  }
  29.5% {
    width: 0;
    height: 0;
    bottom: 97.5px;
    left: 145px;
  }
  34% {
    width: 32px;
    height: 9px;
    bottom: 93px;
    left: 129px;
  }
}
@-webkit-keyframes start-select-button {
  0% {
    width: 0;
    height: 0;
    bottom: 97.5px;
    left: 145px;
  }
  29.5% {
    width: 0;
    height: 0;
    bottom: 97.5px;
    left: 145px;
  }
  34% {
    width: 32px;
    height: 9px;
    bottom: 93px;
    left: 129px;
  }
}
@keyframes start-select-button {
  0% {
    width: 0;
    height: 0;
    bottom: 97.5px;
    left: 145px;
  }
  29.5% {
    width: 0;
    height: 0;
    bottom: 97.5px;
    left: 145px;
  }
  34% {
    width: 32px;
    height: 9px;
    bottom: 93px;
    left: 129px;
  }
}
#start-select-button {
  position: absolute;
  z-index: 14;
  width: 32px;
  height: 9px;
  bottom: 93px;
  left: 129px;
  background-color: #303030;
  border-radius: 80% / 100%;
  box-shadow: 50px 0 #303030;
  -webkit-animation: start-select-button 8s   linear;
  animation: start-select-button 8s   linear;
}
#cover-vertical {
  position: absolute;
  z-index: 12;
  width: 34px;
  height: 570px;
  bottom: 0;
}
@-moz-keyframes cover-vertical-transparent {
  0% {
    width: 0;
    height: 0;
    box-shadow: none;
  }
  39% {
    width: 34px;
    height: 0;
  }
  39.90909091% {
    height: 285px;
    box-shadow: 34px 570px rgba(140, 46, 217, 0.125), 68px 570px rgba(140, 46, 217, 0.125), 102px 570px rgba(140, 46, 217, 0.125), 136px 570px rgba(140, 46, 217, 0.125), 170px 570px rgba(140, 46, 217, 0.125), 204px 570px rgba(140, 46, 217, 0.125), 238px 570px rgba(140, 46, 217, 0.125), 272px 570px rgba(140, 46, 217, 0.125), 306px 570px rgba(140, 46, 217, 0.125);
  }
  40.81818182% {
    height: 570px;
    box-shadow: 34px 285px rgba(140, 46, 217, 0.125), 68px 570px rgba(140, 46, 217, 0.125), 102px 570px rgba(140, 46, 217, 0.125), 136px 570px rgba(140, 46, 217, 0.125), 170px 570px rgba(140, 46, 217, 0.125), 204px 570px rgba(140, 46, 217, 0.125), 238px 570px rgba(140, 46, 217, 0.125), 272px 570px rgba(140, 46, 217, 0.125), 306px 570px rgba(140, 46, 217, 0.125);
  }
  41.72727273% {
    box-shadow: 34px 0 rgba(140, 46, 217, 0.125), 68px 285px rgba(140, 46, 217, 0.125), 102px 570px rgba(140, 46, 217, 0.125), 136px 570px rgba(140, 46, 217, 0.125), 170px 570px rgba(140, 46, 217, 0.125), 204px 570px rgba(140, 46, 217, 0.125), 238px 570px rgba(140, 46, 217, 0.125), 272px 570px rgba(140, 46, 217, 0.125), 306px 570px rgba(140, 46, 217, 0.125);
  }
  42.63636364% {
    box-shadow: 34px 0 rgba(140, 46, 217, 0.125), 68px 0 rgba(140, 46, 217, 0.125), 102px 285px rgba(140, 46, 217, 0.125), 136px 570px rgba(140, 46, 217, 0.125), 170px 570px rgba(140, 46, 217, 0.125), 204px 570px rgba(140, 46, 217, 0.125), 238px 570px rgba(140, 46, 217, 0.125), 272px 570px rgba(140, 46, 217, 0.125), 306px 570px rgba(140, 46, 217, 0.125);
  }
  43.54545455% {
    box-shadow: 34px 0 rgba(140, 46, 217, 0.125), 68px 0 rgba(140, 46, 217, 0.125), 102px 0 rgba(140, 46, 217, 0.125), 136px 285px rgba(140, 46, 217, 0.125), 170px 570px rgba(140, 46, 217, 0.125), 204px 570px rgba(140, 46, 217, 0.125), 238px 570px rgba(140, 46, 217, 0.125), 272px 570px rgba(140, 46, 217, 0.125), 306px 570px rgba(140, 46, 217, 0.125);
  }
  44.45454545% {
    box-shadow: 34px 0 rgba(140, 46, 217, 0.125), 68px 0 rgba(140, 46, 217, 0.125), 102px 0 rgba(140, 46, 217, 0.125), 136px 0 rgba(140, 46, 217, 0.125), 170px 285px rgba(140, 46, 217, 0.125), 204px 570px rgba(140, 46, 217, 0.125), 238px 570px rgba(140, 46, 217, 0.125), 272px 570px rgba(140, 46, 217, 0.125), 306px 570px rgba(140, 46, 217, 0.125);
  }
  45.36363636% {
    box-shadow: 34px 0 rgba(140, 46, 217, 0.125), 68px 0 rgba(140, 46, 217, 0.125), 102px 0 rgba(140, 46, 217, 0.125), 136px 0 rgba(140, 46, 217, 0.125), 170px 0 rgba(140, 46, 217, 0.125), 204px 285px rgba(140, 46, 217, 0.125), 238px 570px rgba(140, 46, 217, 0.125), 272px 570px rgba(140, 46, 217, 0.125), 306px 570px rgba(140, 46, 217, 0.125);
  }
  46.27272727% {
    box-shadow: 34px 0 rgba(140, 46, 217, 0.125), 68px 0 rgba(140, 46, 217, 0.125), 102px 0 rgba(140, 46, 217, 0.125), 136px 0 rgba(140, 46, 217, 0.125), 170px 0 rgba(140, 46, 217, 0.125), 204px 0 rgba(140, 46, 217, 0.125), 238px 285px rgba(140, 46, 217, 0.125), 272px 570px rgba(140, 46, 217, 0.125), 306px 570px rgba(140, 46, 217, 0.125);
  }
  47.18181818% {
    box-shadow: 34px 0 rgba(140, 46, 217, 0.125), 68px 0 rgba(140, 46, 217, 0.125), 102px 0 rgba(140, 46, 217, 0.125), 136px 0 rgba(140, 46, 217, 0.125), 170px 0 rgba(140, 46, 217, 0.125), 204px 0 rgba(140, 46, 217, 0.125), 238px 0 rgba(140, 46, 217, 0.125), 272px 285px rgba(140, 46, 217, 0.125), 306px 570px rgba(140, 46, 217, 0.125);
  }
  48.09090909% {
    box-shadow: 34px 0 rgba(140, 46, 217, 0.125), 68px 0 rgba(140, 46, 217, 0.125), 102px 0 rgba(140, 46, 217, 0.125), 136px 0 rgba(140, 46, 217, 0.125), 170px 0 rgba(140, 46, 217, 0.125), 204px 0 rgba(140, 46, 217, 0.125), 238px 0 rgba(140, 46, 217, 0.125), 272px 0 rgba(140, 46, 217, 0.125), 306px 285px rgba(140, 46, 217, 0.125);
  }
  49% {
    box-shadow: 34px 0 rgba(140, 46, 217, 0.125), 68px 0 rgba(140, 46, 217, 0.125), 102px 0 rgba(140, 46, 217, 0.125), 136px 0 rgba(140, 46, 217, 0.125), 170px 0 rgba(140, 46, 217, 0.125), 204px 0 rgba(140, 46, 217, 0.125), 238px 0 rgba(140, 46, 217, 0.125), 272px 0 rgba(140, 46, 217, 0.125), 306px 0 rgba(140, 46, 217, 0.125);
  }
}
@-webkit-keyframes cover-vertical-transparent {
  0% {
    width: 0;
    height: 0;
    box-shadow: none;
  }
  39% {
    width: 34px;
    height: 0;
  }
  39.90909091% {
    height: 285px;
    box-shadow: 34px 570px rgba(140, 46, 217, 0.125), 68px 570px rgba(140, 46, 217, 0.125), 102px 570px rgba(140, 46, 217, 0.125), 136px 570px rgba(140, 46, 217, 0.125), 170px 570px rgba(140, 46, 217, 0.125), 204px 570px rgba(140, 46, 217, 0.125), 238px 570px rgba(140, 46, 217, 0.125), 272px 570px rgba(140, 46, 217, 0.125), 306px 570px rgba(140, 46, 217, 0.125);
  }
  40.81818182% {
    height: 570px;
    box-shadow: 34px 285px rgba(140, 46, 217, 0.125), 68px 570px rgba(140, 46, 217, 0.125), 102px 570px rgba(140, 46, 217, 0.125), 136px 570px rgba(140, 46, 217, 0.125), 170px 570px rgba(140, 46, 217, 0.125), 204px 570px rgba(140, 46, 217, 0.125), 238px 570px rgba(140, 46, 217, 0.125), 272px 570px rgba(140, 46, 217, 0.125), 306px 570px rgba(140, 46, 217, 0.125);
  }
  41.72727273% {
    box-shadow: 34px 0 rgba(140, 46, 217, 0.125), 68px 285px rgba(140, 46, 217, 0.125), 102px 570px rgba(140, 46, 217, 0.125), 136px 570px rgba(140, 46, 217, 0.125), 170px 570px rgba(140, 46, 217, 0.125), 204px 570px rgba(140, 46, 217, 0.125), 238px 570px rgba(140, 46, 217, 0.125), 272px 570px rgba(140, 46, 217, 0.125), 306px 570px rgba(140, 46, 217, 0.125);
  }
  42.63636364% {
    box-shadow: 34px 0 rgba(140, 46, 217, 0.125), 68px 0 rgba(140, 46, 217, 0.125), 102px 285px rgba(140, 46, 217, 0.125), 136px 570px rgba(140, 46, 217, 0.125), 170px 570px rgba(140, 46, 217, 0.125), 204px 570px rgba(140, 46, 217, 0.125), 238px 570px rgba(140, 46, 217, 0.125), 272px 570px rgba(140, 46, 217, 0.125), 306px 570px rgba(140, 46, 217, 0.125);
  }
  43.54545455% {
    box-shadow: 34px 0 rgba(140, 46, 217, 0.125), 68px 0 rgba(140, 46, 217, 0.125), 102px 0 rgba(140, 46, 217, 0.125), 136px 285px rgba(140, 46, 217, 0.125), 170px 570px rgba(140, 46, 217, 0.125), 204px 570px rgba(140, 46, 217, 0.125), 238px 570px rgba(140, 46, 217, 0.125), 272px 570px rgba(140, 46, 217, 0.125), 306px 570px rgba(140, 46, 217, 0.125);
  }
  44.45454545% {
    box-shadow: 34px 0 rgba(140, 46, 217, 0.125), 68px 0 rgba(140, 46, 217, 0.125), 102px 0 rgba(140, 46, 217, 0.125), 136px 0 rgba(140, 46, 217, 0.125), 170px 285px rgba(140, 46, 217, 0.125), 204px 570px rgba(140, 46, 217, 0.125), 238px 570px rgba(140, 46, 217, 0.125), 272px 570px rgba(140, 46, 217, 0.125), 306px 570px rgba(140, 46, 217, 0.125);
  }
  45.36363636% {
    box-shadow: 34px 0 rgba(140, 46, 217, 0.125), 68px 0 rgba(140, 46, 217, 0.125), 102px 0 rgba(140, 46, 217, 0.125), 136px 0 rgba(140, 46, 217, 0.125), 170px 0 rgba(140, 46, 217, 0.125), 204px 285px rgba(140, 46, 217, 0.125), 238px 570px rgba(140, 46, 217, 0.125), 272px 570px rgba(140, 46, 217, 0.125), 306px 570px rgba(140, 46, 217, 0.125);
  }
  46.27272727% {
    box-shadow: 34px 0 rgba(140, 46, 217, 0.125), 68px 0 rgba(140, 46, 217, 0.125), 102px 0 rgba(140, 46, 217, 0.125), 136px 0 rgba(140, 46, 217, 0.125), 170px 0 rgba(140, 46, 217, 0.125), 204px 0 rgba(140, 46, 217, 0.125), 238px 285px rgba(140, 46, 217, 0.125), 272px 570px rgba(140, 46, 217, 0.125), 306px 570px rgba(140, 46, 217, 0.125);
  }
  47.18181818% {
    box-shadow: 34px 0 rgba(140, 46, 217, 0.125), 68px 0 rgba(140, 46, 217, 0.125), 102px 0 rgba(140, 46, 217, 0.125), 136px 0 rgba(140, 46, 217, 0.125), 170px 0 rgba(140, 46, 217, 0.125), 204px 0 rgba(140, 46, 217, 0.125), 238px 0 rgba(140, 46, 217, 0.125), 272px 285px rgba(140, 46, 217, 0.125), 306px 570px rgba(140, 46, 217, 0.125);
  }
  48.09090909% {
    box-shadow: 34px 0 rgba(140, 46, 217, 0.125), 68px 0 rgba(140, 46, 217, 0.125), 102px 0 rgba(140, 46, 217, 0.125), 136px 0 rgba(140, 46, 217, 0.125), 170px 0 rgba(140, 46, 217, 0.125), 204px 0 rgba(140, 46, 217, 0.125), 238px 0 rgba(140, 46, 217, 0.125), 272px 0 rgba(140, 46, 217, 0.125), 306px 285px rgba(140, 46, 217, 0.125);
  }
  49% {
    box-shadow: 34px 0 rgba(140, 46, 217, 0.125), 68px 0 rgba(140, 46, 217, 0.125), 102px 0 rgba(140, 46, 217, 0.125), 136px 0 rgba(140, 46, 217, 0.125), 170px 0 rgba(140, 46, 217, 0.125), 204px 0 rgba(140, 46, 217, 0.125), 238px 0 rgba(140, 46, 217, 0.125), 272px 0 rgba(140, 46, 217, 0.125), 306px 0 rgba(140, 46, 217, 0.125);
  }
}
@keyframes cover-vertical-transparent {
  0% {
    width: 0;
    height: 0;
    box-shadow: none;
  }
  39% {
    width: 34px;
    height: 0;
  }
  39.90909091% {
    height: 285px;
    box-shadow: 34px 570px rgba(140, 46, 217, 0.125), 68px 570px rgba(140, 46, 217, 0.125), 102px 570px rgba(140, 46, 217, 0.125), 136px 570px rgba(140, 46, 217, 0.125), 170px 570px rgba(140, 46, 217, 0.125), 204px 570px rgba(140, 46, 217, 0.125), 238px 570px rgba(140, 46, 217, 0.125), 272px 570px rgba(140, 46, 217, 0.125), 306px 570px rgba(140, 46, 217, 0.125);
  }
  40.81818182% {
    height: 570px;
    box-shadow: 34px 285px rgba(140, 46, 217, 0.125), 68px 570px rgba(140, 46, 217, 0.125), 102px 570px rgba(140, 46, 217, 0.125), 136px 570px rgba(140, 46, 217, 0.125), 170px 570px rgba(140, 46, 217, 0.125), 204px 570px rgba(140, 46, 217, 0.125), 238px 570px rgba(140, 46, 217, 0.125), 272px 570px rgba(140, 46, 217, 0.125), 306px 570px rgba(140, 46, 217, 0.125);
  }
  41.72727273% {
    box-shadow: 34px 0 rgba(140, 46, 217, 0.125), 68px 285px rgba(140, 46, 217, 0.125), 102px 570px rgba(140, 46, 217, 0.125), 136px 570px rgba(140, 46, 217, 0.125), 170px 570px rgba(140, 46, 217, 0.125), 204px 570px rgba(140, 46, 217, 0.125), 238px 570px rgba(140, 46, 217, 0.125), 272px 570px rgba(140, 46, 217, 0.125), 306px 570px rgba(140, 46, 217, 0.125);
  }
  42.63636364% {
    box-shadow: 34px 0 rgba(140, 46, 217, 0.125), 68px 0 rgba(140, 46, 217, 0.125), 102px 285px rgba(140, 46, 217, 0.125), 136px 570px rgba(140, 46, 217, 0.125), 170px 570px rgba(140, 46, 217, 0.125), 204px 570px rgba(140, 46, 217, 0.125), 238px 570px rgba(140, 46, 217, 0.125), 272px 570px rgba(140, 46, 217, 0.125), 306px 570px rgba(140, 46, 217, 0.125);
  }
  43.54545455% {
    box-shadow: 34px 0 rgba(140, 46, 217, 0.125), 68px 0 rgba(140, 46, 217, 0.125), 102px 0 rgba(140, 46, 217, 0.125), 136px 285px rgba(140, 46, 217, 0.125), 170px 570px rgba(140, 46, 217, 0.125), 204px 570px rgba(140, 46, 217, 0.125), 238px 570px rgba(140, 46, 217, 0.125), 272px 570px rgba(140, 46, 217, 0.125), 306px 570px rgba(140, 46, 217, 0.125);
  }
  44.45454545% {
    box-shadow: 34px 0 rgba(140, 46, 217, 0.125), 68px 0 rgba(140, 46, 217, 0.125), 102px 0 rgba(140, 46, 217, 0.125), 136px 0 rgba(140, 46, 217, 0.125), 170px 285px rgba(140, 46, 217, 0.125), 204px 570px rgba(140, 46, 217, 0.125), 238px 570px rgba(140, 46, 217, 0.125), 272px 570px rgba(140, 46, 217, 0.125), 306px 570px rgba(140, 46, 217, 0.125);
  }
  45.36363636% {
    box-shadow: 34px 0 rgba(140, 46, 217, 0.125), 68px 0 rgba(140, 46, 217, 0.125), 102px 0 rgba(140, 46, 217, 0.125), 136px 0 rgba(140, 46, 217, 0.125), 170px 0 rgba(140, 46, 217, 0.125), 204px 285px rgba(140, 46, 217, 0.125), 238px 570px rgba(140, 46, 217, 0.125), 272px 570px rgba(140, 46, 217, 0.125), 306px 570px rgba(140, 46, 217, 0.125);
  }
  46.27272727% {
    box-shadow: 34px 0 rgba(140, 46, 217, 0.125), 68px 0 rgba(140, 46, 217, 0.125), 102px 0 rgba(140, 46, 217, 0.125), 136px 0 rgba(140, 46, 217, 0.125), 170px 0 rgba(140, 46, 217, 0.125), 204px 0 rgba(140, 46, 217, 0.125), 238px 285px rgba(140, 46, 217, 0.125), 272px 570px rgba(140, 46, 217, 0.125), 306px 570px rgba(140, 46, 217, 0.125);
  }
  47.18181818% {
    box-shadow: 34px 0 rgba(140, 46, 217, 0.125), 68px 0 rgba(140, 46, 217, 0.125), 102px 0 rgba(140, 46, 217, 0.125), 136px 0 rgba(140, 46, 217, 0.125), 170px 0 rgba(140, 46, 217, 0.125), 204px 0 rgba(140, 46, 217, 0.125), 238px 0 rgba(140, 46, 217, 0.125), 272px 285px rgba(140, 46, 217, 0.125), 306px 570px rgba(140, 46, 217, 0.125);
  }
  48.09090909% {
    box-shadow: 34px 0 rgba(140, 46, 217, 0.125), 68px 0 rgba(140, 46, 217, 0.125), 102px 0 rgba(140, 46, 217, 0.125), 136px 0 rgba(140, 46, 217, 0.125), 170px 0 rgba(140, 46, 217, 0.125), 204px 0 rgba(140, 46, 217, 0.125), 238px 0 rgba(140, 46, 217, 0.125), 272px 0 rgba(140, 46, 217, 0.125), 306px 285px rgba(140, 46, 217, 0.125);
  }
  49% {
    box-shadow: 34px 0 rgba(140, 46, 217, 0.125), 68px 0 rgba(140, 46, 217, 0.125), 102px 0 rgba(140, 46, 217, 0.125), 136px 0 rgba(140, 46, 217, 0.125), 170px 0 rgba(140, 46, 217, 0.125), 204px 0 rgba(140, 46, 217, 0.125), 238px 0 rgba(140, 46, 217, 0.125), 272px 0 rgba(140, 46, 217, 0.125), 306px 0 rgba(140, 46, 217, 0.125);
  }
}
#gameboy.transparent #cover-vertical {
  background-color: rgba(140, 46, 217, 0.125);
  -webkit-animation: cover-vertical-transparent 8s   linear;
  animation: cover-vertical-transparent 8s   linear;
  box-shadow: 34px 0 rgba(140, 46, 217, 0.125), 68px 0 rgba(140, 46, 217, 0.125), 102px 0 rgba(140, 46, 217, 0.125), 136px 0 rgba(140, 46, 217, 0.125), 170px 0 rgba(140, 46, 217, 0.125), 204px 0 rgba(140, 46, 217, 0.125), 238px 0 rgba(140, 46, 217, 0.125), 272px 0 rgba(140, 46, 217, 0.125), 306px 0 rgba(140, 46, 217, 0.125);
}
@-moz-keyframes cover-vertical-teal {
  0% {
    width: 0;
    height: 0;
    box-shadow: none;
  }
  39% {
    width: 34px;
    height: 0;
  }
  39.90909091% {
    height: 285px;
    box-shadow: 34px 570px #01b4dd, 68px 570px #01b4dd, 102px 570px #01b4dd, 136px 570px #01b4dd, 170px 570px #01b4dd, 204px 570px #01b4dd, 238px 570px #01b4dd, 272px 570px #01b4dd, 306px 570px #01b4dd;
  }
  40.81818182% {
    height: 570px;
    box-shadow: 34px 285px #01b4dd, 68px 570px #01b4dd, 102px 570px #01b4dd, 136px 570px #01b4dd, 170px 570px #01b4dd, 204px 570px #01b4dd, 238px 570px #01b4dd, 272px 570px #01b4dd, 306px 570px #01b4dd;
  }
  41.72727273% {
    box-shadow: 34px 0 #01b4dd, 68px 285px #01b4dd, 102px 570px #01b4dd, 136px 570px #01b4dd, 170px 570px #01b4dd, 204px 570px #01b4dd, 238px 570px #01b4dd, 272px 570px #01b4dd, 306px 570px #01b4dd;
  }
  42.63636364% {
    box-shadow: 34px 0 #01b4dd, 68px 0 #01b4dd, 102px 285px #01b4dd, 136px 570px #01b4dd, 170px 570px #01b4dd, 204px 570px #01b4dd, 238px 570px #01b4dd, 272px 570px #01b4dd, 306px 570px #01b4dd;
  }
  43.54545455% {
    box-shadow: 34px 0 #01b4dd, 68px 0 #01b4dd, 102px 0 #01b4dd, 136px 285px #01b4dd, 170px 570px #01b4dd, 204px 570px #01b4dd, 238px 570px #01b4dd, 272px 570px #01b4dd, 306px 570px #01b4dd;
  }
  44.45454545% {
    box-shadow: 34px 0 #01b4dd, 68px 0 #01b4dd, 102px 0 #01b4dd, 136px 0 #01b4dd, 170px 285px #01b4dd, 204px 570px #01b4dd, 238px 570px #01b4dd, 272px 570px #01b4dd, 306px 570px #01b4dd;
  }
  45.36363636% {
    box-shadow: 34px 0 #01b4dd, 68px 0 #01b4dd, 102px 0 #01b4dd, 136px 0 #01b4dd, 170px 0 #01b4dd, 204px 285px #01b4dd, 238px 570px #01b4dd, 272px 570px #01b4dd, 306px 570px #01b4dd;
  }
  46.27272727% {
    box-shadow: 34px 0 #01b4dd, 68px 0 #01b4dd, 102px 0 #01b4dd, 136px 0 #01b4dd, 170px 0 #01b4dd, 204px 0 #01b4dd, 238px 285px #01b4dd, 272px 570px #01b4dd, 306px 570px #01b4dd;
  }
  47.18181818% {
    box-shadow: 34px 0 #01b4dd, 68px 0 #01b4dd, 102px 0 #01b4dd, 136px 0 #01b4dd, 170px 0 #01b4dd, 204px 0 #01b4dd, 238px 0 #01b4dd, 272px 285px #01b4dd, 306px 570px #01b4dd;
  }
  48.09090909% {
    box-shadow: 34px 0 #01b4dd, 68px 0 #01b4dd, 102px 0 #01b4dd, 136px 0 #01b4dd, 170px 0 #01b4dd, 204px 0 #01b4dd, 238px 0 #01b4dd, 272px 0 #01b4dd, 306px 285px #01b4dd;
  }
  49% {
    box-shadow: 34px 0 #01b4dd, 68px 0 #01b4dd, 102px 0 #01b4dd, 136px 0 #01b4dd, 170px 0 #01b4dd, 204px 0 #01b4dd, 238px 0 #01b4dd, 272px 0 #01b4dd, 306px 0 #01b4dd;
  }
}
@-webkit-keyframes cover-vertical-teal {
  0% {
    width: 0;
    height: 0;
    box-shadow: none;
  }
  39% {
    width: 34px;
    height: 0;
  }
  39.90909091% {
    height: 285px;
    box-shadow: 34px 570px #01b4dd, 68px 570px #01b4dd, 102px 570px #01b4dd, 136px 570px #01b4dd, 170px 570px #01b4dd, 204px 570px #01b4dd, 238px 570px #01b4dd, 272px 570px #01b4dd, 306px 570px #01b4dd;
  }
  40.81818182% {
    height: 570px;
    box-shadow: 34px 285px #01b4dd, 68px 570px #01b4dd, 102px 570px #01b4dd, 136px 570px #01b4dd, 170px 570px #01b4dd, 204px 570px #01b4dd, 238px 570px #01b4dd, 272px 570px #01b4dd, 306px 570px #01b4dd;
  }
  41.72727273% {
    box-shadow: 34px 0 #01b4dd, 68px 285px #01b4dd, 102px 570px #01b4dd, 136px 570px #01b4dd, 170px 570px #01b4dd, 204px 570px #01b4dd, 238px 570px #01b4dd, 272px 570px #01b4dd, 306px 570px #01b4dd;
  }
  42.63636364% {
    box-shadow: 34px 0 #01b4dd, 68px 0 #01b4dd, 102px 285px #01b4dd, 136px 570px #01b4dd, 170px 570px #01b4dd, 204px 570px #01b4dd, 238px 570px #01b4dd, 272px 570px #01b4dd, 306px 570px #01b4dd;
  }
  43.54545455% {
    box-shadow: 34px 0 #01b4dd, 68px 0 #01b4dd, 102px 0 #01b4dd, 136px 285px #01b4dd, 170px 570px #01b4dd, 204px 570px #01b4dd, 238px 570px #01b4dd, 272px 570px #01b4dd, 306px 570px #01b4dd;
  }
  44.45454545% {
    box-shadow: 34px 0 #01b4dd, 68px 0 #01b4dd, 102px 0 #01b4dd, 136px 0 #01b4dd, 170px 285px #01b4dd, 204px 570px #01b4dd, 238px 570px #01b4dd, 272px 570px #01b4dd, 306px 570px #01b4dd;
  }
  45.36363636% {
    box-shadow: 34px 0 #01b4dd, 68px 0 #01b4dd, 102px 0 #01b4dd, 136px 0 #01b4dd, 170px 0 #01b4dd, 204px 285px #01b4dd, 238px 570px #01b4dd, 272px 570px #01b4dd, 306px 570px #01b4dd;
  }
  46.27272727% {
    box-shadow: 34px 0 #01b4dd, 68px 0 #01b4dd, 102px 0 #01b4dd, 136px 0 #01b4dd, 170px 0 #01b4dd, 204px 0 #01b4dd, 238px 285px #01b4dd, 272px 570px #01b4dd, 306px 570px #01b4dd;
  }
  47.18181818% {
    box-shadow: 34px 0 #01b4dd, 68px 0 #01b4dd, 102px 0 #01b4dd, 136px 0 #01b4dd, 170px 0 #01b4dd, 204px 0 #01b4dd, 238px 0 #01b4dd, 272px 285px #01b4dd, 306px 570px #01b4dd;
  }
  48.09090909% {
    box-shadow: 34px 0 #01b4dd, 68px 0 #01b4dd, 102px 0 #01b4dd, 136px 0 #01b4dd, 170px 0 #01b4dd, 204px 0 #01b4dd, 238px 0 #01b4dd, 272px 0 #01b4dd, 306px 285px #01b4dd;
  }
  49% {
    box-shadow: 34px 0 #01b4dd, 68px 0 #01b4dd, 102px 0 #01b4dd, 136px 0 #01b4dd, 170px 0 #01b4dd, 204px 0 #01b4dd, 238px 0 #01b4dd, 272px 0 #01b4dd, 306px 0 #01b4dd;
  }
}
@keyframes cover-vertical-teal {
  0% {
    width: 0;
    height: 0;
    box-shadow: none;
  }
  39% {
    width: 34px;
    height: 0;
  }
  39.90909091% {
    height: 285px;
    box-shadow: 34px 570px #01b4dd, 68px 570px #01b4dd, 102px 570px #01b4dd, 136px 570px #01b4dd, 170px 570px #01b4dd, 204px 570px #01b4dd, 238px 570px #01b4dd, 272px 570px #01b4dd, 306px 570px #01b4dd;
  }
  40.81818182% {
    height: 570px;
    box-shadow: 34px 285px #01b4dd, 68px 570px #01b4dd, 102px 570px #01b4dd, 136px 570px #01b4dd, 170px 570px #01b4dd, 204px 570px #01b4dd, 238px 570px #01b4dd, 272px 570px #01b4dd, 306px 570px #01b4dd;
  }
  41.72727273% {
    box-shadow: 34px 0 #01b4dd, 68px 285px #01b4dd, 102px 570px #01b4dd, 136px 570px #01b4dd, 170px 570px #01b4dd, 204px 570px #01b4dd, 238px 570px #01b4dd, 272px 570px #01b4dd, 306px 570px #01b4dd;
  }
  42.63636364% {
    box-shadow: 34px 0 #01b4dd, 68px 0 #01b4dd, 102px 285px #01b4dd, 136px 570px #01b4dd, 170px 570px #01b4dd, 204px 570px #01b4dd, 238px 570px #01b4dd, 272px 570px #01b4dd, 306px 570px #01b4dd;
  }
  43.54545455% {
    box-shadow: 34px 0 #01b4dd, 68px 0 #01b4dd, 102px 0 #01b4dd, 136px 285px #01b4dd, 170px 570px #01b4dd, 204px 570px #01b4dd, 238px 570px #01b4dd, 272px 570px #01b4dd, 306px 570px #01b4dd;
  }
  44.45454545% {
    box-shadow: 34px 0 #01b4dd, 68px 0 #01b4dd, 102px 0 #01b4dd, 136px 0 #01b4dd, 170px 285px #01b4dd, 204px 570px #01b4dd, 238px 570px #01b4dd, 272px 570px #01b4dd, 306px 570px #01b4dd;
  }
  45.36363636% {
    box-shadow: 34px 0 #01b4dd, 68px 0 #01b4dd, 102px 0 #01b4dd, 136px 0 #01b4dd, 170px 0 #01b4dd, 204px 285px #01b4dd, 238px 570px #01b4dd, 272px 570px #01b4dd, 306px 570px #01b4dd;
  }
  46.27272727% {
    box-shadow: 34px 0 #01b4dd, 68px 0 #01b4dd, 102px 0 #01b4dd, 136px 0 #01b4dd, 170px 0 #01b4dd, 204px 0 #01b4dd, 238px 285px #01b4dd, 272px 570px #01b4dd, 306px 570px #01b4dd;
  }
  47.18181818% {
    box-shadow: 34px 0 #01b4dd, 68px 0 #01b4dd, 102px 0 #01b4dd, 136px 0 #01b4dd, 170px 0 #01b4dd, 204px 0 #01b4dd, 238px 0 #01b4dd, 272px 285px #01b4dd, 306px 570px #01b4dd;
  }
  48.09090909% {
    box-shadow: 34px 0 #01b4dd, 68px 0 #01b4dd, 102px 0 #01b4dd, 136px 0 #01b4dd, 170px 0 #01b4dd, 204px 0 #01b4dd, 238px 0 #01b4dd, 272px 0 #01b4dd, 306px 285px #01b4dd;
  }
  49% {
    box-shadow: 34px 0 #01b4dd, 68px 0 #01b4dd, 102px 0 #01b4dd, 136px 0 #01b4dd, 170px 0 #01b4dd, 204px 0 #01b4dd, 238px 0 #01b4dd, 272px 0 #01b4dd, 306px 0 #01b4dd;
  }
}
#gameboy.teal #cover-vertical {
  background-color: #01b4dd;
  -webkit-animation: cover-vertical-teal 8s   linear;
  animation: cover-vertical-teal 8s   linear;
  box-shadow: 34px 0 #01b4dd, 68px 0 #01b4dd, 102px 0 #01b4dd, 136px 0 #01b4dd, 170px 0 #01b4dd, 204px 0 #01b4dd, 238px 0 #01b4dd, 272px 0 #01b4dd, 306px 0 #01b4dd;
}
@-moz-keyframes cover-vertical-yellow {
  0% {
    width: 0;
    height: 0;
    box-shadow: none;
  }
  39% {
    width: 34px;
    height: 0;
  }
  39.90909091% {
    height: 285px;
    box-shadow: 34px 570px #f9e52e, 68px 570px #f9e52e, 102px 570px #f9e52e, 136px 570px #f9e52e, 170px 570px #f9e52e, 204px 570px #f9e52e, 238px 570px #f9e52e, 272px 570px #f9e52e, 306px 570px #f9e52e;
  }
  40.81818182% {
    height: 570px;
    box-shadow: 34px 285px #f9e52e, 68px 570px #f9e52e, 102px 570px #f9e52e, 136px 570px #f9e52e, 170px 570px #f9e52e, 204px 570px #f9e52e, 238px 570px #f9e52e, 272px 570px #f9e52e, 306px 570px #f9e52e;
  }
  41.72727273% {
    box-shadow: 34px 0 #f9e52e, 68px 285px #f9e52e, 102px 570px #f9e52e, 136px 570px #f9e52e, 170px 570px #f9e52e, 204px 570px #f9e52e, 238px 570px #f9e52e, 272px 570px #f9e52e, 306px 570px #f9e52e;
  }
  42.63636364% {
    box-shadow: 34px 0 #f9e52e, 68px 0 #f9e52e, 102px 285px #f9e52e, 136px 570px #f9e52e, 170px 570px #f9e52e, 204px 570px #f9e52e, 238px 570px #f9e52e, 272px 570px #f9e52e, 306px 570px #f9e52e;
  }
  43.54545455% {
    box-shadow: 34px 0 #f9e52e, 68px 0 #f9e52e, 102px 0 #f9e52e, 136px 285px #f9e52e, 170px 570px #f9e52e, 204px 570px #f9e52e, 238px 570px #f9e52e, 272px 570px #f9e52e, 306px 570px #f9e52e;
  }
  44.45454545% {
    box-shadow: 34px 0 #f9e52e, 68px 0 #f9e52e, 102px 0 #f9e52e, 136px 0 #f9e52e, 170px 285px #f9e52e, 204px 570px #f9e52e, 238px 570px #f9e52e, 272px 570px #f9e52e, 306px 570px #f9e52e;
  }
  45.36363636% {
    box-shadow: 34px 0 #f9e52e, 68px 0 #f9e52e, 102px 0 #f9e52e, 136px 0 #f9e52e, 170px 0 #f9e52e, 204px 285px #f9e52e, 238px 570px #f9e52e, 272px 570px #f9e52e, 306px 570px #f9e52e;
  }
  46.27272727% {
    box-shadow: 34px 0 #f9e52e, 68px 0 #f9e52e, 102px 0 #f9e52e, 136px 0 #f9e52e, 170px 0 #f9e52e, 204px 0 #f9e52e, 238px 285px #f9e52e, 272px 570px #f9e52e, 306px 570px #f9e52e;
  }
  47.18181818% {
    box-shadow: 34px 0 #f9e52e, 68px 0 #f9e52e, 102px 0 #f9e52e, 136px 0 #f9e52e, 170px 0 #f9e52e, 204px 0 #f9e52e, 238px 0 #f9e52e, 272px 285px #f9e52e, 306px 570px #f9e52e;
  }
  48.09090909% {
    box-shadow: 34px 0 #f9e52e, 68px 0 #f9e52e, 102px 0 #f9e52e, 136px 0 #f9e52e, 170px 0 #f9e52e, 204px 0 #f9e52e, 238px 0 #f9e52e, 272px 0 #f9e52e, 306px 285px #f9e52e;
  }
  49% {
    box-shadow: 34px 0 #f9e52e, 68px 0 #f9e52e, 102px 0 #f9e52e, 136px 0 #f9e52e, 170px 0 #f9e52e, 204px 0 #f9e52e, 238px 0 #f9e52e, 272px 0 #f9e52e, 306px 0 #f9e52e;
  }
}
@-webkit-keyframes cover-vertical-yellow {
  0% {
    width: 0;
    height: 0;
    box-shadow: none;
  }
  39% {
    width: 34px;
    height: 0;
  }
  39.90909091% {
    height: 285px;
    box-shadow: 34px 570px #f9e52e, 68px 570px #f9e52e, 102px 570px #f9e52e, 136px 570px #f9e52e, 170px 570px #f9e52e, 204px 570px #f9e52e, 238px 570px #f9e52e, 272px 570px #f9e52e, 306px 570px #f9e52e;
  }
  40.81818182% {
    height: 570px;
    box-shadow: 34px 285px #f9e52e, 68px 570px #f9e52e, 102px 570px #f9e52e, 136px 570px #f9e52e, 170px 570px #f9e52e, 204px 570px #f9e52e, 238px 570px #f9e52e, 272px 570px #f9e52e, 306px 570px #f9e52e;
  }
  41.72727273% {
    box-shadow: 34px 0 #f9e52e, 68px 285px #f9e52e, 102px 570px #f9e52e, 136px 570px #f9e52e, 170px 570px #f9e52e, 204px 570px #f9e52e, 238px 570px #f9e52e, 272px 570px #f9e52e, 306px 570px #f9e52e;
  }
  42.63636364% {
    box-shadow: 34px 0 #f9e52e, 68px 0 #f9e52e, 102px 285px #f9e52e, 136px 570px #f9e52e, 170px 570px #f9e52e, 204px 570px #f9e52e, 238px 570px #f9e52e, 272px 570px #f9e52e, 306px 570px #f9e52e;
  }
  43.54545455% {
    box-shadow: 34px 0 #f9e52e, 68px 0 #f9e52e, 102px 0 #f9e52e, 136px 285px #f9e52e, 170px 570px #f9e52e, 204px 570px #f9e52e, 238px 570px #f9e52e, 272px 570px #f9e52e, 306px 570px #f9e52e;
  }
  44.45454545% {
    box-shadow: 34px 0 #f9e52e, 68px 0 #f9e52e, 102px 0 #f9e52e, 136px 0 #f9e52e, 170px 285px #f9e52e, 204px 570px #f9e52e, 238px 570px #f9e52e, 272px 570px #f9e52e, 306px 570px #f9e52e;
  }
  45.36363636% {
    box-shadow: 34px 0 #f9e52e, 68px 0 #f9e52e, 102px 0 #f9e52e, 136px 0 #f9e52e, 170px 0 #f9e52e, 204px 285px #f9e52e, 238px 570px #f9e52e, 272px 570px #f9e52e, 306px 570px #f9e52e;
  }
  46.27272727% {
    box-shadow: 34px 0 #f9e52e, 68px 0 #f9e52e, 102px 0 #f9e52e, 136px 0 #f9e52e, 170px 0 #f9e52e, 204px 0 #f9e52e, 238px 285px #f9e52e, 272px 570px #f9e52e, 306px 570px #f9e52e;
  }
  47.18181818% {
    box-shadow: 34px 0 #f9e52e, 68px 0 #f9e52e, 102px 0 #f9e52e, 136px 0 #f9e52e, 170px 0 #f9e52e, 204px 0 #f9e52e, 238px 0 #f9e52e, 272px 285px #f9e52e, 306px 570px #f9e52e;
  }
  48.09090909% {
    box-shadow: 34px 0 #f9e52e, 68px 0 #f9e52e, 102px 0 #f9e52e, 136px 0 #f9e52e, 170px 0 #f9e52e, 204px 0 #f9e52e, 238px 0 #f9e52e, 272px 0 #f9e52e, 306px 285px #f9e52e;
  }
  49% {
    box-shadow: 34px 0 #f9e52e, 68px 0 #f9e52e, 102px 0 #f9e52e, 136px 0 #f9e52e, 170px 0 #f9e52e, 204px 0 #f9e52e, 238px 0 #f9e52e, 272px 0 #f9e52e, 306px 0 #f9e52e;
  }
}
@keyframes cover-vertical-yellow {
  0% {
    width: 0;
    height: 0;
    box-shadow: none;
  }
  39% {
    width: 34px;
    height: 0;
  }
  39.90909091% {
    height: 285px;
    box-shadow: 34px 570px #f9e52e, 68px 570px #f9e52e, 102px 570px #f9e52e, 136px 570px #f9e52e, 170px 570px #f9e52e, 204px 570px #f9e52e, 238px 570px #f9e52e, 272px 570px #f9e52e, 306px 570px #f9e52e;
  }
  40.81818182% {
    height: 570px;
    box-shadow: 34px 285px #f9e52e, 68px 570px #f9e52e, 102px 570px #f9e52e, 136px 570px #f9e52e, 170px 570px #f9e52e, 204px 570px #f9e52e, 238px 570px #f9e52e, 272px 570px #f9e52e, 306px 570px #f9e52e;
  }
  41.72727273% {
    box-shadow: 34px 0 #f9e52e, 68px 285px #f9e52e, 102px 570px #f9e52e, 136px 570px #f9e52e, 170px 570px #f9e52e, 204px 570px #f9e52e, 238px 570px #f9e52e, 272px 570px #f9e52e, 306px 570px #f9e52e;
  }
  42.63636364% {
    box-shadow: 34px 0 #f9e52e, 68px 0 #f9e52e, 102px 285px #f9e52e, 136px 570px #f9e52e, 170px 570px #f9e52e, 204px 570px #f9e52e, 238px 570px #f9e52e, 272px 570px #f9e52e, 306px 570px #f9e52e;
  }
  43.54545455% {
    box-shadow: 34px 0 #f9e52e, 68px 0 #f9e52e, 102px 0 #f9e52e, 136px 285px #f9e52e, 170px 570px #f9e52e, 204px 570px #f9e52e, 238px 570px #f9e52e, 272px 570px #f9e52e, 306px 570px #f9e52e;
  }
  44.45454545% {
    box-shadow: 34px 0 #f9e52e, 68px 0 #f9e52e, 102px 0 #f9e52e, 136px 0 #f9e52e, 170px 285px #f9e52e, 204px 570px #f9e52e, 238px 570px #f9e52e, 272px 570px #f9e52e, 306px 570px #f9e52e;
  }
  45.36363636% {
    box-shadow: 34px 0 #f9e52e, 68px 0 #f9e52e, 102px 0 #f9e52e, 136px 0 #f9e52e, 170px 0 #f9e52e, 204px 285px #f9e52e, 238px 570px #f9e52e, 272px 570px #f9e52e, 306px 570px #f9e52e;
  }
  46.27272727% {
    box-shadow: 34px 0 #f9e52e, 68px 0 #f9e52e, 102px 0 #f9e52e, 136px 0 #f9e52e, 170px 0 #f9e52e, 204px 0 #f9e52e, 238px 285px #f9e52e, 272px 570px #f9e52e, 306px 570px #f9e52e;
  }
  47.18181818% {
    box-shadow: 34px 0 #f9e52e, 68px 0 #f9e52e, 102px 0 #f9e52e, 136px 0 #f9e52e, 170px 0 #f9e52e, 204px 0 #f9e52e, 238px 0 #f9e52e, 272px 285px #f9e52e, 306px 570px #f9e52e;
  }
  48.09090909% {
    box-shadow: 34px 0 #f9e52e, 68px 0 #f9e52e, 102px 0 #f9e52e, 136px 0 #f9e52e, 170px 0 #f9e52e, 204px 0 #f9e52e, 238px 0 #f9e52e, 272px 0 #f9e52e, 306px 285px #f9e52e;
  }
  49% {
    box-shadow: 34px 0 #f9e52e, 68px 0 #f9e52e, 102px 0 #f9e52e, 136px 0 #f9e52e, 170px 0 #f9e52e, 204px 0 #f9e52e, 238px 0 #f9e52e, 272px 0 #f9e52e, 306px 0 #f9e52e;
  }
}
#gameboy.yellow #cover-vertical {
  background-color: #f9e52e;
  -webkit-animation: cover-vertical-yellow 8s   linear;
  animation: cover-vertical-yellow 8s   linear;
  box-shadow: 34px 0 #f9e52e, 68px 0 #f9e52e, 102px 0 #f9e52e, 136px 0 #f9e52e, 170px 0 #f9e52e, 204px 0 #f9e52e, 238px 0 #f9e52e, 272px 0 #f9e52e, 306px 0 #f9e52e;
}
@-moz-keyframes cover-vertical-green {
  0% {
    width: 0;
    height: 0;
    box-shadow: none;
  }
  39% {
    width: 34px;
    height: 0;
  }
  39.90909091% {
    height: 285px;
    box-shadow: 34px 570px #85e367, 68px 570px #85e367, 102px 570px #85e367, 136px 570px #85e367, 170px 570px #85e367, 204px 570px #85e367, 238px 570px #85e367, 272px 570px #85e367, 306px 570px #85e367;
  }
  40.81818182% {
    height: 570px;
    box-shadow: 34px 285px #85e367, 68px 570px #85e367, 102px 570px #85e367, 136px 570px #85e367, 170px 570px #85e367, 204px 570px #85e367, 238px 570px #85e367, 272px 570px #85e367, 306px 570px #85e367;
  }
  41.72727273% {
    box-shadow: 34px 0 #85e367, 68px 285px #85e367, 102px 570px #85e367, 136px 570px #85e367, 170px 570px #85e367, 204px 570px #85e367, 238px 570px #85e367, 272px 570px #85e367, 306px 570px #85e367;
  }
  42.63636364% {
    box-shadow: 34px 0 #85e367, 68px 0 #85e367, 102px 285px #85e367, 136px 570px #85e367, 170px 570px #85e367, 204px 570px #85e367, 238px 570px #85e367, 272px 570px #85e367, 306px 570px #85e367;
  }
  43.54545455% {
    box-shadow: 34px 0 #85e367, 68px 0 #85e367, 102px 0 #85e367, 136px 285px #85e367, 170px 570px #85e367, 204px 570px #85e367, 238px 570px #85e367, 272px 570px #85e367, 306px 570px #85e367;
  }
  44.45454545% {
    box-shadow: 34px 0 #85e367, 68px 0 #85e367, 102px 0 #85e367, 136px 0 #85e367, 170px 285px #85e367, 204px 570px #85e367, 238px 570px #85e367, 272px 570px #85e367, 306px 570px #85e367;
  }
  45.36363636% {
    box-shadow: 34px 0 #85e367, 68px 0 #85e367, 102px 0 #85e367, 136px 0 #85e367, 170px 0 #85e367, 204px 285px #85e367, 238px 570px #85e367, 272px 570px #85e367, 306px 570px #85e367;
  }
  46.27272727% {
    box-shadow: 34px 0 #85e367, 68px 0 #85e367, 102px 0 #85e367, 136px 0 #85e367, 170px 0 #85e367, 204px 0 #85e367, 238px 285px #85e367, 272px 570px #85e367, 306px 570px #85e367;
  }
  47.18181818% {
    box-shadow: 34px 0 #85e367, 68px 0 #85e367, 102px 0 #85e367, 136px 0 #85e367, 170px 0 #85e367, 204px 0 #85e367, 238px 0 #85e367, 272px 285px #85e367, 306px 570px #85e367;
  }
  48.09090909% {
    box-shadow: 34px 0 #85e367, 68px 0 #85e367, 102px 0 #85e367, 136px 0 #85e367, 170px 0 #85e367, 204px 0 #85e367, 238px 0 #85e367, 272px 0 #85e367, 306px 285px #85e367;
  }
  49% {
    box-shadow: 34px 0 #85e367, 68px 0 #85e367, 102px 0 #85e367, 136px 0 #85e367, 170px 0 #85e367, 204px 0 #85e367, 238px 0 #85e367, 272px 0 #85e367, 306px 0 #85e367;
  }
}
@-webkit-keyframes cover-vertical-green {
  0% {
    width: 0;
    height: 0;
    box-shadow: none;
  }
  39% {
    width: 34px;
    height: 0;
  }
  39.90909091% {
    height: 285px;
    box-shadow: 34px 570px #85e367, 68px 570px #85e367, 102px 570px #85e367, 136px 570px #85e367, 170px 570px #85e367, 204px 570px #85e367, 238px 570px #85e367, 272px 570px #85e367, 306px 570px #85e367;
  }
  40.81818182% {
    height: 570px;
    box-shadow: 34px 285px #85e367, 68px 570px #85e367, 102px 570px #85e367, 136px 570px #85e367, 170px 570px #85e367, 204px 570px #85e367, 238px 570px #85e367, 272px 570px #85e367, 306px 570px #85e367;
  }
  41.72727273% {
    box-shadow: 34px 0 #85e367, 68px 285px #85e367, 102px 570px #85e367, 136px 570px #85e367, 170px 570px #85e367, 204px 570px #85e367, 238px 570px #85e367, 272px 570px #85e367, 306px 570px #85e367;
  }
  42.63636364% {
    box-shadow: 34px 0 #85e367, 68px 0 #85e367, 102px 285px #85e367, 136px 570px #85e367, 170px 570px #85e367, 204px 570px #85e367, 238px 570px #85e367, 272px 570px #85e367, 306px 570px #85e367;
  }
  43.54545455% {
    box-shadow: 34px 0 #85e367, 68px 0 #85e367, 102px 0 #85e367, 136px 285px #85e367, 170px 570px #85e367, 204px 570px #85e367, 238px 570px #85e367, 272px 570px #85e367, 306px 570px #85e367;
  }
  44.45454545% {
    box-shadow: 34px 0 #85e367, 68px 0 #85e367, 102px 0 #85e367, 136px 0 #85e367, 170px 285px #85e367, 204px 570px #85e367, 238px 570px #85e367, 272px 570px #85e367, 306px 570px #85e367;
  }
  45.36363636% {
    box-shadow: 34px 0 #85e367, 68px 0 #85e367, 102px 0 #85e367, 136px 0 #85e367, 170px 0 #85e367, 204px 285px #85e367, 238px 570px #85e367, 272px 570px #85e367, 306px 570px #85e367;
  }
  46.27272727% {
    box-shadow: 34px 0 #85e367, 68px 0 #85e367, 102px 0 #85e367, 136px 0 #85e367, 170px 0 #85e367, 204px 0 #85e367, 238px 285px #85e367, 272px 570px #85e367, 306px 570px #85e367;
  }
  47.18181818% {
    box-shadow: 34px 0 #85e367, 68px 0 #85e367, 102px 0 #85e367, 136px 0 #85e367, 170px 0 #85e367, 204px 0 #85e367, 238px 0 #85e367, 272px 285px #85e367, 306px 570px #85e367;
  }
  48.09090909% {
    box-shadow: 34px 0 #85e367, 68px 0 #85e367, 102px 0 #85e367, 136px 0 #85e367, 170px 0 #85e367, 204px 0 #85e367, 238px 0 #85e367, 272px 0 #85e367, 306px 285px #85e367;
  }
  49% {
    box-shadow: 34px 0 #85e367, 68px 0 #85e367, 102px 0 #85e367, 136px 0 #85e367, 170px 0 #85e367, 204px 0 #85e367, 238px 0 #85e367, 272px 0 #85e367, 306px 0 #85e367;
  }
}
@keyframes cover-vertical-green {
  0% {
    width: 0;
    height: 0;
    box-shadow: none;
  }
  39% {
    width: 34px;
    height: 0;
  }
  39.90909091% {
    height: 285px;
    box-shadow: 34px 570px #85e367, 68px 570px #85e367, 102px 570px #85e367, 136px 570px #85e367, 170px 570px #85e367, 204px 570px #85e367, 238px 570px #85e367, 272px 570px #85e367, 306px 570px #85e367;
  }
  40.81818182% {
    height: 570px;
    box-shadow: 34px 285px #85e367, 68px 570px #85e367, 102px 570px #85e367, 136px 570px #85e367, 170px 570px #85e367, 204px 570px #85e367, 238px 570px #85e367, 272px 570px #85e367, 306px 570px #85e367;
  }
  41.72727273% {
    box-shadow: 34px 0 #85e367, 68px 285px #85e367, 102px 570px #85e367, 136px 570px #85e367, 170px 570px #85e367, 204px 570px #85e367, 238px 570px #85e367, 272px 570px #85e367, 306px 570px #85e367;
  }
  42.63636364% {
    box-shadow: 34px 0 #85e367, 68px 0 #85e367, 102px 285px #85e367, 136px 570px #85e367, 170px 570px #85e367, 204px 570px #85e367, 238px 570px #85e367, 272px 570px #85e367, 306px 570px #85e367;
  }
  43.54545455% {
    box-shadow: 34px 0 #85e367, 68px 0 #85e367, 102px 0 #85e367, 136px 285px #85e367, 170px 570px #85e367, 204px 570px #85e367, 238px 570px #85e367, 272px 570px #85e367, 306px 570px #85e367;
  }
  44.45454545% {
    box-shadow: 34px 0 #85e367, 68px 0 #85e367, 102px 0 #85e367, 136px 0 #85e367, 170px 285px #85e367, 204px 570px #85e367, 238px 570px #85e367, 272px 570px #85e367, 306px 570px #85e367;
  }
  45.36363636% {
    box-shadow: 34px 0 #85e367, 68px 0 #85e367, 102px 0 #85e367, 136px 0 #85e367, 170px 0 #85e367, 204px 285px #85e367, 238px 570px #85e367, 272px 570px #85e367, 306px 570px #85e367;
  }
  46.27272727% {
    box-shadow: 34px 0 #85e367, 68px 0 #85e367, 102px 0 #85e367, 136px 0 #85e367, 170px 0 #85e367, 204px 0 #85e367, 238px 285px #85e367, 272px 570px #85e367, 306px 570px #85e367;
  }
  47.18181818% {
    box-shadow: 34px 0 #85e367, 68px 0 #85e367, 102px 0 #85e367, 136px 0 #85e367, 170px 0 #85e367, 204px 0 #85e367, 238px 0 #85e367, 272px 285px #85e367, 306px 570px #85e367;
  }
  48.09090909% {
    box-shadow: 34px 0 #85e367, 68px 0 #85e367, 102px 0 #85e367, 136px 0 #85e367, 170px 0 #85e367, 204px 0 #85e367, 238px 0 #85e367, 272px 0 #85e367, 306px 285px #85e367;
  }
  49% {
    box-shadow: 34px 0 #85e367, 68px 0 #85e367, 102px 0 #85e367, 136px 0 #85e367, 170px 0 #85e367, 204px 0 #85e367, 238px 0 #85e367, 272px 0 #85e367, 306px 0 #85e367;
  }
}
#gameboy.green #cover-vertical {
  background-color: #85e367;
  -webkit-animation: cover-vertical-green 8s   linear;
  animation: cover-vertical-green 8s   linear;
  box-shadow: 34px 0 #85e367, 68px 0 #85e367, 102px 0 #85e367, 136px 0 #85e367, 170px 0 #85e367, 204px 0 #85e367, 238px 0 #85e367, 272px 0 #85e367, 306px 0 #85e367;
}
@-moz-keyframes cover-vertical-purple {
  0% {
    width: 0;
    height: 0;
    box-shadow: none;
  }
  39% {
    width: 34px;
    height: 0;
  }
  39.90909091% {
    height: 285px;
    box-shadow: 34px 570px #5151dd, 68px 570px #5151dd, 102px 570px #5151dd, 136px 570px #5151dd, 170px 570px #5151dd, 204px 570px #5151dd, 238px 570px #5151dd, 272px 570px #5151dd, 306px 570px #5151dd;
  }
  40.81818182% {
    height: 570px;
    box-shadow: 34px 285px #5151dd, 68px 570px #5151dd, 102px 570px #5151dd, 136px 570px #5151dd, 170px 570px #5151dd, 204px 570px #5151dd, 238px 570px #5151dd, 272px 570px #5151dd, 306px 570px #5151dd;
  }
  41.72727273% {
    box-shadow: 34px 0 #5151dd, 68px 285px #5151dd, 102px 570px #5151dd, 136px 570px #5151dd, 170px 570px #5151dd, 204px 570px #5151dd, 238px 570px #5151dd, 272px 570px #5151dd, 306px 570px #5151dd;
  }
  42.63636364% {
    box-shadow: 34px 0 #5151dd, 68px 0 #5151dd, 102px 285px #5151dd, 136px 570px #5151dd, 170px 570px #5151dd, 204px 570px #5151dd, 238px 570px #5151dd, 272px 570px #5151dd, 306px 570px #5151dd;
  }
  43.54545455% {
    box-shadow: 34px 0 #5151dd, 68px 0 #5151dd, 102px 0 #5151dd, 136px 285px #5151dd, 170px 570px #5151dd, 204px 570px #5151dd, 238px 570px #5151dd, 272px 570px #5151dd, 306px 570px #5151dd;
  }
  44.45454545% {
    box-shadow: 34px 0 #5151dd, 68px 0 #5151dd, 102px 0 #5151dd, 136px 0 #5151dd, 170px 285px #5151dd, 204px 570px #5151dd, 238px 570px #5151dd, 272px 570px #5151dd, 306px 570px #5151dd;
  }
  45.36363636% {
    box-shadow: 34px 0 #5151dd, 68px 0 #5151dd, 102px 0 #5151dd, 136px 0 #5151dd, 170px 0 #5151dd, 204px 285px #5151dd, 238px 570px #5151dd, 272px 570px #5151dd, 306px 570px #5151dd;
  }
  46.27272727% {
    box-shadow: 34px 0 #5151dd, 68px 0 #5151dd, 102px 0 #5151dd, 136px 0 #5151dd, 170px 0 #5151dd, 204px 0 #5151dd, 238px 285px #5151dd, 272px 570px #5151dd, 306px 570px #5151dd;
  }
  47.18181818% {
    box-shadow: 34px 0 #5151dd, 68px 0 #5151dd, 102px 0 #5151dd, 136px 0 #5151dd, 170px 0 #5151dd, 204px 0 #5151dd, 238px 0 #5151dd, 272px 285px #5151dd, 306px 570px #5151dd;
  }
  48.09090909% {
    box-shadow: 34px 0 #5151dd, 68px 0 #5151dd, 102px 0 #5151dd, 136px 0 #5151dd, 170px 0 #5151dd, 204px 0 #5151dd, 238px 0 #5151dd, 272px 0 #5151dd, 306px 285px #5151dd;
  }
  49% {
    box-shadow: 34px 0 #5151dd, 68px 0 #5151dd, 102px 0 #5151dd, 136px 0 #5151dd, 170px 0 #5151dd, 204px 0 #5151dd, 238px 0 #5151dd, 272px 0 #5151dd, 306px 0 #5151dd;
  }
}
@-webkit-keyframes cover-vertical-purple {
  0% {
    width: 0;
    height: 0;
    box-shadow: none;
  }
  39% {
    width: 34px;
    height: 0;
  }
  39.90909091% {
    height: 285px;
    box-shadow: 34px 570px #5151dd, 68px 570px #5151dd, 102px 570px #5151dd, 136px 570px #5151dd, 170px 570px #5151dd, 204px 570px #5151dd, 238px 570px #5151dd, 272px 570px #5151dd, 306px 570px #5151dd;
  }
  40.81818182% {
    height: 570px;
    box-shadow: 34px 285px #5151dd, 68px 570px #5151dd, 102px 570px #5151dd, 136px 570px #5151dd, 170px 570px #5151dd, 204px 570px #5151dd, 238px 570px #5151dd, 272px 570px #5151dd, 306px 570px #5151dd;
  }
  41.72727273% {
    box-shadow: 34px 0 #5151dd, 68px 285px #5151dd, 102px 570px #5151dd, 136px 570px #5151dd, 170px 570px #5151dd, 204px 570px #5151dd, 238px 570px #5151dd, 272px 570px #5151dd, 306px 570px #5151dd;
  }
  42.63636364% {
    box-shadow: 34px 0 #5151dd, 68px 0 #5151dd, 102px 285px #5151dd, 136px 570px #5151dd, 170px 570px #5151dd, 204px 570px #5151dd, 238px 570px #5151dd, 272px 570px #5151dd, 306px 570px #5151dd;
  }
  43.54545455% {
    box-shadow: 34px 0 #5151dd, 68px 0 #5151dd, 102px 0 #5151dd, 136px 285px #5151dd, 170px 570px #5151dd, 204px 570px #5151dd, 238px 570px #5151dd, 272px 570px #5151dd, 306px 570px #5151dd;
  }
  44.45454545% {
    box-shadow: 34px 0 #5151dd, 68px 0 #5151dd, 102px 0 #5151dd, 136px 0 #5151dd, 170px 285px #5151dd, 204px 570px #5151dd, 238px 570px #5151dd, 272px 570px #5151dd, 306px 570px #5151dd;
  }
  45.36363636% {
    box-shadow: 34px 0 #5151dd, 68px 0 #5151dd, 102px 0 #5151dd, 136px 0 #5151dd, 170px 0 #5151dd, 204px 285px #5151dd, 238px 570px #5151dd, 272px 570px #5151dd, 306px 570px #5151dd;
  }
  46.27272727% {
    box-shadow: 34px 0 #5151dd, 68px 0 #5151dd, 102px 0 #5151dd, 136px 0 #5151dd, 170px 0 #5151dd, 204px 0 #5151dd, 238px 285px #5151dd, 272px 570px #5151dd, 306px 570px #5151dd;
  }
  47.18181818% {
    box-shadow: 34px 0 #5151dd, 68px 0 #5151dd, 102px 0 #5151dd, 136px 0 #5151dd, 170px 0 #5151dd, 204px 0 #5151dd, 238px 0 #5151dd, 272px 285px #5151dd, 306px 570px #5151dd;
  }
  48.09090909% {
    box-shadow: 34px 0 #5151dd, 68px 0 #5151dd, 102px 0 #5151dd, 136px 0 #5151dd, 170px 0 #5151dd, 204px 0 #5151dd, 238px 0 #5151dd, 272px 0 #5151dd, 306px 285px #5151dd;
  }
  49% {
    box-shadow: 34px 0 #5151dd, 68px 0 #5151dd, 102px 0 #5151dd, 136px 0 #5151dd, 170px 0 #5151dd, 204px 0 #5151dd, 238px 0 #5151dd, 272px 0 #5151dd, 306px 0 #5151dd;
  }
}
@keyframes cover-vertical-purple {
  0% {
    width: 0;
    height: 0;
    box-shadow: none;
  }
  39% {
    width: 34px;
    height: 0;
  }
  39.90909091% {
    height: 285px;
    box-shadow: 34px 570px #5151dd, 68px 570px #5151dd, 102px 570px #5151dd, 136px 570px #5151dd, 170px 570px #5151dd, 204px 570px #5151dd, 238px 570px #5151dd, 272px 570px #5151dd, 306px 570px #5151dd;
  }
  40.81818182% {
    height: 570px;
    box-shadow: 34px 285px #5151dd, 68px 570px #5151dd, 102px 570px #5151dd, 136px 570px #5151dd, 170px 570px #5151dd, 204px 570px #5151dd, 238px 570px #5151dd, 272px 570px #5151dd, 306px 570px #5151dd;
  }
  41.72727273% {
    box-shadow: 34px 0 #5151dd, 68px 285px #5151dd, 102px 570px #5151dd, 136px 570px #5151dd, 170px 570px #5151dd, 204px 570px #5151dd, 238px 570px #5151dd, 272px 570px #5151dd, 306px 570px #5151dd;
  }
  42.63636364% {
    box-shadow: 34px 0 #5151dd, 68px 0 #5151dd, 102px 285px #5151dd, 136px 570px #5151dd, 170px 570px #5151dd, 204px 570px #5151dd, 238px 570px #5151dd, 272px 570px #5151dd, 306px 570px #5151dd;
  }
  43.54545455% {
    box-shadow: 34px 0 #5151dd, 68px 0 #5151dd, 102px 0 #5151dd, 136px 285px #5151dd, 170px 570px #5151dd, 204px 570px #5151dd, 238px 570px #5151dd, 272px 570px #5151dd, 306px 570px #5151dd;
  }
  44.45454545% {
    box-shadow: 34px 0 #5151dd, 68px 0 #5151dd, 102px 0 #5151dd, 136px 0 #5151dd, 170px 285px #5151dd, 204px 570px #5151dd, 238px 570px #5151dd, 272px 570px #5151dd, 306px 570px #5151dd;
  }
  45.36363636% {
    box-shadow: 34px 0 #5151dd, 68px 0 #5151dd, 102px 0 #5151dd, 136px 0 #5151dd, 170px 0 #5151dd, 204px 285px #5151dd, 238px 570px #5151dd, 272px 570px #5151dd, 306px 570px #5151dd;
  }
  46.27272727% {
    box-shadow: 34px 0 #5151dd, 68px 0 #5151dd, 102px 0 #5151dd, 136px 0 #5151dd, 170px 0 #5151dd, 204px 0 #5151dd, 238px 285px #5151dd, 272px 570px #5151dd, 306px 570px #5151dd;
  }
  47.18181818% {
    box-shadow: 34px 0 #5151dd, 68px 0 #5151dd, 102px 0 #5151dd, 136px 0 #5151dd, 170px 0 #5151dd, 204px 0 #5151dd, 238px 0 #5151dd, 272px 285px #5151dd, 306px 570px #5151dd;
  }
  48.09090909% {
    box-shadow: 34px 0 #5151dd, 68px 0 #5151dd, 102px 0 #5151dd, 136px 0 #5151dd, 170px 0 #5151dd, 204px 0 #5151dd, 238px 0 #5151dd, 272px 0 #5151dd, 306px 285px #5151dd;
  }
  49% {
    box-shadow: 34px 0 #5151dd, 68px 0 #5151dd, 102px 0 #5151dd, 136px 0 #5151dd, 170px 0 #5151dd, 204px 0 #5151dd, 238px 0 #5151dd, 272px 0 #5151dd, 306px 0 #5151dd;
  }
}
#gameboy.purple #cover-vertical {
  background-color: #5151dd;
  -webkit-animation: cover-vertical-purple 8s   linear;
  animation: cover-vertical-purple 8s   linear;
  box-shadow: 34px 0 #5151dd, 68px 0 #5151dd, 102px 0 #5151dd, 136px 0 #5151dd, 170px 0 #5151dd, 204px 0 #5151dd, 238px 0 #5151dd, 272px 0 #5151dd, 306px 0 #5151dd;
}
@-moz-keyframes cover-vertical-red {
  0% {
    width: 0;
    height: 0;
    box-shadow: none;
  }
  39% {
    width: 34px;
    height: 0;
  }
  39.90909091% {
    height: 285px;
    box-shadow: 34px 570px #ff0151, 68px 570px #ff0151, 102px 570px #ff0151, 136px 570px #ff0151, 170px 570px #ff0151, 204px 570px #ff0151, 238px 570px #ff0151, 272px 570px #ff0151, 306px 570px #ff0151;
  }
  40.81818182% {
    height: 570px;
    box-shadow: 34px 285px #ff0151, 68px 570px #ff0151, 102px 570px #ff0151, 136px 570px #ff0151, 170px 570px #ff0151, 204px 570px #ff0151, 238px 570px #ff0151, 272px 570px #ff0151, 306px 570px #ff0151;
  }
  41.72727273% {
    box-shadow: 34px 0 #ff0151, 68px 285px #ff0151, 102px 570px #ff0151, 136px 570px #ff0151, 170px 570px #ff0151, 204px 570px #ff0151, 238px 570px #ff0151, 272px 570px #ff0151, 306px 570px #ff0151;
  }
  42.63636364% {
    box-shadow: 34px 0 #ff0151, 68px 0 #ff0151, 102px 285px #ff0151, 136px 570px #ff0151, 170px 570px #ff0151, 204px 570px #ff0151, 238px 570px #ff0151, 272px 570px #ff0151, 306px 570px #ff0151;
  }
  43.54545455% {
    box-shadow: 34px 0 #ff0151, 68px 0 #ff0151, 102px 0 #ff0151, 136px 285px #ff0151, 170px 570px #ff0151, 204px 570px #ff0151, 238px 570px #ff0151, 272px 570px #ff0151, 306px 570px #ff0151;
  }
  44.45454545% {
    box-shadow: 34px 0 #ff0151, 68px 0 #ff0151, 102px 0 #ff0151, 136px 0 #ff0151, 170px 285px #ff0151, 204px 570px #ff0151, 238px 570px #ff0151, 272px 570px #ff0151, 306px 570px #ff0151;
  }
  45.36363636% {
    box-shadow: 34px 0 #ff0151, 68px 0 #ff0151, 102px 0 #ff0151, 136px 0 #ff0151, 170px 0 #ff0151, 204px 285px #ff0151, 238px 570px #ff0151, 272px 570px #ff0151, 306px 570px #ff0151;
  }
  46.27272727% {
    box-shadow: 34px 0 #ff0151, 68px 0 #ff0151, 102px 0 #ff0151, 136px 0 #ff0151, 170px 0 #ff0151, 204px 0 #ff0151, 238px 285px #ff0151, 272px 570px #ff0151, 306px 570px #ff0151;
  }
  47.18181818% {
    box-shadow: 34px 0 #ff0151, 68px 0 #ff0151, 102px 0 #ff0151, 136px 0 #ff0151, 170px 0 #ff0151, 204px 0 #ff0151, 238px 0 #ff0151, 272px 285px #ff0151, 306px 570px #ff0151;
  }
  48.09090909% {
    box-shadow: 34px 0 #ff0151, 68px 0 #ff0151, 102px 0 #ff0151, 136px 0 #ff0151, 170px 0 #ff0151, 204px 0 #ff0151, 238px 0 #ff0151, 272px 0 #ff0151, 306px 285px #ff0151;
  }
  49% {
    box-shadow: 34px 0 #ff0151, 68px 0 #ff0151, 102px 0 #ff0151, 136px 0 #ff0151, 170px 0 #ff0151, 204px 0 #ff0151, 238px 0 #ff0151, 272px 0 #ff0151, 306px 0 #ff0151;
  }
}
@-webkit-keyframes cover-vertical-red {
  0% {
    width: 0;
    height: 0;
    box-shadow: none;
  }
  39% {
    width: 34px;
    height: 0;
  }
  39.90909091% {
    height: 285px;
    box-shadow: 34px 570px #ff0151, 68px 570px #ff0151, 102px 570px #ff0151, 136px 570px #ff0151, 170px 570px #ff0151, 204px 570px #ff0151, 238px 570px #ff0151, 272px 570px #ff0151, 306px 570px #ff0151;
  }
  40.81818182% {
    height: 570px;
    box-shadow: 34px 285px #ff0151, 68px 570px #ff0151, 102px 570px #ff0151, 136px 570px #ff0151, 170px 570px #ff0151, 204px 570px #ff0151, 238px 570px #ff0151, 272px 570px #ff0151, 306px 570px #ff0151;
  }
  41.72727273% {
    box-shadow: 34px 0 #ff0151, 68px 285px #ff0151, 102px 570px #ff0151, 136px 570px #ff0151, 170px 570px #ff0151, 204px 570px #ff0151, 238px 570px #ff0151, 272px 570px #ff0151, 306px 570px #ff0151;
  }
  42.63636364% {
    box-shadow: 34px 0 #ff0151, 68px 0 #ff0151, 102px 285px #ff0151, 136px 570px #ff0151, 170px 570px #ff0151, 204px 570px #ff0151, 238px 570px #ff0151, 272px 570px #ff0151, 306px 570px #ff0151;
  }
  43.54545455% {
    box-shadow: 34px 0 #ff0151, 68px 0 #ff0151, 102px 0 #ff0151, 136px 285px #ff0151, 170px 570px #ff0151, 204px 570px #ff0151, 238px 570px #ff0151, 272px 570px #ff0151, 306px 570px #ff0151;
  }
  44.45454545% {
    box-shadow: 34px 0 #ff0151, 68px 0 #ff0151, 102px 0 #ff0151, 136px 0 #ff0151, 170px 285px #ff0151, 204px 570px #ff0151, 238px 570px #ff0151, 272px 570px #ff0151, 306px 570px #ff0151;
  }
  45.36363636% {
    box-shadow: 34px 0 #ff0151, 68px 0 #ff0151, 102px 0 #ff0151, 136px 0 #ff0151, 170px 0 #ff0151, 204px 285px #ff0151, 238px 570px #ff0151, 272px 570px #ff0151, 306px 570px #ff0151;
  }
  46.27272727% {
    box-shadow: 34px 0 #ff0151, 68px 0 #ff0151, 102px 0 #ff0151, 136px 0 #ff0151, 170px 0 #ff0151, 204px 0 #ff0151, 238px 285px #ff0151, 272px 570px #ff0151, 306px 570px #ff0151;
  }
  47.18181818% {
    box-shadow: 34px 0 #ff0151, 68px 0 #ff0151, 102px 0 #ff0151, 136px 0 #ff0151, 170px 0 #ff0151, 204px 0 #ff0151, 238px 0 #ff0151, 272px 285px #ff0151, 306px 570px #ff0151;
  }
  48.09090909% {
    box-shadow: 34px 0 #ff0151, 68px 0 #ff0151, 102px 0 #ff0151, 136px 0 #ff0151, 170px 0 #ff0151, 204px 0 #ff0151, 238px 0 #ff0151, 272px 0 #ff0151, 306px 285px #ff0151;
  }
  49% {
    box-shadow: 34px 0 #ff0151, 68px 0 #ff0151, 102px 0 #ff0151, 136px 0 #ff0151, 170px 0 #ff0151, 204px 0 #ff0151, 238px 0 #ff0151, 272px 0 #ff0151, 306px 0 #ff0151;
  }
}
@keyframes cover-vertical-red {
  0% {
    width: 0;
    height: 0;
    box-shadow: none;
  }
  39% {
    width: 34px;
    height: 0;
  }
  39.90909091% {
    height: 285px;
    box-shadow: 34px 570px #ff0151, 68px 570px #ff0151, 102px 570px #ff0151, 136px 570px #ff0151, 170px 570px #ff0151, 204px 570px #ff0151, 238px 570px #ff0151, 272px 570px #ff0151, 306px 570px #ff0151;
  }
  40.81818182% {
    height: 570px;
    box-shadow: 34px 285px #ff0151, 68px 570px #ff0151, 102px 570px #ff0151, 136px 570px #ff0151, 170px 570px #ff0151, 204px 570px #ff0151, 238px 570px #ff0151, 272px 570px #ff0151, 306px 570px #ff0151;
  }
  41.72727273% {
    box-shadow: 34px 0 #ff0151, 68px 285px #ff0151, 102px 570px #ff0151, 136px 570px #ff0151, 170px 570px #ff0151, 204px 570px #ff0151, 238px 570px #ff0151, 272px 570px #ff0151, 306px 570px #ff0151;
  }
  42.63636364% {
    box-shadow: 34px 0 #ff0151, 68px 0 #ff0151, 102px 285px #ff0151, 136px 570px #ff0151, 170px 570px #ff0151, 204px 570px #ff0151, 238px 570px #ff0151, 272px 570px #ff0151, 306px 570px #ff0151;
  }
  43.54545455% {
    box-shadow: 34px 0 #ff0151, 68px 0 #ff0151, 102px 0 #ff0151, 136px 285px #ff0151, 170px 570px #ff0151, 204px 570px #ff0151, 238px 570px #ff0151, 272px 570px #ff0151, 306px 570px #ff0151;
  }
  44.45454545% {
    box-shadow: 34px 0 #ff0151, 68px 0 #ff0151, 102px 0 #ff0151, 136px 0 #ff0151, 170px 285px #ff0151, 204px 570px #ff0151, 238px 570px #ff0151, 272px 570px #ff0151, 306px 570px #ff0151;
  }
  45.36363636% {
    box-shadow: 34px 0 #ff0151, 68px 0 #ff0151, 102px 0 #ff0151, 136px 0 #ff0151, 170px 0 #ff0151, 204px 285px #ff0151, 238px 570px #ff0151, 272px 570px #ff0151, 306px 570px #ff0151;
  }
  46.27272727% {
    box-shadow: 34px 0 #ff0151, 68px 0 #ff0151, 102px 0 #ff0151, 136px 0 #ff0151, 170px 0 #ff0151, 204px 0 #ff0151, 238px 285px #ff0151, 272px 570px #ff0151, 306px 570px #ff0151;
  }
  47.18181818% {
    box-shadow: 34px 0 #ff0151, 68px 0 #ff0151, 102px 0 #ff0151, 136px 0 #ff0151, 170px 0 #ff0151, 204px 0 #ff0151, 238px 0 #ff0151, 272px 285px #ff0151, 306px 570px #ff0151;
  }
  48.09090909% {
    box-shadow: 34px 0 #ff0151, 68px 0 #ff0151, 102px 0 #ff0151, 136px 0 #ff0151, 170px 0 #ff0151, 204px 0 #ff0151, 238px 0 #ff0151, 272px 0 #ff0151, 306px 285px #ff0151;
  }
  49% {
    box-shadow: 34px 0 #ff0151, 68px 0 #ff0151, 102px 0 #ff0151, 136px 0 #ff0151, 170px 0 #ff0151, 204px 0 #ff0151, 238px 0 #ff0151, 272px 0 #ff0151, 306px 0 #ff0151;
  }
}
#gameboy.red #cover-vertical {
  background-color: #ff0151;
  -webkit-animation: cover-vertical-red 8s   linear;
  animation: cover-vertical-red 8s   linear;
  box-shadow: 34px 0 #ff0151, 68px 0 #ff0151, 102px 0 #ff0151, 136px 0 #ff0151, 170px 0 #ff0151, 204px 0 #ff0151, 238px 0 #ff0151, 272px 0 #ff0151, 306px 0 #ff0151;
}
#cover-horizontal {
  position: absolute;
  z-index: 12;
  width: 340px;
  height: 38px;
  bottom: 0;
  left: 0;
}
@-moz-keyframes cover-horizontal-transparent {
  0% {
    width: 0;
    height: 0;
    box-shadow: none;
  }
  39% {
    width: 0;
    height: 38px;
  }
  39.625% {
    width: 170px;
    box-shadow: -340px -38px rgba(140, 46, 217, 0.125), -340px -76px rgba(140, 46, 217, 0.125), -340px -114px rgba(140, 46, 217, 0.125), -340px -152px rgba(140, 46, 217, 0.125), -340px -190px rgba(140, 46, 217, 0.125), -340px -228px rgba(140, 46, 217, 0.125), -340px -266px rgba(140, 46, 217, 0.125), -340px -304px rgba(140, 46, 217, 0.125), -340px -342px rgba(140, 46, 217, 0.125), -340px -380px rgba(140, 46, 217, 0.125), -340px -418px rgba(140, 46, 217, 0.125), -340px -456px rgba(140, 46, 217, 0.125), -340px -494px rgba(140, 46, 217, 0.125), -340px -532px rgba(140, 46, 217, 0.125);
  }
  40.25% {
    width: 340px;
    box-shadow: -170px -38px rgba(140, 46, 217, 0.125), -340px -76px rgba(140, 46, 217, 0.125), -340px -114px rgba(140, 46, 217, 0.125), -340px -152px rgba(140, 46, 217, 0.125), -340px -190px rgba(140, 46, 217, 0.125), -340px -228px rgba(140, 46, 217, 0.125), -340px -266px rgba(140, 46, 217, 0.125), -340px -304px rgba(140, 46, 217, 0.125), -340px -342px rgba(140, 46, 217, 0.125), -340px -380px rgba(140, 46, 217, 0.125), -340px -418px rgba(140, 46, 217, 0.125), -340px -456px rgba(140, 46, 217, 0.125), -340px -494px rgba(140, 46, 217, 0.125), -340px -532px rgba(140, 46, 217, 0.125);
  }
  40.875% {
    box-shadow: 0 -38px rgba(140, 46, 217, 0.125), -170px -76px rgba(140, 46, 217, 0.125), -340px -114px rgba(140, 46, 217, 0.125), -340px -152px rgba(140, 46, 217, 0.125), -340px -190px rgba(140, 46, 217, 0.125), -340px -228px rgba(140, 46, 217, 0.125), -340px -266px rgba(140, 46, 217, 0.125), -340px -304px rgba(140, 46, 217, 0.125), -340px -342px rgba(140, 46, 217, 0.125), -340px -380px rgba(140, 46, 217, 0.125), -340px -418px rgba(140, 46, 217, 0.125), -340px -456px rgba(140, 46, 217, 0.125), -340px -494px rgba(140, 46, 217, 0.125), -340px -532px rgba(140, 46, 217, 0.125);
  }
  41.5% {
    box-shadow: 0 -38px rgba(140, 46, 217, 0.125), 0 -76px rgba(140, 46, 217, 0.125), -170px -114px rgba(140, 46, 217, 0.125), -340px -152px rgba(140, 46, 217, 0.125), -340px -190px rgba(140, 46, 217, 0.125), -340px -228px rgba(140, 46, 217, 0.125), -340px -266px rgba(140, 46, 217, 0.125), -340px -304px rgba(140, 46, 217, 0.125), -340px -342px rgba(140, 46, 217, 0.125), -340px -380px rgba(140, 46, 217, 0.125), -340px -418px rgba(140, 46, 217, 0.125), -340px -456px rgba(140, 46, 217, 0.125), -340px -494px rgba(140, 46, 217, 0.125), -340px -532px rgba(140, 46, 217, 0.125);
  }
  42.125% {
    box-shadow: 0 -38px rgba(140, 46, 217, 0.125), 0 -76px rgba(140, 46, 217, 0.125), 0 -114px rgba(140, 46, 217, 0.125), -170px -152px rgba(140, 46, 217, 0.125), -340px -190px rgba(140, 46, 217, 0.125), -340px -228px rgba(140, 46, 217, 0.125), -340px -266px rgba(140, 46, 217, 0.125), -340px -304px rgba(140, 46, 217, 0.125), -340px -342px rgba(140, 46, 217, 0.125), -340px -380px rgba(140, 46, 217, 0.125), -340px -418px rgba(140, 46, 217, 0.125), -340px -456px rgba(140, 46, 217, 0.125), -340px -494px rgba(140, 46, 217, 0.125), -340px -532px rgba(140, 46, 217, 0.125);
  }
  42.75% {
    box-shadow: 0 -38px rgba(140, 46, 217, 0.125), 0 -76px rgba(140, 46, 217, 0.125), 0 -114px rgba(140, 46, 217, 0.125), 0 -152px rgba(140, 46, 217, 0.125), -170px -190px rgba(140, 46, 217, 0.125), -340px -228px rgba(140, 46, 217, 0.125), -340px -266px rgba(140, 46, 217, 0.125), -340px -304px rgba(140, 46, 217, 0.125), -340px -342px rgba(140, 46, 217, 0.125), -340px -380px rgba(140, 46, 217, 0.125), -340px -418px rgba(140, 46, 217, 0.125), -340px -456px rgba(140, 46, 217, 0.125), -340px -494px rgba(140, 46, 217, 0.125), -340px -532px rgba(140, 46, 217, 0.125);
  }
  43.375% {
    box-shadow: 0 -38px rgba(140, 46, 217, 0.125), 0 -76px rgba(140, 46, 217, 0.125), 0 -114px rgba(140, 46, 217, 0.125), 0 -152px rgba(140, 46, 217, 0.125), 0 -190px rgba(140, 46, 217, 0.125), -170px -228px rgba(140, 46, 217, 0.125), -340px -266px rgba(140, 46, 217, 0.125), -340px -304px rgba(140, 46, 217, 0.125), -340px -342px rgba(140, 46, 217, 0.125), -340px -380px rgba(140, 46, 217, 0.125), -340px -418px rgba(140, 46, 217, 0.125), -340px -456px rgba(140, 46, 217, 0.125), -340px -494px rgba(140, 46, 217, 0.125), -340px -532px rgba(140, 46, 217, 0.125);
  }
  44% {
    box-shadow: 0 -38px rgba(140, 46, 217, 0.125), 0 -76px rgba(140, 46, 217, 0.125), 0 -114px rgba(140, 46, 217, 0.125), 0 -152px rgba(140, 46, 217, 0.125), 0 -190px rgba(140, 46, 217, 0.125), 0 -228px rgba(140, 46, 217, 0.125), -170px -266px rgba(140, 46, 217, 0.125), -340px -304px rgba(140, 46, 217, 0.125), -340px -342px rgba(140, 46, 217, 0.125), -340px -380px rgba(140, 46, 217, 0.125), -340px -418px rgba(140, 46, 217, 0.125), -340px -456px rgba(140, 46, 217, 0.125), -340px -494px rgba(140, 46, 217, 0.125), -340px -532px rgba(140, 46, 217, 0.125);
  }
  44.625% {
    box-shadow: 0 -38px rgba(140, 46, 217, 0.125), 0 -76px rgba(140, 46, 217, 0.125), 0 -114px rgba(140, 46, 217, 0.125), 0 -152px rgba(140, 46, 217, 0.125), 0 -190px rgba(140, 46, 217, 0.125), 0 -228px rgba(140, 46, 217, 0.125), 0 -266px rgba(140, 46, 217, 0.125), -170px -304px rgba(140, 46, 217, 0.125), -340px -342px rgba(140, 46, 217, 0.125), -340px -380px rgba(140, 46, 217, 0.125), -340px -418px rgba(140, 46, 217, 0.125), -340px -456px rgba(140, 46, 217, 0.125), -340px -494px rgba(140, 46, 217, 0.125), -340px -532px rgba(140, 46, 217, 0.125);
  }
  45.25% {
    box-shadow: 0 -38px rgba(140, 46, 217, 0.125), 0 -76px rgba(140, 46, 217, 0.125), 0 -114px rgba(140, 46, 217, 0.125), 0 -152px rgba(140, 46, 217, 0.125), 0 -190px rgba(140, 46, 217, 0.125), 0 -228px rgba(140, 46, 217, 0.125), 0 -266px rgba(140, 46, 217, 0.125), 0 -304px rgba(140, 46, 217, 0.125), -170px -342px rgba(140, 46, 217, 0.125), -340px -380px rgba(140, 46, 217, 0.125), -340px -418px rgba(140, 46, 217, 0.125), -340px -456px rgba(140, 46, 217, 0.125), -340px -494px rgba(140, 46, 217, 0.125), -340px -532px rgba(140, 46, 217, 0.125);
  }
  45.875% {
    box-shadow: 0 -38px rgba(140, 46, 217, 0.125), 0 -76px rgba(140, 46, 217, 0.125), 0 -114px rgba(140, 46, 217, 0.125), 0 -152px rgba(140, 46, 217, 0.125), 0 -190px rgba(140, 46, 217, 0.125), 0 -228px rgba(140, 46, 217, 0.125), 0 -266px rgba(140, 46, 217, 0.125), 0 -304px rgba(140, 46, 217, 0.125), 0 -342px rgba(140, 46, 217, 0.125), -170px -380px rgba(140, 46, 217, 0.125), -340px -418px rgba(140, 46, 217, 0.125), -340px -456px rgba(140, 46, 217, 0.125), -340px -494px rgba(140, 46, 217, 0.125), -340px -532px rgba(140, 46, 217, 0.125);
  }
  46.5% {
    box-shadow: 0 -38px rgba(140, 46, 217, 0.125), 0 -76px rgba(140, 46, 217, 0.125), 0 -114px rgba(140, 46, 217, 0.125), 0 -152px rgba(140, 46, 217, 0.125), 0 -190px rgba(140, 46, 217, 0.125), 0 -228px rgba(140, 46, 217, 0.125), 0 -266px rgba(140, 46, 217, 0.125), 0 -304px rgba(140, 46, 217, 0.125), 0 -342px rgba(140, 46, 217, 0.125), 0 -380px rgba(140, 46, 217, 0.125), -170px -418px rgba(140, 46, 217, 0.125), -340px -456px rgba(140, 46, 217, 0.125), -340px -494px rgba(140, 46, 217, 0.125), -340px -532px rgba(140, 46, 217, 0.125);
  }
  47.125% {
    box-shadow: 0 -38px rgba(140, 46, 217, 0.125), 0 -76px rgba(140, 46, 217, 0.125), 0 -114px rgba(140, 46, 217, 0.125), 0 -152px rgba(140, 46, 217, 0.125), 0 -190px rgba(140, 46, 217, 0.125), 0 -228px rgba(140, 46, 217, 0.125), 0 -266px rgba(140, 46, 217, 0.125), 0 -304px rgba(140, 46, 217, 0.125), 0 -342px rgba(140, 46, 217, 0.125), 0 -380px rgba(140, 46, 217, 0.125), 0 -418px rgba(140, 46, 217, 0.125), -170px -456px rgba(140, 46, 217, 0.125), -340px -494px rgba(140, 46, 217, 0.125), -340px -532px rgba(140, 46, 217, 0.125);
  }
  47.75% {
    box-shadow: 0 -38px rgba(140, 46, 217, 0.125), 0 -76px rgba(140, 46, 217, 0.125), 0 -114px rgba(140, 46, 217, 0.125), 0 -152px rgba(140, 46, 217, 0.125), 0 -190px rgba(140, 46, 217, 0.125), 0 -228px rgba(140, 46, 217, 0.125), 0 -266px rgba(140, 46, 217, 0.125), 0 -304px rgba(140, 46, 217, 0.125), 0 -342px rgba(140, 46, 217, 0.125), 0 -380px rgba(140, 46, 217, 0.125), 0 -418px rgba(140, 46, 217, 0.125), 0 -456px rgba(140, 46, 217, 0.125), -170px -494px rgba(140, 46, 217, 0.125), -340px -532px rgba(140, 46, 217, 0.125);
  }
  48.375% {
    box-shadow: 0 -38px rgba(140, 46, 217, 0.125), 0 -76px rgba(140, 46, 217, 0.125), 0 -114px rgba(140, 46, 217, 0.125), 0 -152px rgba(140, 46, 217, 0.125), 0 -190px rgba(140, 46, 217, 0.125), 0 -228px rgba(140, 46, 217, 0.125), 0 -266px rgba(140, 46, 217, 0.125), 0 -304px rgba(140, 46, 217, 0.125), 0 -342px rgba(140, 46, 217, 0.125), 0 -380px rgba(140, 46, 217, 0.125), 0 -418px rgba(140, 46, 217, 0.125), 0 -456px rgba(140, 46, 217, 0.125), 0 -494px rgba(140, 46, 217, 0.125), -170px -532px rgba(140, 46, 217, 0.125);
  }
  49% {
    box-shadow: 0 -38px rgba(140, 46, 217, 0.125), 0 -76px rgba(140, 46, 217, 0.125), 0 -114px rgba(140, 46, 217, 0.125), 0 -152px rgba(140, 46, 217, 0.125), 0 -190px rgba(140, 46, 217, 0.125), 0 -228px rgba(140, 46, 217, 0.125), 0 -266px rgba(140, 46, 217, 0.125), 0 -304px rgba(140, 46, 217, 0.125), 0 -342px rgba(140, 46, 217, 0.125), 0 -380px rgba(140, 46, 217, 0.125), 0 -418px rgba(140, 46, 217, 0.125), 0 -456px rgba(140, 46, 217, 0.125), 0 -494px rgba(140, 46, 217, 0.125), 0 -532px rgba(140, 46, 217, 0.125);
  }
}
@-webkit-keyframes cover-horizontal-transparent {
  0% {
    width: 0;
    height: 0;
    box-shadow: none;
  }
  39% {
    width: 0;
    height: 38px;
  }
  39.625% {
    width: 170px;
    box-shadow: -340px -38px rgba(140, 46, 217, 0.125), -340px -76px rgba(140, 46, 217, 0.125), -340px -114px rgba(140, 46, 217, 0.125), -340px -152px rgba(140, 46, 217, 0.125), -340px -190px rgba(140, 46, 217, 0.125), -340px -228px rgba(140, 46, 217, 0.125), -340px -266px rgba(140, 46, 217, 0.125), -340px -304px rgba(140, 46, 217, 0.125), -340px -342px rgba(140, 46, 217, 0.125), -340px -380px rgba(140, 46, 217, 0.125), -340px -418px rgba(140, 46, 217, 0.125), -340px -456px rgba(140, 46, 217, 0.125), -340px -494px rgba(140, 46, 217, 0.125), -340px -532px rgba(140, 46, 217, 0.125);
  }
  40.25% {
    width: 340px;
    box-shadow: -170px -38px rgba(140, 46, 217, 0.125), -340px -76px rgba(140, 46, 217, 0.125), -340px -114px rgba(140, 46, 217, 0.125), -340px -152px rgba(140, 46, 217, 0.125), -340px -190px rgba(140, 46, 217, 0.125), -340px -228px rgba(140, 46, 217, 0.125), -340px -266px rgba(140, 46, 217, 0.125), -340px -304px rgba(140, 46, 217, 0.125), -340px -342px rgba(140, 46, 217, 0.125), -340px -380px rgba(140, 46, 217, 0.125), -340px -418px rgba(140, 46, 217, 0.125), -340px -456px rgba(140, 46, 217, 0.125), -340px -494px rgba(140, 46, 217, 0.125), -340px -532px rgba(140, 46, 217, 0.125);
  }
  40.875% {
    box-shadow: 0 -38px rgba(140, 46, 217, 0.125), -170px -76px rgba(140, 46, 217, 0.125), -340px -114px rgba(140, 46, 217, 0.125), -340px -152px rgba(140, 46, 217, 0.125), -340px -190px rgba(140, 46, 217, 0.125), -340px -228px rgba(140, 46, 217, 0.125), -340px -266px rgba(140, 46, 217, 0.125), -340px -304px rgba(140, 46, 217, 0.125), -340px -342px rgba(140, 46, 217, 0.125), -340px -380px rgba(140, 46, 217, 0.125), -340px -418px rgba(140, 46, 217, 0.125), -340px -456px rgba(140, 46, 217, 0.125), -340px -494px rgba(140, 46, 217, 0.125), -340px -532px rgba(140, 46, 217, 0.125);
  }
  41.5% {
    box-shadow: 0 -38px rgba(140, 46, 217, 0.125), 0 -76px rgba(140, 46, 217, 0.125), -170px -114px rgba(140, 46, 217, 0.125), -340px -152px rgba(140, 46, 217, 0.125), -340px -190px rgba(140, 46, 217, 0.125), -340px -228px rgba(140, 46, 217, 0.125), -340px -266px rgba(140, 46, 217, 0.125), -340px -304px rgba(140, 46, 217, 0.125), -340px -342px rgba(140, 46, 217, 0.125), -340px -380px rgba(140, 46, 217, 0.125), -340px -418px rgba(140, 46, 217, 0.125), -340px -456px rgba(140, 46, 217, 0.125), -340px -494px rgba(140, 46, 217, 0.125), -340px -532px rgba(140, 46, 217, 0.125);
  }
  42.125% {
    box-shadow: 0 -38px rgba(140, 46, 217, 0.125), 0 -76px rgba(140, 46, 217, 0.125), 0 -114px rgba(140, 46, 217, 0.125), -170px -152px rgba(140, 46, 217, 0.125), -340px -190px rgba(140, 46, 217, 0.125), -340px -228px rgba(140, 46, 217, 0.125), -340px -266px rgba(140, 46, 217, 0.125), -340px -304px rgba(140, 46, 217, 0.125), -340px -342px rgba(140, 46, 217, 0.125), -340px -380px rgba(140, 46, 217, 0.125), -340px -418px rgba(140, 46, 217, 0.125), -340px -456px rgba(140, 46, 217, 0.125), -340px -494px rgba(140, 46, 217, 0.125), -340px -532px rgba(140, 46, 217, 0.125);
  }
  42.75% {
    box-shadow: 0 -38px rgba(140, 46, 217, 0.125), 0 -76px rgba(140, 46, 217, 0.125), 0 -114px rgba(140, 46, 217, 0.125), 0 -152px rgba(140, 46, 217, 0.125), -170px -190px rgba(140, 46, 217, 0.125), -340px -228px rgba(140, 46, 217, 0.125), -340px -266px rgba(140, 46, 217, 0.125), -340px -304px rgba(140, 46, 217, 0.125), -340px -342px rgba(140, 46, 217, 0.125), -340px -380px rgba(140, 46, 217, 0.125), -340px -418px rgba(140, 46, 217, 0.125), -340px -456px rgba(140, 46, 217, 0.125), -340px -494px rgba(140, 46, 217, 0.125), -340px -532px rgba(140, 46, 217, 0.125);
  }
  43.375% {
    box-shadow: 0 -38px rgba(140, 46, 217, 0.125), 0 -76px rgba(140, 46, 217, 0.125), 0 -114px rgba(140, 46, 217, 0.125), 0 -152px rgba(140, 46, 217, 0.125), 0 -190px rgba(140, 46, 217, 0.125), -170px -228px rgba(140, 46, 217, 0.125), -340px -266px rgba(140, 46, 217, 0.125), -340px -304px rgba(140, 46, 217, 0.125), -340px -342px rgba(140, 46, 217, 0.125), -340px -380px rgba(140, 46, 217, 0.125), -340px -418px rgba(140, 46, 217, 0.125), -340px -456px rgba(140, 46, 217, 0.125), -340px -494px rgba(140, 46, 217, 0.125), -340px -532px rgba(140, 46, 217, 0.125);
  }
  44% {
    box-shadow: 0 -38px rgba(140, 46, 217, 0.125), 0 -76px rgba(140, 46, 217, 0.125), 0 -114px rgba(140, 46, 217, 0.125), 0 -152px rgba(140, 46, 217, 0.125), 0 -190px rgba(140, 46, 217, 0.125), 0 -228px rgba(140, 46, 217, 0.125), -170px -266px rgba(140, 46, 217, 0.125), -340px -304px rgba(140, 46, 217, 0.125), -340px -342px rgba(140, 46, 217, 0.125), -340px -380px rgba(140, 46, 217, 0.125), -340px -418px rgba(140, 46, 217, 0.125), -340px -456px rgba(140, 46, 217, 0.125), -340px -494px rgba(140, 46, 217, 0.125), -340px -532px rgba(140, 46, 217, 0.125);
  }
  44.625% {
    box-shadow: 0 -38px rgba(140, 46, 217, 0.125), 0 -76px rgba(140, 46, 217, 0.125), 0 -114px rgba(140, 46, 217, 0.125), 0 -152px rgba(140, 46, 217, 0.125), 0 -190px rgba(140, 46, 217, 0.125), 0 -228px rgba(140, 46, 217, 0.125), 0 -266px rgba(140, 46, 217, 0.125), -170px -304px rgba(140, 46, 217, 0.125), -340px -342px rgba(140, 46, 217, 0.125), -340px -380px rgba(140, 46, 217, 0.125), -340px -418px rgba(140, 46, 217, 0.125), -340px -456px rgba(140, 46, 217, 0.125), -340px -494px rgba(140, 46, 217, 0.125), -340px -532px rgba(140, 46, 217, 0.125);
  }
  45.25% {
    box-shadow: 0 -38px rgba(140, 46, 217, 0.125), 0 -76px rgba(140, 46, 217, 0.125), 0 -114px rgba(140, 46, 217, 0.125), 0 -152px rgba(140, 46, 217, 0.125), 0 -190px rgba(140, 46, 217, 0.125), 0 -228px rgba(140, 46, 217, 0.125), 0 -266px rgba(140, 46, 217, 0.125), 0 -304px rgba(140, 46, 217, 0.125), -170px -342px rgba(140, 46, 217, 0.125), -340px -380px rgba(140, 46, 217, 0.125), -340px -418px rgba(140, 46, 217, 0.125), -340px -456px rgba(140, 46, 217, 0.125), -340px -494px rgba(140, 46, 217, 0.125), -340px -532px rgba(140, 46, 217, 0.125);
  }
  45.875% {
    box-shadow: 0 -38px rgba(140, 46, 217, 0.125), 0 -76px rgba(140, 46, 217, 0.125), 0 -114px rgba(140, 46, 217, 0.125), 0 -152px rgba(140, 46, 217, 0.125), 0 -190px rgba(140, 46, 217, 0.125), 0 -228px rgba(140, 46, 217, 0.125), 0 -266px rgba(140, 46, 217, 0.125), 0 -304px rgba(140, 46, 217, 0.125), 0 -342px rgba(140, 46, 217, 0.125), -170px -380px rgba(140, 46, 217, 0.125), -340px -418px rgba(140, 46, 217, 0.125), -340px -456px rgba(140, 46, 217, 0.125), -340px -494px rgba(140, 46, 217, 0.125), -340px -532px rgba(140, 46, 217, 0.125);
  }
  46.5% {
    box-shadow: 0 -38px rgba(140, 46, 217, 0.125), 0 -76px rgba(140, 46, 217, 0.125), 0 -114px rgba(140, 46, 217, 0.125), 0 -152px rgba(140, 46, 217, 0.125), 0 -190px rgba(140, 46, 217, 0.125), 0 -228px rgba(140, 46, 217, 0.125), 0 -266px rgba(140, 46, 217, 0.125), 0 -304px rgba(140, 46, 217, 0.125), 0 -342px rgba(140, 46, 217, 0.125), 0 -380px rgba(140, 46, 217, 0.125), -170px -418px rgba(140, 46, 217, 0.125), -340px -456px rgba(140, 46, 217, 0.125), -340px -494px rgba(140, 46, 217, 0.125), -340px -532px rgba(140, 46, 217, 0.125);
  }
  47.125% {
    box-shadow: 0 -38px rgba(140, 46, 217, 0.125), 0 -76px rgba(140, 46, 217, 0.125), 0 -114px rgba(140, 46, 217, 0.125), 0 -152px rgba(140, 46, 217, 0.125), 0 -190px rgba(140, 46, 217, 0.125), 0 -228px rgba(140, 46, 217, 0.125), 0 -266px rgba(140, 46, 217, 0.125), 0 -304px rgba(140, 46, 217, 0.125), 0 -342px rgba(140, 46, 217, 0.125), 0 -380px rgba(140, 46, 217, 0.125), 0 -418px rgba(140, 46, 217, 0.125), -170px -456px rgba(140, 46, 217, 0.125), -340px -494px rgba(140, 46, 217, 0.125), -340px -532px rgba(140, 46, 217, 0.125);
  }
  47.75% {
    box-shadow: 0 -38px rgba(140, 46, 217, 0.125), 0 -76px rgba(140, 46, 217, 0.125), 0 -114px rgba(140, 46, 217, 0.125), 0 -152px rgba(140, 46, 217, 0.125), 0 -190px rgba(140, 46, 217, 0.125), 0 -228px rgba(140, 46, 217, 0.125), 0 -266px rgba(140, 46, 217, 0.125), 0 -304px rgba(140, 46, 217, 0.125), 0 -342px rgba(140, 46, 217, 0.125), 0 -380px rgba(140, 46, 217, 0.125), 0 -418px rgba(140, 46, 217, 0.125), 0 -456px rgba(140, 46, 217, 0.125), -170px -494px rgba(140, 46, 217, 0.125), -340px -532px rgba(140, 46, 217, 0.125);
  }
  48.375% {
    box-shadow: 0 -38px rgba(140, 46, 217, 0.125), 0 -76px rgba(140, 46, 217, 0.125), 0 -114px rgba(140, 46, 217, 0.125), 0 -152px rgba(140, 46, 217, 0.125), 0 -190px rgba(140, 46, 217, 0.125), 0 -228px rgba(140, 46, 217, 0.125), 0 -266px rgba(140, 46, 217, 0.125), 0 -304px rgba(140, 46, 217, 0.125), 0 -342px rgba(140, 46, 217, 0.125), 0 -380px rgba(140, 46, 217, 0.125), 0 -418px rgba(140, 46, 217, 0.125), 0 -456px rgba(140, 46, 217, 0.125), 0 -494px rgba(140, 46, 217, 0.125), -170px -532px rgba(140, 46, 217, 0.125);
  }
  49% {
    box-shadow: 0 -38px rgba(140, 46, 217, 0.125), 0 -76px rgba(140, 46, 217, 0.125), 0 -114px rgba(140, 46, 217, 0.125), 0 -152px rgba(140, 46, 217, 0.125), 0 -190px rgba(140, 46, 217, 0.125), 0 -228px rgba(140, 46, 217, 0.125), 0 -266px rgba(140, 46, 217, 0.125), 0 -304px rgba(140, 46, 217, 0.125), 0 -342px rgba(140, 46, 217, 0.125), 0 -380px rgba(140, 46, 217, 0.125), 0 -418px rgba(140, 46, 217, 0.125), 0 -456px rgba(140, 46, 217, 0.125), 0 -494px rgba(140, 46, 217, 0.125), 0 -532px rgba(140, 46, 217, 0.125);
  }
}
@keyframes cover-horizontal-transparent {
  0% {
    width: 0;
    height: 0;
    box-shadow: none;
  }
  39% {
    width: 0;
    height: 38px;
  }
  39.625% {
    width: 170px;
    box-shadow: -340px -38px rgba(140, 46, 217, 0.125), -340px -76px rgba(140, 46, 217, 0.125), -340px -114px rgba(140, 46, 217, 0.125), -340px -152px rgba(140, 46, 217, 0.125), -340px -190px rgba(140, 46, 217, 0.125), -340px -228px rgba(140, 46, 217, 0.125), -340px -266px rgba(140, 46, 217, 0.125), -340px -304px rgba(140, 46, 217, 0.125), -340px -342px rgba(140, 46, 217, 0.125), -340px -380px rgba(140, 46, 217, 0.125), -340px -418px rgba(140, 46, 217, 0.125), -340px -456px rgba(140, 46, 217, 0.125), -340px -494px rgba(140, 46, 217, 0.125), -340px -532px rgba(140, 46, 217, 0.125);
  }
  40.25% {
    width: 340px;
    box-shadow: -170px -38px rgba(140, 46, 217, 0.125), -340px -76px rgba(140, 46, 217, 0.125), -340px -114px rgba(140, 46, 217, 0.125), -340px -152px rgba(140, 46, 217, 0.125), -340px -190px rgba(140, 46, 217, 0.125), -340px -228px rgba(140, 46, 217, 0.125), -340px -266px rgba(140, 46, 217, 0.125), -340px -304px rgba(140, 46, 217, 0.125), -340px -342px rgba(140, 46, 217, 0.125), -340px -380px rgba(140, 46, 217, 0.125), -340px -418px rgba(140, 46, 217, 0.125), -340px -456px rgba(140, 46, 217, 0.125), -340px -494px rgba(140, 46, 217, 0.125), -340px -532px rgba(140, 46, 217, 0.125);
  }
  40.875% {
    box-shadow: 0 -38px rgba(140, 46, 217, 0.125), -170px -76px rgba(140, 46, 217, 0.125), -340px -114px rgba(140, 46, 217, 0.125), -340px -152px rgba(140, 46, 217, 0.125), -340px -190px rgba(140, 46, 217, 0.125), -340px -228px rgba(140, 46, 217, 0.125), -340px -266px rgba(140, 46, 217, 0.125), -340px -304px rgba(140, 46, 217, 0.125), -340px -342px rgba(140, 46, 217, 0.125), -340px -380px rgba(140, 46, 217, 0.125), -340px -418px rgba(140, 46, 217, 0.125), -340px -456px rgba(140, 46, 217, 0.125), -340px -494px rgba(140, 46, 217, 0.125), -340px -532px rgba(140, 46, 217, 0.125);
  }
  41.5% {
    box-shadow: 0 -38px rgba(140, 46, 217, 0.125), 0 -76px rgba(140, 46, 217, 0.125), -170px -114px rgba(140, 46, 217, 0.125), -340px -152px rgba(140, 46, 217, 0.125), -340px -190px rgba(140, 46, 217, 0.125), -340px -228px rgba(140, 46, 217, 0.125), -340px -266px rgba(140, 46, 217, 0.125), -340px -304px rgba(140, 46, 217, 0.125), -340px -342px rgba(140, 46, 217, 0.125), -340px -380px rgba(140, 46, 217, 0.125), -340px -418px rgba(140, 46, 217, 0.125), -340px -456px rgba(140, 46, 217, 0.125), -340px -494px rgba(140, 46, 217, 0.125), -340px -532px rgba(140, 46, 217, 0.125);
  }
  42.125% {
    box-shadow: 0 -38px rgba(140, 46, 217, 0.125), 0 -76px rgba(140, 46, 217, 0.125), 0 -114px rgba(140, 46, 217, 0.125), -170px -152px rgba(140, 46, 217, 0.125), -340px -190px rgba(140, 46, 217, 0.125), -340px -228px rgba(140, 46, 217, 0.125), -340px -266px rgba(140, 46, 217, 0.125), -340px -304px rgba(140, 46, 217, 0.125), -340px -342px rgba(140, 46, 217, 0.125), -340px -380px rgba(140, 46, 217, 0.125), -340px -418px rgba(140, 46, 217, 0.125), -340px -456px rgba(140, 46, 217, 0.125), -340px -494px rgba(140, 46, 217, 0.125), -340px -532px rgba(140, 46, 217, 0.125);
  }
  42.75% {
    box-shadow: 0 -38px rgba(140, 46, 217, 0.125), 0 -76px rgba(140, 46, 217, 0.125), 0 -114px rgba(140, 46, 217, 0.125), 0 -152px rgba(140, 46, 217, 0.125), -170px -190px rgba(140, 46, 217, 0.125), -340px -228px rgba(140, 46, 217, 0.125), -340px -266px rgba(140, 46, 217, 0.125), -340px -304px rgba(140, 46, 217, 0.125), -340px -342px rgba(140, 46, 217, 0.125), -340px -380px rgba(140, 46, 217, 0.125), -340px -418px rgba(140, 46, 217, 0.125), -340px -456px rgba(140, 46, 217, 0.125), -340px -494px rgba(140, 46, 217, 0.125), -340px -532px rgba(140, 46, 217, 0.125);
  }
  43.375% {
    box-shadow: 0 -38px rgba(140, 46, 217, 0.125), 0 -76px rgba(140, 46, 217, 0.125), 0 -114px rgba(140, 46, 217, 0.125), 0 -152px rgba(140, 46, 217, 0.125), 0 -190px rgba(140, 46, 217, 0.125), -170px -228px rgba(140, 46, 217, 0.125), -340px -266px rgba(140, 46, 217, 0.125), -340px -304px rgba(140, 46, 217, 0.125), -340px -342px rgba(140, 46, 217, 0.125), -340px -380px rgba(140, 46, 217, 0.125), -340px -418px rgba(140, 46, 217, 0.125), -340px -456px rgba(140, 46, 217, 0.125), -340px -494px rgba(140, 46, 217, 0.125), -340px -532px rgba(140, 46, 217, 0.125);
  }
  44% {
    box-shadow: 0 -38px rgba(140, 46, 217, 0.125), 0 -76px rgba(140, 46, 217, 0.125), 0 -114px rgba(140, 46, 217, 0.125), 0 -152px rgba(140, 46, 217, 0.125), 0 -190px rgba(140, 46, 217, 0.125), 0 -228px rgba(140, 46, 217, 0.125), -170px -266px rgba(140, 46, 217, 0.125), -340px -304px rgba(140, 46, 217, 0.125), -340px -342px rgba(140, 46, 217, 0.125), -340px -380px rgba(140, 46, 217, 0.125), -340px -418px rgba(140, 46, 217, 0.125), -340px -456px rgba(140, 46, 217, 0.125), -340px -494px rgba(140, 46, 217, 0.125), -340px -532px rgba(140, 46, 217, 0.125);
  }
  44.625% {
    box-shadow: 0 -38px rgba(140, 46, 217, 0.125), 0 -76px rgba(140, 46, 217, 0.125), 0 -114px rgba(140, 46, 217, 0.125), 0 -152px rgba(140, 46, 217, 0.125), 0 -190px rgba(140, 46, 217, 0.125), 0 -228px rgba(140, 46, 217, 0.125), 0 -266px rgba(140, 46, 217, 0.125), -170px -304px rgba(140, 46, 217, 0.125), -340px -342px rgba(140, 46, 217, 0.125), -340px -380px rgba(140, 46, 217, 0.125), -340px -418px rgba(140, 46, 217, 0.125), -340px -456px rgba(140, 46, 217, 0.125), -340px -494px rgba(140, 46, 217, 0.125), -340px -532px rgba(140, 46, 217, 0.125);
  }
  45.25% {
    box-shadow: 0 -38px rgba(140, 46, 217, 0.125), 0 -76px rgba(140, 46, 217, 0.125), 0 -114px rgba(140, 46, 217, 0.125), 0 -152px rgba(140, 46, 217, 0.125), 0 -190px rgba(140, 46, 217, 0.125), 0 -228px rgba(140, 46, 217, 0.125), 0 -266px rgba(140, 46, 217, 0.125), 0 -304px rgba(140, 46, 217, 0.125), -170px -342px rgba(140, 46, 217, 0.125), -340px -380px rgba(140, 46, 217, 0.125), -340px -418px rgba(140, 46, 217, 0.125), -340px -456px rgba(140, 46, 217, 0.125), -340px -494px rgba(140, 46, 217, 0.125), -340px -532px rgba(140, 46, 217, 0.125);
  }
  45.875% {
    box-shadow: 0 -38px rgba(140, 46, 217, 0.125), 0 -76px rgba(140, 46, 217, 0.125), 0 -114px rgba(140, 46, 217, 0.125), 0 -152px rgba(140, 46, 217, 0.125), 0 -190px rgba(140, 46, 217, 0.125), 0 -228px rgba(140, 46, 217, 0.125), 0 -266px rgba(140, 46, 217, 0.125), 0 -304px rgba(140, 46, 217, 0.125), 0 -342px rgba(140, 46, 217, 0.125), -170px -380px rgba(140, 46, 217, 0.125), -340px -418px rgba(140, 46, 217, 0.125), -340px -456px rgba(140, 46, 217, 0.125), -340px -494px rgba(140, 46, 217, 0.125), -340px -532px rgba(140, 46, 217, 0.125);
  }
  46.5% {
    box-shadow: 0 -38px rgba(140, 46, 217, 0.125), 0 -76px rgba(140, 46, 217, 0.125), 0 -114px rgba(140, 46, 217, 0.125), 0 -152px rgba(140, 46, 217, 0.125), 0 -190px rgba(140, 46, 217, 0.125), 0 -228px rgba(140, 46, 217, 0.125), 0 -266px rgba(140, 46, 217, 0.125), 0 -304px rgba(140, 46, 217, 0.125), 0 -342px rgba(140, 46, 217, 0.125), 0 -380px rgba(140, 46, 217, 0.125), -170px -418px rgba(140, 46, 217, 0.125), -340px -456px rgba(140, 46, 217, 0.125), -340px -494px rgba(140, 46, 217, 0.125), -340px -532px rgba(140, 46, 217, 0.125);
  }
  47.125% {
    box-shadow: 0 -38px rgba(140, 46, 217, 0.125), 0 -76px rgba(140, 46, 217, 0.125), 0 -114px rgba(140, 46, 217, 0.125), 0 -152px rgba(140, 46, 217, 0.125), 0 -190px rgba(140, 46, 217, 0.125), 0 -228px rgba(140, 46, 217, 0.125), 0 -266px rgba(140, 46, 217, 0.125), 0 -304px rgba(140, 46, 217, 0.125), 0 -342px rgba(140, 46, 217, 0.125), 0 -380px rgba(140, 46, 217, 0.125), 0 -418px rgba(140, 46, 217, 0.125), -170px -456px rgba(140, 46, 217, 0.125), -340px -494px rgba(140, 46, 217, 0.125), -340px -532px rgba(140, 46, 217, 0.125);
  }
  47.75% {
    box-shadow: 0 -38px rgba(140, 46, 217, 0.125), 0 -76px rgba(140, 46, 217, 0.125), 0 -114px rgba(140, 46, 217, 0.125), 0 -152px rgba(140, 46, 217, 0.125), 0 -190px rgba(140, 46, 217, 0.125), 0 -228px rgba(140, 46, 217, 0.125), 0 -266px rgba(140, 46, 217, 0.125), 0 -304px rgba(140, 46, 217, 0.125), 0 -342px rgba(140, 46, 217, 0.125), 0 -380px rgba(140, 46, 217, 0.125), 0 -418px rgba(140, 46, 217, 0.125), 0 -456px rgba(140, 46, 217, 0.125), -170px -494px rgba(140, 46, 217, 0.125), -340px -532px rgba(140, 46, 217, 0.125);
  }
  48.375% {
    box-shadow: 0 -38px rgba(140, 46, 217, 0.125), 0 -76px rgba(140, 46, 217, 0.125), 0 -114px rgba(140, 46, 217, 0.125), 0 -152px rgba(140, 46, 217, 0.125), 0 -190px rgba(140, 46, 217, 0.125), 0 -228px rgba(140, 46, 217, 0.125), 0 -266px rgba(140, 46, 217, 0.125), 0 -304px rgba(140, 46, 217, 0.125), 0 -342px rgba(140, 46, 217, 0.125), 0 -380px rgba(140, 46, 217, 0.125), 0 -418px rgba(140, 46, 217, 0.125), 0 -456px rgba(140, 46, 217, 0.125), 0 -494px rgba(140, 46, 217, 0.125), -170px -532px rgba(140, 46, 217, 0.125);
  }
  49% {
    box-shadow: 0 -38px rgba(140, 46, 217, 0.125), 0 -76px rgba(140, 46, 217, 0.125), 0 -114px rgba(140, 46, 217, 0.125), 0 -152px rgba(140, 46, 217, 0.125), 0 -190px rgba(140, 46, 217, 0.125), 0 -228px rgba(140, 46, 217, 0.125), 0 -266px rgba(140, 46, 217, 0.125), 0 -304px rgba(140, 46, 217, 0.125), 0 -342px rgba(140, 46, 217, 0.125), 0 -380px rgba(140, 46, 217, 0.125), 0 -418px rgba(140, 46, 217, 0.125), 0 -456px rgba(140, 46, 217, 0.125), 0 -494px rgba(140, 46, 217, 0.125), 0 -532px rgba(140, 46, 217, 0.125);
  }
}
#gameboy.transparent #cover-horizontal {
  background-color: rgba(140, 46, 217, 0.125);
  -webkit-animation: cover-horizontal-transparent 8s   linear;
  animation: cover-horizontal-transparent 8s   linear;
  box-shadow: 0 -38px rgba(140, 46, 217, 0.125), 0 -76px rgba(140, 46, 217, 0.125), 0 -114px rgba(140, 46, 217, 0.125), 0 -152px rgba(140, 46, 217, 0.125), 0 -190px rgba(140, 46, 217, 0.125), 0 -228px rgba(140, 46, 217, 0.125), 0 -266px rgba(140, 46, 217, 0.125), 0 -304px rgba(140, 46, 217, 0.125), 0 -342px rgba(140, 46, 217, 0.125), 0 -380px rgba(140, 46, 217, 0.125), 0 -418px rgba(140, 46, 217, 0.125), 0 -456px rgba(140, 46, 217, 0.125), 0 -494px rgba(140, 46, 217, 0.125), 0 -532px rgba(140, 46, 217, 0.125);
}
@-moz-keyframes cover-horizontal-teal {
  0% {
    width: 0;
    height: 0;
    box-shadow: none;
  }
  39% {
    width: 0;
    height: 38px;
  }
  39.625% {
    width: 170px;
    box-shadow: -340px -38px #01b4dd, -340px -76px #01b4dd, -340px -114px #01b4dd, -340px -152px #01b4dd, -340px -190px #01b4dd, -340px -228px #01b4dd, -340px -266px #01b4dd, -340px -304px #01b4dd, -340px -342px #01b4dd, -340px -380px #01b4dd, -340px -418px #01b4dd, -340px -456px #01b4dd, -340px -494px #01b4dd, -340px -532px #01b4dd;
  }
  40.25% {
    width: 340px;
    box-shadow: -170px -38px #01b4dd, -340px -76px #01b4dd, -340px -114px #01b4dd, -340px -152px #01b4dd, -340px -190px #01b4dd, -340px -228px #01b4dd, -340px -266px #01b4dd, -340px -304px #01b4dd, -340px -342px #01b4dd, -340px -380px #01b4dd, -340px -418px #01b4dd, -340px -456px #01b4dd, -340px -494px #01b4dd, -340px -532px #01b4dd;
  }
  40.875% {
    box-shadow: 0 -38px #01b4dd, -170px -76px #01b4dd, -340px -114px #01b4dd, -340px -152px #01b4dd, -340px -190px #01b4dd, -340px -228px #01b4dd, -340px -266px #01b4dd, -340px -304px #01b4dd, -340px -342px #01b4dd, -340px -380px #01b4dd, -340px -418px #01b4dd, -340px -456px #01b4dd, -340px -494px #01b4dd, -340px -532px #01b4dd;
  }
  41.5% {
    box-shadow: 0 -38px #01b4dd, 0 -76px #01b4dd, -170px -114px #01b4dd, -340px -152px #01b4dd, -340px -190px #01b4dd, -340px -228px #01b4dd, -340px -266px #01b4dd, -340px -304px #01b4dd, -340px -342px #01b4dd, -340px -380px #01b4dd, -340px -418px #01b4dd, -340px -456px #01b4dd, -340px -494px #01b4dd, -340px -532px #01b4dd;
  }
  42.125% {
    box-shadow: 0 -38px #01b4dd, 0 -76px #01b4dd, 0 -114px #01b4dd, -170px -152px #01b4dd, -340px -190px #01b4dd, -340px -228px #01b4dd, -340px -266px #01b4dd, -340px -304px #01b4dd, -340px -342px #01b4dd, -340px -380px #01b4dd, -340px -418px #01b4dd, -340px -456px #01b4dd, -340px -494px #01b4dd, -340px -532px #01b4dd;
  }
  42.75% {
    box-shadow: 0 -38px #01b4dd, 0 -76px #01b4dd, 0 -114px #01b4dd, 0 -152px #01b4dd, -170px -190px #01b4dd, -340px -228px #01b4dd, -340px -266px #01b4dd, -340px -304px #01b4dd, -340px -342px #01b4dd, -340px -380px #01b4dd, -340px -418px #01b4dd, -340px -456px #01b4dd, -340px -494px #01b4dd, -340px -532px #01b4dd;
  }
  43.375% {
    box-shadow: 0 -38px #01b4dd, 0 -76px #01b4dd, 0 -114px #01b4dd, 0 -152px #01b4dd, 0 -190px #01b4dd, -170px -228px #01b4dd, -340px -266px #01b4dd, -340px -304px #01b4dd, -340px -342px #01b4dd, -340px -380px #01b4dd, -340px -418px #01b4dd, -340px -456px #01b4dd, -340px -494px #01b4dd, -340px -532px #01b4dd;
  }
  44% {
    box-shadow: 0 -38px #01b4dd, 0 -76px #01b4dd, 0 -114px #01b4dd, 0 -152px #01b4dd, 0 -190px #01b4dd, 0 -228px #01b4dd, -170px -266px #01b4dd, -340px -304px #01b4dd, -340px -342px #01b4dd, -340px -380px #01b4dd, -340px -418px #01b4dd, -340px -456px #01b4dd, -340px -494px #01b4dd, -340px -532px #01b4dd;
  }
  44.625% {
    box-shadow: 0 -38px #01b4dd, 0 -76px #01b4dd, 0 -114px #01b4dd, 0 -152px #01b4dd, 0 -190px #01b4dd, 0 -228px #01b4dd, 0 -266px #01b4dd, -170px -304px #01b4dd, -340px -342px #01b4dd, -340px -380px #01b4dd, -340px -418px #01b4dd, -340px -456px #01b4dd, -340px -494px #01b4dd, -340px -532px #01b4dd;
  }
  45.25% {
    box-shadow: 0 -38px #01b4dd, 0 -76px #01b4dd, 0 -114px #01b4dd, 0 -152px #01b4dd, 0 -190px #01b4dd, 0 -228px #01b4dd, 0 -266px #01b4dd, 0 -304px #01b4dd, -170px -342px #01b4dd, -340px -380px #01b4dd, -340px -418px #01b4dd, -340px -456px #01b4dd, -340px -494px #01b4dd, -340px -532px #01b4dd;
  }
  45.875% {
    box-shadow: 0 -38px #01b4dd, 0 -76px #01b4dd, 0 -114px #01b4dd, 0 -152px #01b4dd, 0 -190px #01b4dd, 0 -228px #01b4dd, 0 -266px #01b4dd, 0 -304px #01b4dd, 0 -342px #01b4dd, -170px -380px #01b4dd, -340px -418px #01b4dd, -340px -456px #01b4dd, -340px -494px #01b4dd, -340px -532px #01b4dd;
  }
  46.5% {
    box-shadow: 0 -38px #01b4dd, 0 -76px #01b4dd, 0 -114px #01b4dd, 0 -152px #01b4dd, 0 -190px #01b4dd, 0 -228px #01b4dd, 0 -266px #01b4dd, 0 -304px #01b4dd, 0 -342px #01b4dd, 0 -380px #01b4dd, -170px -418px #01b4dd, -340px -456px #01b4dd, -340px -494px #01b4dd, -340px -532px #01b4dd;
  }
  47.125% {
    box-shadow: 0 -38px #01b4dd, 0 -76px #01b4dd, 0 -114px #01b4dd, 0 -152px #01b4dd, 0 -190px #01b4dd, 0 -228px #01b4dd, 0 -266px #01b4dd, 0 -304px #01b4dd, 0 -342px #01b4dd, 0 -380px #01b4dd, 0 -418px #01b4dd, -170px -456px #01b4dd, -340px -494px #01b4dd, -340px -532px #01b4dd;
  }
  47.75% {
    box-shadow: 0 -38px #01b4dd, 0 -76px #01b4dd, 0 -114px #01b4dd, 0 -152px #01b4dd, 0 -190px #01b4dd, 0 -228px #01b4dd, 0 -266px #01b4dd, 0 -304px #01b4dd, 0 -342px #01b4dd, 0 -380px #01b4dd, 0 -418px #01b4dd, 0 -456px #01b4dd, -170px -494px #01b4dd, -340px -532px #01b4dd;
  }
  48.375% {
    box-shadow: 0 -38px #01b4dd, 0 -76px #01b4dd, 0 -114px #01b4dd, 0 -152px #01b4dd, 0 -190px #01b4dd, 0 -228px #01b4dd, 0 -266px #01b4dd, 0 -304px #01b4dd, 0 -342px #01b4dd, 0 -380px #01b4dd, 0 -418px #01b4dd, 0 -456px #01b4dd, 0 -494px #01b4dd, -170px -532px #01b4dd;
  }
  49% {
    box-shadow: 0 -38px #01b4dd, 0 -76px #01b4dd, 0 -114px #01b4dd, 0 -152px #01b4dd, 0 -190px #01b4dd, 0 -228px #01b4dd, 0 -266px #01b4dd, 0 -304px #01b4dd, 0 -342px #01b4dd, 0 -380px #01b4dd, 0 -418px #01b4dd, 0 -456px #01b4dd, 0 -494px #01b4dd, 0 -532px #01b4dd;
  }
}
@-webkit-keyframes cover-horizontal-teal {
  0% {
    width: 0;
    height: 0;
    box-shadow: none;
  }
  39% {
    width: 0;
    height: 38px;
  }
  39.625% {
    width: 170px;
    box-shadow: -340px -38px #01b4dd, -340px -76px #01b4dd, -340px -114px #01b4dd, -340px -152px #01b4dd, -340px -190px #01b4dd, -340px -228px #01b4dd, -340px -266px #01b4dd, -340px -304px #01b4dd, -340px -342px #01b4dd, -340px -380px #01b4dd, -340px -418px #01b4dd, -340px -456px #01b4dd, -340px -494px #01b4dd, -340px -532px #01b4dd;
  }
  40.25% {
    width: 340px;
    box-shadow: -170px -38px #01b4dd, -340px -76px #01b4dd, -340px -114px #01b4dd, -340px -152px #01b4dd, -340px -190px #01b4dd, -340px -228px #01b4dd, -340px -266px #01b4dd, -340px -304px #01b4dd, -340px -342px #01b4dd, -340px -380px #01b4dd, -340px -418px #01b4dd, -340px -456px #01b4dd, -340px -494px #01b4dd, -340px -532px #01b4dd;
  }
  40.875% {
    box-shadow: 0 -38px #01b4dd, -170px -76px #01b4dd, -340px -114px #01b4dd, -340px -152px #01b4dd, -340px -190px #01b4dd, -340px -228px #01b4dd, -340px -266px #01b4dd, -340px -304px #01b4dd, -340px -342px #01b4dd, -340px -380px #01b4dd, -340px -418px #01b4dd, -340px -456px #01b4dd, -340px -494px #01b4dd, -340px -532px #01b4dd;
  }
  41.5% {
    box-shadow: 0 -38px #01b4dd, 0 -76px #01b4dd, -170px -114px #01b4dd, -340px -152px #01b4dd, -340px -190px #01b4dd, -340px -228px #01b4dd, -340px -266px #01b4dd, -340px -304px #01b4dd, -340px -342px #01b4dd, -340px -380px #01b4dd, -340px -418px #01b4dd, -340px -456px #01b4dd, -340px -494px #01b4dd, -340px -532px #01b4dd;
  }
  42.125% {
    box-shadow: 0 -38px #01b4dd, 0 -76px #01b4dd, 0 -114px #01b4dd, -170px -152px #01b4dd, -340px -190px #01b4dd, -340px -228px #01b4dd, -340px -266px #01b4dd, -340px -304px #01b4dd, -340px -342px #01b4dd, -340px -380px #01b4dd, -340px -418px #01b4dd, -340px -456px #01b4dd, -340px -494px #01b4dd, -340px -532px #01b4dd;
  }
  42.75% {
    box-shadow: 0 -38px #01b4dd, 0 -76px #01b4dd, 0 -114px #01b4dd, 0 -152px #01b4dd, -170px -190px #01b4dd, -340px -228px #01b4dd, -340px -266px #01b4dd, -340px -304px #01b4dd, -340px -342px #01b4dd, -340px -380px #01b4dd, -340px -418px #01b4dd, -340px -456px #01b4dd, -340px -494px #01b4dd, -340px -532px #01b4dd;
  }
  43.375% {
    box-shadow: 0 -38px #01b4dd, 0 -76px #01b4dd, 0 -114px #01b4dd, 0 -152px #01b4dd, 0 -190px #01b4dd, -170px -228px #01b4dd, -340px -266px #01b4dd, -340px -304px #01b4dd, -340px -342px #01b4dd, -340px -380px #01b4dd, -340px -418px #01b4dd, -340px -456px #01b4dd, -340px -494px #01b4dd, -340px -532px #01b4dd;
  }
  44% {
    box-shadow: 0 -38px #01b4dd, 0 -76px #01b4dd, 0 -114px #01b4dd, 0 -152px #01b4dd, 0 -190px #01b4dd, 0 -228px #01b4dd, -170px -266px #01b4dd, -340px -304px #01b4dd, -340px -342px #01b4dd, -340px -380px #01b4dd, -340px -418px #01b4dd, -340px -456px #01b4dd, -340px -494px #01b4dd, -340px -532px #01b4dd;
  }
  44.625% {
    box-shadow: 0 -38px #01b4dd, 0 -76px #01b4dd, 0 -114px #01b4dd, 0 -152px #01b4dd, 0 -190px #01b4dd, 0 -228px #01b4dd, 0 -266px #01b4dd, -170px -304px #01b4dd, -340px -342px #01b4dd, -340px -380px #01b4dd, -340px -418px #01b4dd, -340px -456px #01b4dd, -340px -494px #01b4dd, -340px -532px #01b4dd;
  }
  45.25% {
    box-shadow: 0 -38px #01b4dd, 0 -76px #01b4dd, 0 -114px #01b4dd, 0 -152px #01b4dd, 0 -190px #01b4dd, 0 -228px #01b4dd, 0 -266px #01b4dd, 0 -304px #01b4dd, -170px -342px #01b4dd, -340px -380px #01b4dd, -340px -418px #01b4dd, -340px -456px #01b4dd, -340px -494px #01b4dd, -340px -532px #01b4dd;
  }
  45.875% {
    box-shadow: 0 -38px #01b4dd, 0 -76px #01b4dd, 0 -114px #01b4dd, 0 -152px #01b4dd, 0 -190px #01b4dd, 0 -228px #01b4dd, 0 -266px #01b4dd, 0 -304px #01b4dd, 0 -342px #01b4dd, -170px -380px #01b4dd, -340px -418px #01b4dd, -340px -456px #01b4dd, -340px -494px #01b4dd, -340px -532px #01b4dd;
  }
  46.5% {
    box-shadow: 0 -38px #01b4dd, 0 -76px #01b4dd, 0 -114px #01b4dd, 0 -152px #01b4dd, 0 -190px #01b4dd, 0 -228px #01b4dd, 0 -266px #01b4dd, 0 -304px #01b4dd, 0 -342px #01b4dd, 0 -380px #01b4dd, -170px -418px #01b4dd, -340px -456px #01b4dd, -340px -494px #01b4dd, -340px -532px #01b4dd;
  }
  47.125% {
    box-shadow: 0 -38px #01b4dd, 0 -76px #01b4dd, 0 -114px #01b4dd, 0 -152px #01b4dd, 0 -190px #01b4dd, 0 -228px #01b4dd, 0 -266px #01b4dd, 0 -304px #01b4dd, 0 -342px #01b4dd, 0 -380px #01b4dd, 0 -418px #01b4dd, -170px -456px #01b4dd, -340px -494px #01b4dd, -340px -532px #01b4dd;
  }
  47.75% {
    box-shadow: 0 -38px #01b4dd, 0 -76px #01b4dd, 0 -114px #01b4dd, 0 -152px #01b4dd, 0 -190px #01b4dd, 0 -228px #01b4dd, 0 -266px #01b4dd, 0 -304px #01b4dd, 0 -342px #01b4dd, 0 -380px #01b4dd, 0 -418px #01b4dd, 0 -456px #01b4dd, -170px -494px #01b4dd, -340px -532px #01b4dd;
  }
  48.375% {
    box-shadow: 0 -38px #01b4dd, 0 -76px #01b4dd, 0 -114px #01b4dd, 0 -152px #01b4dd, 0 -190px #01b4dd, 0 -228px #01b4dd, 0 -266px #01b4dd, 0 -304px #01b4dd, 0 -342px #01b4dd, 0 -380px #01b4dd, 0 -418px #01b4dd, 0 -456px #01b4dd, 0 -494px #01b4dd, -170px -532px #01b4dd;
  }
  49% {
    box-shadow: 0 -38px #01b4dd, 0 -76px #01b4dd, 0 -114px #01b4dd, 0 -152px #01b4dd, 0 -190px #01b4dd, 0 -228px #01b4dd, 0 -266px #01b4dd, 0 -304px #01b4dd, 0 -342px #01b4dd, 0 -380px #01b4dd, 0 -418px #01b4dd, 0 -456px #01b4dd, 0 -494px #01b4dd, 0 -532px #01b4dd;
  }
}
@keyframes cover-horizontal-teal {
  0% {
    width: 0;
    height: 0;
    box-shadow: none;
  }
  39% {
    width: 0;
    height: 38px;
  }
  39.625% {
    width: 170px;
    box-shadow: -340px -38px #01b4dd, -340px -76px #01b4dd, -340px -114px #01b4dd, -340px -152px #01b4dd, -340px -190px #01b4dd, -340px -228px #01b4dd, -340px -266px #01b4dd, -340px -304px #01b4dd, -340px -342px #01b4dd, -340px -380px #01b4dd, -340px -418px #01b4dd, -340px -456px #01b4dd, -340px -494px #01b4dd, -340px -532px #01b4dd;
  }
  40.25% {
    width: 340px;
    box-shadow: -170px -38px #01b4dd, -340px -76px #01b4dd, -340px -114px #01b4dd, -340px -152px #01b4dd, -340px -190px #01b4dd, -340px -228px #01b4dd, -340px -266px #01b4dd, -340px -304px #01b4dd, -340px -342px #01b4dd, -340px -380px #01b4dd, -340px -418px #01b4dd, -340px -456px #01b4dd, -340px -494px #01b4dd, -340px -532px #01b4dd;
  }
  40.875% {
    box-shadow: 0 -38px #01b4dd, -170px -76px #01b4dd, -340px -114px #01b4dd, -340px -152px #01b4dd, -340px -190px #01b4dd, -340px -228px #01b4dd, -340px -266px #01b4dd, -340px -304px #01b4dd, -340px -342px #01b4dd, -340px -380px #01b4dd, -340px -418px #01b4dd, -340px -456px #01b4dd, -340px -494px #01b4dd, -340px -532px #01b4dd;
  }
  41.5% {
    box-shadow: 0 -38px #01b4dd, 0 -76px #01b4dd, -170px -114px #01b4dd, -340px -152px #01b4dd, -340px -190px #01b4dd, -340px -228px #01b4dd, -340px -266px #01b4dd, -340px -304px #01b4dd, -340px -342px #01b4dd, -340px -380px #01b4dd, -340px -418px #01b4dd, -340px -456px #01b4dd, -340px -494px #01b4dd, -340px -532px #01b4dd;
  }
  42.125% {
    box-shadow: 0 -38px #01b4dd, 0 -76px #01b4dd, 0 -114px #01b4dd, -170px -152px #01b4dd, -340px -190px #01b4dd, -340px -228px #01b4dd, -340px -266px #01b4dd, -340px -304px #01b4dd, -340px -342px #01b4dd, -340px -380px #01b4dd, -340px -418px #01b4dd, -340px -456px #01b4dd, -340px -494px #01b4dd, -340px -532px #01b4dd;
  }
  42.75% {
    box-shadow: 0 -38px #01b4dd, 0 -76px #01b4dd, 0 -114px #01b4dd, 0 -152px #01b4dd, -170px -190px #01b4dd, -340px -228px #01b4dd, -340px -266px #01b4dd, -340px -304px #01b4dd, -340px -342px #01b4dd, -340px -380px #01b4dd, -340px -418px #01b4dd, -340px -456px #01b4dd, -340px -494px #01b4dd, -340px -532px #01b4dd;
  }
  43.375% {
    box-shadow: 0 -38px #01b4dd, 0 -76px #01b4dd, 0 -114px #01b4dd, 0 -152px #01b4dd, 0 -190px #01b4dd, -170px -228px #01b4dd, -340px -266px #01b4dd, -340px -304px #01b4dd, -340px -342px #01b4dd, -340px -380px #01b4dd, -340px -418px #01b4dd, -340px -456px #01b4dd, -340px -494px #01b4dd, -340px -532px #01b4dd;
  }
  44% {
    box-shadow: 0 -38px #01b4dd, 0 -76px #01b4dd, 0 -114px #01b4dd, 0 -152px #01b4dd, 0 -190px #01b4dd, 0 -228px #01b4dd, -170px -266px #01b4dd, -340px -304px #01b4dd, -340px -342px #01b4dd, -340px -380px #01b4dd, -340px -418px #01b4dd, -340px -456px #01b4dd, -340px -494px #01b4dd, -340px -532px #01b4dd;
  }
  44.625% {
    box-shadow: 0 -38px #01b4dd, 0 -76px #01b4dd, 0 -114px #01b4dd, 0 -152px #01b4dd, 0 -190px #01b4dd, 0 -228px #01b4dd, 0 -266px #01b4dd, -170px -304px #01b4dd, -340px -342px #01b4dd, -340px -380px #01b4dd, -340px -418px #01b4dd, -340px -456px #01b4dd, -340px -494px #01b4dd, -340px -532px #01b4dd;
  }
  45.25% {
    box-shadow: 0 -38px #01b4dd, 0 -76px #01b4dd, 0 -114px #01b4dd, 0 -152px #01b4dd, 0 -190px #01b4dd, 0 -228px #01b4dd, 0 -266px #01b4dd, 0 -304px #01b4dd, -170px -342px #01b4dd, -340px -380px #01b4dd, -340px -418px #01b4dd, -340px -456px #01b4dd, -340px -494px #01b4dd, -340px -532px #01b4dd;
  }
  45.875% {
    box-shadow: 0 -38px #01b4dd, 0 -76px #01b4dd, 0 -114px #01b4dd, 0 -152px #01b4dd, 0 -190px #01b4dd, 0 -228px #01b4dd, 0 -266px #01b4dd, 0 -304px #01b4dd, 0 -342px #01b4dd, -170px -380px #01b4dd, -340px -418px #01b4dd, -340px -456px #01b4dd, -340px -494px #01b4dd, -340px -532px #01b4dd;
  }
  46.5% {
    box-shadow: 0 -38px #01b4dd, 0 -76px #01b4dd, 0 -114px #01b4dd, 0 -152px #01b4dd, 0 -190px #01b4dd, 0 -228px #01b4dd, 0 -266px #01b4dd, 0 -304px #01b4dd, 0 -342px #01b4dd, 0 -380px #01b4dd, -170px -418px #01b4dd, -340px -456px #01b4dd, -340px -494px #01b4dd, -340px -532px #01b4dd;
  }
  47.125% {
    box-shadow: 0 -38px #01b4dd, 0 -76px #01b4dd, 0 -114px #01b4dd, 0 -152px #01b4dd, 0 -190px #01b4dd, 0 -228px #01b4dd, 0 -266px #01b4dd, 0 -304px #01b4dd, 0 -342px #01b4dd, 0 -380px #01b4dd, 0 -418px #01b4dd, -170px -456px #01b4dd, -340px -494px #01b4dd, -340px -532px #01b4dd;
  }
  47.75% {
    box-shadow: 0 -38px #01b4dd, 0 -76px #01b4dd, 0 -114px #01b4dd, 0 -152px #01b4dd, 0 -190px #01b4dd, 0 -228px #01b4dd, 0 -266px #01b4dd, 0 -304px #01b4dd, 0 -342px #01b4dd, 0 -380px #01b4dd, 0 -418px #01b4dd, 0 -456px #01b4dd, -170px -494px #01b4dd, -340px -532px #01b4dd;
  }
  48.375% {
    box-shadow: 0 -38px #01b4dd, 0 -76px #01b4dd, 0 -114px #01b4dd, 0 -152px #01b4dd, 0 -190px #01b4dd, 0 -228px #01b4dd, 0 -266px #01b4dd, 0 -304px #01b4dd, 0 -342px #01b4dd, 0 -380px #01b4dd, 0 -418px #01b4dd, 0 -456px #01b4dd, 0 -494px #01b4dd, -170px -532px #01b4dd;
  }
  49% {
    box-shadow: 0 -38px #01b4dd, 0 -76px #01b4dd, 0 -114px #01b4dd, 0 -152px #01b4dd, 0 -190px #01b4dd, 0 -228px #01b4dd, 0 -266px #01b4dd, 0 -304px #01b4dd, 0 -342px #01b4dd, 0 -380px #01b4dd, 0 -418px #01b4dd, 0 -456px #01b4dd, 0 -494px #01b4dd, 0 -532px #01b4dd;
  }
}
#gameboy.teal #cover-horizontal {
  background-color: #01b4dd;
  -webkit-animation: cover-horizontal-teal 8s   linear;
  animation: cover-horizontal-teal 8s   linear;
  box-shadow: 0 -38px #01b4dd, 0 -76px #01b4dd, 0 -114px #01b4dd, 0 -152px #01b4dd, 0 -190px #01b4dd, 0 -228px #01b4dd, 0 -266px #01b4dd, 0 -304px #01b4dd, 0 -342px #01b4dd, 0 -380px #01b4dd, 0 -418px #01b4dd, 0 -456px #01b4dd, 0 -494px #01b4dd, 0 -532px #01b4dd;
}
@-moz-keyframes cover-horizontal-yellow {
  0% {
    width: 0;
    height: 0;
    box-shadow: none;
  }
  39% {
    width: 0;
    height: 38px;
  }
  39.625% {
    width: 170px;
    box-shadow: -340px -38px #f9e52e, -340px -76px #f9e52e, -340px -114px #f9e52e, -340px -152px #f9e52e, -340px -190px #f9e52e, -340px -228px #f9e52e, -340px -266px #f9e52e, -340px -304px #f9e52e, -340px -342px #f9e52e, -340px -380px #f9e52e, -340px -418px #f9e52e, -340px -456px #f9e52e, -340px -494px #f9e52e, -340px -532px #f9e52e;
  }
  40.25% {
    width: 340px;
    box-shadow: -170px -38px #f9e52e, -340px -76px #f9e52e, -340px -114px #f9e52e, -340px -152px #f9e52e, -340px -190px #f9e52e, -340px -228px #f9e52e, -340px -266px #f9e52e, -340px -304px #f9e52e, -340px -342px #f9e52e, -340px -380px #f9e52e, -340px -418px #f9e52e, -340px -456px #f9e52e, -340px -494px #f9e52e, -340px -532px #f9e52e;
  }
  40.875% {
    box-shadow: 0 -38px #f9e52e, -170px -76px #f9e52e, -340px -114px #f9e52e, -340px -152px #f9e52e, -340px -190px #f9e52e, -340px -228px #f9e52e, -340px -266px #f9e52e, -340px -304px #f9e52e, -340px -342px #f9e52e, -340px -380px #f9e52e, -340px -418px #f9e52e, -340px -456px #f9e52e, -340px -494px #f9e52e, -340px -532px #f9e52e;
  }
  41.5% {
    box-shadow: 0 -38px #f9e52e, 0 -76px #f9e52e, -170px -114px #f9e52e, -340px -152px #f9e52e, -340px -190px #f9e52e, -340px -228px #f9e52e, -340px -266px #f9e52e, -340px -304px #f9e52e, -340px -342px #f9e52e, -340px -380px #f9e52e, -340px -418px #f9e52e, -340px -456px #f9e52e, -340px -494px #f9e52e, -340px -532px #f9e52e;
  }
  42.125% {
    box-shadow: 0 -38px #f9e52e, 0 -76px #f9e52e, 0 -114px #f9e52e, -170px -152px #f9e52e, -340px -190px #f9e52e, -340px -228px #f9e52e, -340px -266px #f9e52e, -340px -304px #f9e52e, -340px -342px #f9e52e, -340px -380px #f9e52e, -340px -418px #f9e52e, -340px -456px #f9e52e, -340px -494px #f9e52e, -340px -532px #f9e52e;
  }
  42.75% {
    box-shadow: 0 -38px #f9e52e, 0 -76px #f9e52e, 0 -114px #f9e52e, 0 -152px #f9e52e, -170px -190px #f9e52e, -340px -228px #f9e52e, -340px -266px #f9e52e, -340px -304px #f9e52e, -340px -342px #f9e52e, -340px -380px #f9e52e, -340px -418px #f9e52e, -340px -456px #f9e52e, -340px -494px #f9e52e, -340px -532px #f9e52e;
  }
  43.375% {
    box-shadow: 0 -38px #f9e52e, 0 -76px #f9e52e, 0 -114px #f9e52e, 0 -152px #f9e52e, 0 -190px #f9e52e, -170px -228px #f9e52e, -340px -266px #f9e52e, -340px -304px #f9e52e, -340px -342px #f9e52e, -340px -380px #f9e52e, -340px -418px #f9e52e, -340px -456px #f9e52e, -340px -494px #f9e52e, -340px -532px #f9e52e;
  }
  44% {
    box-shadow: 0 -38px #f9e52e, 0 -76px #f9e52e, 0 -114px #f9e52e, 0 -152px #f9e52e, 0 -190px #f9e52e, 0 -228px #f9e52e, -170px -266px #f9e52e, -340px -304px #f9e52e, -340px -342px #f9e52e, -340px -380px #f9e52e, -340px -418px #f9e52e, -340px -456px #f9e52e, -340px -494px #f9e52e, -340px -532px #f9e52e;
  }
  44.625% {
    box-shadow: 0 -38px #f9e52e, 0 -76px #f9e52e, 0 -114px #f9e52e, 0 -152px #f9e52e, 0 -190px #f9e52e, 0 -228px #f9e52e, 0 -266px #f9e52e, -170px -304px #f9e52e, -340px -342px #f9e52e, -340px -380px #f9e52e, -340px -418px #f9e52e, -340px -456px #f9e52e, -340px -494px #f9e52e, -340px -532px #f9e52e;
  }
  45.25% {
    box-shadow: 0 -38px #f9e52e, 0 -76px #f9e52e, 0 -114px #f9e52e, 0 -152px #f9e52e, 0 -190px #f9e52e, 0 -228px #f9e52e, 0 -266px #f9e52e, 0 -304px #f9e52e, -170px -342px #f9e52e, -340px -380px #f9e52e, -340px -418px #f9e52e, -340px -456px #f9e52e, -340px -494px #f9e52e, -340px -532px #f9e52e;
  }
  45.875% {
    box-shadow: 0 -38px #f9e52e, 0 -76px #f9e52e, 0 -114px #f9e52e, 0 -152px #f9e52e, 0 -190px #f9e52e, 0 -228px #f9e52e, 0 -266px #f9e52e, 0 -304px #f9e52e, 0 -342px #f9e52e, -170px -380px #f9e52e, -340px -418px #f9e52e, -340px -456px #f9e52e, -340px -494px #f9e52e, -340px -532px #f9e52e;
  }
  46.5% {
    box-shadow: 0 -38px #f9e52e, 0 -76px #f9e52e, 0 -114px #f9e52e, 0 -152px #f9e52e, 0 -190px #f9e52e, 0 -228px #f9e52e, 0 -266px #f9e52e, 0 -304px #f9e52e, 0 -342px #f9e52e, 0 -380px #f9e52e, -170px -418px #f9e52e, -340px -456px #f9e52e, -340px -494px #f9e52e, -340px -532px #f9e52e;
  }
  47.125% {
    box-shadow: 0 -38px #f9e52e, 0 -76px #f9e52e, 0 -114px #f9e52e, 0 -152px #f9e52e, 0 -190px #f9e52e, 0 -228px #f9e52e, 0 -266px #f9e52e, 0 -304px #f9e52e, 0 -342px #f9e52e, 0 -380px #f9e52e, 0 -418px #f9e52e, -170px -456px #f9e52e, -340px -494px #f9e52e, -340px -532px #f9e52e;
  }
  47.75% {
    box-shadow: 0 -38px #f9e52e, 0 -76px #f9e52e, 0 -114px #f9e52e, 0 -152px #f9e52e, 0 -190px #f9e52e, 0 -228px #f9e52e, 0 -266px #f9e52e, 0 -304px #f9e52e, 0 -342px #f9e52e, 0 -380px #f9e52e, 0 -418px #f9e52e, 0 -456px #f9e52e, -170px -494px #f9e52e, -340px -532px #f9e52e;
  }
  48.375% {
    box-shadow: 0 -38px #f9e52e, 0 -76px #f9e52e, 0 -114px #f9e52e, 0 -152px #f9e52e, 0 -190px #f9e52e, 0 -228px #f9e52e, 0 -266px #f9e52e, 0 -304px #f9e52e, 0 -342px #f9e52e, 0 -380px #f9e52e, 0 -418px #f9e52e, 0 -456px #f9e52e, 0 -494px #f9e52e, -170px -532px #f9e52e;
  }
  49% {
    box-shadow: 0 -38px #f9e52e, 0 -76px #f9e52e, 0 -114px #f9e52e, 0 -152px #f9e52e, 0 -190px #f9e52e, 0 -228px #f9e52e, 0 -266px #f9e52e, 0 -304px #f9e52e, 0 -342px #f9e52e, 0 -380px #f9e52e, 0 -418px #f9e52e, 0 -456px #f9e52e, 0 -494px #f9e52e, 0 -532px #f9e52e;
  }
}
@-webkit-keyframes cover-horizontal-yellow {
  0% {
    width: 0;
    height: 0;
    box-shadow: none;
  }
  39% {
    width: 0;
    height: 38px;
  }
  39.625% {
    width: 170px;
    box-shadow: -340px -38px #f9e52e, -340px -76px #f9e52e, -340px -114px #f9e52e, -340px -152px #f9e52e, -340px -190px #f9e52e, -340px -228px #f9e52e, -340px -266px #f9e52e, -340px -304px #f9e52e, -340px -342px #f9e52e, -340px -380px #f9e52e, -340px -418px #f9e52e, -340px -456px #f9e52e, -340px -494px #f9e52e, -340px -532px #f9e52e;
  }
  40.25% {
    width: 340px;
    box-shadow: -170px -38px #f9e52e, -340px -76px #f9e52e, -340px -114px #f9e52e, -340px -152px #f9e52e, -340px -190px #f9e52e, -340px -228px #f9e52e, -340px -266px #f9e52e, -340px -304px #f9e52e, -340px -342px #f9e52e, -340px -380px #f9e52e, -340px -418px #f9e52e, -340px -456px #f9e52e, -340px -494px #f9e52e, -340px -532px #f9e52e;
  }
  40.875% {
    box-shadow: 0 -38px #f9e52e, -170px -76px #f9e52e, -340px -114px #f9e52e, -340px -152px #f9e52e, -340px -190px #f9e52e, -340px -228px #f9e52e, -340px -266px #f9e52e, -340px -304px #f9e52e, -340px -342px #f9e52e, -340px -380px #f9e52e, -340px -418px #f9e52e, -340px -456px #f9e52e, -340px -494px #f9e52e, -340px -532px #f9e52e;
  }
  41.5% {
    box-shadow: 0 -38px #f9e52e, 0 -76px #f9e52e, -170px -114px #f9e52e, -340px -152px #f9e52e, -340px -190px #f9e52e, -340px -228px #f9e52e, -340px -266px #f9e52e, -340px -304px #f9e52e, -340px -342px #f9e52e, -340px -380px #f9e52e, -340px -418px #f9e52e, -340px -456px #f9e52e, -340px -494px #f9e52e, -340px -532px #f9e52e;
  }
  42.125% {
    box-shadow: 0 -38px #f9e52e, 0 -76px #f9e52e, 0 -114px #f9e52e, -170px -152px #f9e52e, -340px -190px #f9e52e, -340px -228px #f9e52e, -340px -266px #f9e52e, -340px -304px #f9e52e, -340px -342px #f9e52e, -340px -380px #f9e52e, -340px -418px #f9e52e, -340px -456px #f9e52e, -340px -494px #f9e52e, -340px -532px #f9e52e;
  }
  42.75% {
    box-shadow: 0 -38px #f9e52e, 0 -76px #f9e52e, 0 -114px #f9e52e, 0 -152px #f9e52e, -170px -190px #f9e52e, -340px -228px #f9e52e, -340px -266px #f9e52e, -340px -304px #f9e52e, -340px -342px #f9e52e, -340px -380px #f9e52e, -340px -418px #f9e52e, -340px -456px #f9e52e, -340px -494px #f9e52e, -340px -532px #f9e52e;
  }
  43.375% {
    box-shadow: 0 -38px #f9e52e, 0 -76px #f9e52e, 0 -114px #f9e52e, 0 -152px #f9e52e, 0 -190px #f9e52e, -170px -228px #f9e52e, -340px -266px #f9e52e, -340px -304px #f9e52e, -340px -342px #f9e52e, -340px -380px #f9e52e, -340px -418px #f9e52e, -340px -456px #f9e52e, -340px -494px #f9e52e, -340px -532px #f9e52e;
  }
  44% {
    box-shadow: 0 -38px #f9e52e, 0 -76px #f9e52e, 0 -114px #f9e52e, 0 -152px #f9e52e, 0 -190px #f9e52e, 0 -228px #f9e52e, -170px -266px #f9e52e, -340px -304px #f9e52e, -340px -342px #f9e52e, -340px -380px #f9e52e, -340px -418px #f9e52e, -340px -456px #f9e52e, -340px -494px #f9e52e, -340px -532px #f9e52e;
  }
  44.625% {
    box-shadow: 0 -38px #f9e52e, 0 -76px #f9e52e, 0 -114px #f9e52e, 0 -152px #f9e52e, 0 -190px #f9e52e, 0 -228px #f9e52e, 0 -266px #f9e52e, -170px -304px #f9e52e, -340px -342px #f9e52e, -340px -380px #f9e52e, -340px -418px #f9e52e, -340px -456px #f9e52e, -340px -494px #f9e52e, -340px -532px #f9e52e;
  }
  45.25% {
    box-shadow: 0 -38px #f9e52e, 0 -76px #f9e52e, 0 -114px #f9e52e, 0 -152px #f9e52e, 0 -190px #f9e52e, 0 -228px #f9e52e, 0 -266px #f9e52e, 0 -304px #f9e52e, -170px -342px #f9e52e, -340px -380px #f9e52e, -340px -418px #f9e52e, -340px -456px #f9e52e, -340px -494px #f9e52e, -340px -532px #f9e52e;
  }
  45.875% {
    box-shadow: 0 -38px #f9e52e, 0 -76px #f9e52e, 0 -114px #f9e52e, 0 -152px #f9e52e, 0 -190px #f9e52e, 0 -228px #f9e52e, 0 -266px #f9e52e, 0 -304px #f9e52e, 0 -342px #f9e52e, -170px -380px #f9e52e, -340px -418px #f9e52e, -340px -456px #f9e52e, -340px -494px #f9e52e, -340px -532px #f9e52e;
  }
  46.5% {
    box-shadow: 0 -38px #f9e52e, 0 -76px #f9e52e, 0 -114px #f9e52e, 0 -152px #f9e52e, 0 -190px #f9e52e, 0 -228px #f9e52e, 0 -266px #f9e52e, 0 -304px #f9e52e, 0 -342px #f9e52e, 0 -380px #f9e52e, -170px -418px #f9e52e, -340px -456px #f9e52e, -340px -494px #f9e52e, -340px -532px #f9e52e;
  }
  47.125% {
    box-shadow: 0 -38px #f9e52e, 0 -76px #f9e52e, 0 -114px #f9e52e, 0 -152px #f9e52e, 0 -190px #f9e52e, 0 -228px #f9e52e, 0 -266px #f9e52e, 0 -304px #f9e52e, 0 -342px #f9e52e, 0 -380px #f9e52e, 0 -418px #f9e52e, -170px -456px #f9e52e, -340px -494px #f9e52e, -340px -532px #f9e52e;
  }
  47.75% {
    box-shadow: 0 -38px #f9e52e, 0 -76px #f9e52e, 0 -114px #f9e52e, 0 -152px #f9e52e, 0 -190px #f9e52e, 0 -228px #f9e52e, 0 -266px #f9e52e, 0 -304px #f9e52e, 0 -342px #f9e52e, 0 -380px #f9e52e, 0 -418px #f9e52e, 0 -456px #f9e52e, -170px -494px #f9e52e, -340px -532px #f9e52e;
  }
  48.375% {
    box-shadow: 0 -38px #f9e52e, 0 -76px #f9e52e, 0 -114px #f9e52e, 0 -152px #f9e52e, 0 -190px #f9e52e, 0 -228px #f9e52e, 0 -266px #f9e52e, 0 -304px #f9e52e, 0 -342px #f9e52e, 0 -380px #f9e52e, 0 -418px #f9e52e, 0 -456px #f9e52e, 0 -494px #f9e52e, -170px -532px #f9e52e;
  }
  49% {
    box-shadow: 0 -38px #f9e52e, 0 -76px #f9e52e, 0 -114px #f9e52e, 0 -152px #f9e52e, 0 -190px #f9e52e, 0 -228px #f9e52e, 0 -266px #f9e52e, 0 -304px #f9e52e, 0 -342px #f9e52e, 0 -380px #f9e52e, 0 -418px #f9e52e, 0 -456px #f9e52e, 0 -494px #f9e52e, 0 -532px #f9e52e;
  }
}
@keyframes cover-horizontal-yellow {
  0% {
    width: 0;
    height: 0;
    box-shadow: none;
  }
  39% {
    width: 0;
    height: 38px;
  }
  39.625% {
    width: 170px;
    box-shadow: -340px -38px #f9e52e, -340px -76px #f9e52e, -340px -114px #f9e52e, -340px -152px #f9e52e, -340px -190px #f9e52e, -340px -228px #f9e52e, -340px -266px #f9e52e, -340px -304px #f9e52e, -340px -342px #f9e52e, -340px -380px #f9e52e, -340px -418px #f9e52e, -340px -456px #f9e52e, -340px -494px #f9e52e, -340px -532px #f9e52e;
  }
  40.25% {
    width: 340px;
    box-shadow: -170px -38px #f9e52e, -340px -76px #f9e52e, -340px -114px #f9e52e, -340px -152px #f9e52e, -340px -190px #f9e52e, -340px -228px #f9e52e, -340px -266px #f9e52e, -340px -304px #f9e52e, -340px -342px #f9e52e, -340px -380px #f9e52e, -340px -418px #f9e52e, -340px -456px #f9e52e, -340px -494px #f9e52e, -340px -532px #f9e52e;
  }
  40.875% {
    box-shadow: 0 -38px #f9e52e, -170px -76px #f9e52e, -340px -114px #f9e52e, -340px -152px #f9e52e, -340px -190px #f9e52e, -340px -228px #f9e52e, -340px -266px #f9e52e, -340px -304px #f9e52e, -340px -342px #f9e52e, -340px -380px #f9e52e, -340px -418px #f9e52e, -340px -456px #f9e52e, -340px -494px #f9e52e, -340px -532px #f9e52e;
  }
  41.5% {
    box-shadow: 0 -38px #f9e52e, 0 -76px #f9e52e, -170px -114px #f9e52e, -340px -152px #f9e52e, -340px -190px #f9e52e, -340px -228px #f9e52e, -340px -266px #f9e52e, -340px -304px #f9e52e, -340px -342px #f9e52e, -340px -380px #f9e52e, -340px -418px #f9e52e, -340px -456px #f9e52e, -340px -494px #f9e52e, -340px -532px #f9e52e;
  }
  42.125% {
    box-shadow: 0 -38px #f9e52e, 0 -76px #f9e52e, 0 -114px #f9e52e, -170px -152px #f9e52e, -340px -190px #f9e52e, -340px -228px #f9e52e, -340px -266px #f9e52e, -340px -304px #f9e52e, -340px -342px #f9e52e, -340px -380px #f9e52e, -340px -418px #f9e52e, -340px -456px #f9e52e, -340px -494px #f9e52e, -340px -532px #f9e52e;
  }
  42.75% {
    box-shadow: 0 -38px #f9e52e, 0 -76px #f9e52e, 0 -114px #f9e52e, 0 -152px #f9e52e, -170px -190px #f9e52e, -340px -228px #f9e52e, -340px -266px #f9e52e, -340px -304px #f9e52e, -340px -342px #f9e52e, -340px -380px #f9e52e, -340px -418px #f9e52e, -340px -456px #f9e52e, -340px -494px #f9e52e, -340px -532px #f9e52e;
  }
  43.375% {
    box-shadow: 0 -38px #f9e52e, 0 -76px #f9e52e, 0 -114px #f9e52e, 0 -152px #f9e52e, 0 -190px #f9e52e, -170px -228px #f9e52e, -340px -266px #f9e52e, -340px -304px #f9e52e, -340px -342px #f9e52e, -340px -380px #f9e52e, -340px -418px #f9e52e, -340px -456px #f9e52e, -340px -494px #f9e52e, -340px -532px #f9e52e;
  }
  44% {
    box-shadow: 0 -38px #f9e52e, 0 -76px #f9e52e, 0 -114px #f9e52e, 0 -152px #f9e52e, 0 -190px #f9e52e, 0 -228px #f9e52e, -170px -266px #f9e52e, -340px -304px #f9e52e, -340px -342px #f9e52e, -340px -380px #f9e52e, -340px -418px #f9e52e, -340px -456px #f9e52e, -340px -494px #f9e52e, -340px -532px #f9e52e;
  }
  44.625% {
    box-shadow: 0 -38px #f9e52e, 0 -76px #f9e52e, 0 -114px #f9e52e, 0 -152px #f9e52e, 0 -190px #f9e52e, 0 -228px #f9e52e, 0 -266px #f9e52e, -170px -304px #f9e52e, -340px -342px #f9e52e, -340px -380px #f9e52e, -340px -418px #f9e52e, -340px -456px #f9e52e, -340px -494px #f9e52e, -340px -532px #f9e52e;
  }
  45.25% {
    box-shadow: 0 -38px #f9e52e, 0 -76px #f9e52e, 0 -114px #f9e52e, 0 -152px #f9e52e, 0 -190px #f9e52e, 0 -228px #f9e52e, 0 -266px #f9e52e, 0 -304px #f9e52e, -170px -342px #f9e52e, -340px -380px #f9e52e, -340px -418px #f9e52e, -340px -456px #f9e52e, -340px -494px #f9e52e, -340px -532px #f9e52e;
  }
  45.875% {
    box-shadow: 0 -38px #f9e52e, 0 -76px #f9e52e, 0 -114px #f9e52e, 0 -152px #f9e52e, 0 -190px #f9e52e, 0 -228px #f9e52e, 0 -266px #f9e52e, 0 -304px #f9e52e, 0 -342px #f9e52e, -170px -380px #f9e52e, -340px -418px #f9e52e, -340px -456px #f9e52e, -340px -494px #f9e52e, -340px -532px #f9e52e;
  }
  46.5% {
    box-shadow: 0 -38px #f9e52e, 0 -76px #f9e52e, 0 -114px #f9e52e, 0 -152px #f9e52e, 0 -190px #f9e52e, 0 -228px #f9e52e, 0 -266px #f9e52e, 0 -304px #f9e52e, 0 -342px #f9e52e, 0 -380px #f9e52e, -170px -418px #f9e52e, -340px -456px #f9e52e, -340px -494px #f9e52e, -340px -532px #f9e52e;
  }
  47.125% {
    box-shadow: 0 -38px #f9e52e, 0 -76px #f9e52e, 0 -114px #f9e52e, 0 -152px #f9e52e, 0 -190px #f9e52e, 0 -228px #f9e52e, 0 -266px #f9e52e, 0 -304px #f9e52e, 0 -342px #f9e52e, 0 -380px #f9e52e, 0 -418px #f9e52e, -170px -456px #f9e52e, -340px -494px #f9e52e, -340px -532px #f9e52e;
  }
  47.75% {
    box-shadow: 0 -38px #f9e52e, 0 -76px #f9e52e, 0 -114px #f9e52e, 0 -152px #f9e52e, 0 -190px #f9e52e, 0 -228px #f9e52e, 0 -266px #f9e52e, 0 -304px #f9e52e, 0 -342px #f9e52e, 0 -380px #f9e52e, 0 -418px #f9e52e, 0 -456px #f9e52e, -170px -494px #f9e52e, -340px -532px #f9e52e;
  }
  48.375% {
    box-shadow: 0 -38px #f9e52e, 0 -76px #f9e52e, 0 -114px #f9e52e, 0 -152px #f9e52e, 0 -190px #f9e52e, 0 -228px #f9e52e, 0 -266px #f9e52e, 0 -304px #f9e52e, 0 -342px #f9e52e, 0 -380px #f9e52e, 0 -418px #f9e52e, 0 -456px #f9e52e, 0 -494px #f9e52e, -170px -532px #f9e52e;
  }
  49% {
    box-shadow: 0 -38px #f9e52e, 0 -76px #f9e52e, 0 -114px #f9e52e, 0 -152px #f9e52e, 0 -190px #f9e52e, 0 -228px #f9e52e, 0 -266px #f9e52e, 0 -304px #f9e52e, 0 -342px #f9e52e, 0 -380px #f9e52e, 0 -418px #f9e52e, 0 -456px #f9e52e, 0 -494px #f9e52e, 0 -532px #f9e52e;
  }
}
#gameboy.yellow #cover-horizontal {
  background-color: #f9e52e;
  -webkit-animation: cover-horizontal-yellow 8s   linear;
  animation: cover-horizontal-yellow 8s   linear;
  box-shadow: 0 -38px #f9e52e, 0 -76px #f9e52e, 0 -114px #f9e52e, 0 -152px #f9e52e, 0 -190px #f9e52e, 0 -228px #f9e52e, 0 -266px #f9e52e, 0 -304px #f9e52e, 0 -342px #f9e52e, 0 -380px #f9e52e, 0 -418px #f9e52e, 0 -456px #f9e52e, 0 -494px #f9e52e, 0 -532px #f9e52e;
}
@-moz-keyframes cover-horizontal-green {
  0% {
    width: 0;
    height: 0;
    box-shadow: none;
  }
  39% {
    width: 0;
    height: 38px;
  }
  39.625% {
    width: 170px;
    box-shadow: -340px -38px #85e367, -340px -76px #85e367, -340px -114px #85e367, -340px -152px #85e367, -340px -190px #85e367, -340px -228px #85e367, -340px -266px #85e367, -340px -304px #85e367, -340px -342px #85e367, -340px -380px #85e367, -340px -418px #85e367, -340px -456px #85e367, -340px -494px #85e367, -340px -532px #85e367;
  }
  40.25% {
    width: 340px;
    box-shadow: -170px -38px #85e367, -340px -76px #85e367, -340px -114px #85e367, -340px -152px #85e367, -340px -190px #85e367, -340px -228px #85e367, -340px -266px #85e367, -340px -304px #85e367, -340px -342px #85e367, -340px -380px #85e367, -340px -418px #85e367, -340px -456px #85e367, -340px -494px #85e367, -340px -532px #85e367;
  }
  40.875% {
    box-shadow: 0 -38px #85e367, -170px -76px #85e367, -340px -114px #85e367, -340px -152px #85e367, -340px -190px #85e367, -340px -228px #85e367, -340px -266px #85e367, -340px -304px #85e367, -340px -342px #85e367, -340px -380px #85e367, -340px -418px #85e367, -340px -456px #85e367, -340px -494px #85e367, -340px -532px #85e367;
  }
  41.5% {
    box-shadow: 0 -38px #85e367, 0 -76px #85e367, -170px -114px #85e367, -340px -152px #85e367, -340px -190px #85e367, -340px -228px #85e367, -340px -266px #85e367, -340px -304px #85e367, -340px -342px #85e367, -340px -380px #85e367, -340px -418px #85e367, -340px -456px #85e367, -340px -494px #85e367, -340px -532px #85e367;
  }
  42.125% {
    box-shadow: 0 -38px #85e367, 0 -76px #85e367, 0 -114px #85e367, -170px -152px #85e367, -340px -190px #85e367, -340px -228px #85e367, -340px -266px #85e367, -340px -304px #85e367, -340px -342px #85e367, -340px -380px #85e367, -340px -418px #85e367, -340px -456px #85e367, -340px -494px #85e367, -340px -532px #85e367;
  }
  42.75% {
    box-shadow: 0 -38px #85e367, 0 -76px #85e367, 0 -114px #85e367, 0 -152px #85e367, -170px -190px #85e367, -340px -228px #85e367, -340px -266px #85e367, -340px -304px #85e367, -340px -342px #85e367, -340px -380px #85e367, -340px -418px #85e367, -340px -456px #85e367, -340px -494px #85e367, -340px -532px #85e367;
  }
  43.375% {
    box-shadow: 0 -38px #85e367, 0 -76px #85e367, 0 -114px #85e367, 0 -152px #85e367, 0 -190px #85e367, -170px -228px #85e367, -340px -266px #85e367, -340px -304px #85e367, -340px -342px #85e367, -340px -380px #85e367, -340px -418px #85e367, -340px -456px #85e367, -340px -494px #85e367, -340px -532px #85e367;
  }
  44% {
    box-shadow: 0 -38px #85e367, 0 -76px #85e367, 0 -114px #85e367, 0 -152px #85e367, 0 -190px #85e367, 0 -228px #85e367, -170px -266px #85e367, -340px -304px #85e367, -340px -342px #85e367, -340px -380px #85e367, -340px -418px #85e367, -340px -456px #85e367, -340px -494px #85e367, -340px -532px #85e367;
  }
  44.625% {
    box-shadow: 0 -38px #85e367, 0 -76px #85e367, 0 -114px #85e367, 0 -152px #85e367, 0 -190px #85e367, 0 -228px #85e367, 0 -266px #85e367, -170px -304px #85e367, -340px -342px #85e367, -340px -380px #85e367, -340px -418px #85e367, -340px -456px #85e367, -340px -494px #85e367, -340px -532px #85e367;
  }
  45.25% {
    box-shadow: 0 -38px #85e367, 0 -76px #85e367, 0 -114px #85e367, 0 -152px #85e367, 0 -190px #85e367, 0 -228px #85e367, 0 -266px #85e367, 0 -304px #85e367, -170px -342px #85e367, -340px -380px #85e367, -340px -418px #85e367, -340px -456px #85e367, -340px -494px #85e367, -340px -532px #85e367;
  }
  45.875% {
    box-shadow: 0 -38px #85e367, 0 -76px #85e367, 0 -114px #85e367, 0 -152px #85e367, 0 -190px #85e367, 0 -228px #85e367, 0 -266px #85e367, 0 -304px #85e367, 0 -342px #85e367, -170px -380px #85e367, -340px -418px #85e367, -340px -456px #85e367, -340px -494px #85e367, -340px -532px #85e367;
  }
  46.5% {
    box-shadow: 0 -38px #85e367, 0 -76px #85e367, 0 -114px #85e367, 0 -152px #85e367, 0 -190px #85e367, 0 -228px #85e367, 0 -266px #85e367, 0 -304px #85e367, 0 -342px #85e367, 0 -380px #85e367, -170px -418px #85e367, -340px -456px #85e367, -340px -494px #85e367, -340px -532px #85e367;
  }
  47.125% {
    box-shadow: 0 -38px #85e367, 0 -76px #85e367, 0 -114px #85e367, 0 -152px #85e367, 0 -190px #85e367, 0 -228px #85e367, 0 -266px #85e367, 0 -304px #85e367, 0 -342px #85e367, 0 -380px #85e367, 0 -418px #85e367, -170px -456px #85e367, -340px -494px #85e367, -340px -532px #85e367;
  }
  47.75% {
    box-shadow: 0 -38px #85e367, 0 -76px #85e367, 0 -114px #85e367, 0 -152px #85e367, 0 -190px #85e367, 0 -228px #85e367, 0 -266px #85e367, 0 -304px #85e367, 0 -342px #85e367, 0 -380px #85e367, 0 -418px #85e367, 0 -456px #85e367, -170px -494px #85e367, -340px -532px #85e367;
  }
  48.375% {
    box-shadow: 0 -38px #85e367, 0 -76px #85e367, 0 -114px #85e367, 0 -152px #85e367, 0 -190px #85e367, 0 -228px #85e367, 0 -266px #85e367, 0 -304px #85e367, 0 -342px #85e367, 0 -380px #85e367, 0 -418px #85e367, 0 -456px #85e367, 0 -494px #85e367, -170px -532px #85e367;
  }
  49% {
    box-shadow: 0 -38px #85e367, 0 -76px #85e367, 0 -114px #85e367, 0 -152px #85e367, 0 -190px #85e367, 0 -228px #85e367, 0 -266px #85e367, 0 -304px #85e367, 0 -342px #85e367, 0 -380px #85e367, 0 -418px #85e367, 0 -456px #85e367, 0 -494px #85e367, 0 -532px #85e367;
  }
}
@-webkit-keyframes cover-horizontal-green {
  0% {
    width: 0;
    height: 0;
    box-shadow: none;
  }
  39% {
    width: 0;
    height: 38px;
  }
  39.625% {
    width: 170px;
    box-shadow: -340px -38px #85e367, -340px -76px #85e367, -340px -114px #85e367, -340px -152px #85e367, -340px -190px #85e367, -340px -228px #85e367, -340px -266px #85e367, -340px -304px #85e367, -340px -342px #85e367, -340px -380px #85e367, -340px -418px #85e367, -340px -456px #85e367, -340px -494px #85e367, -340px -532px #85e367;
  }
  40.25% {
    width: 340px;
    box-shadow: -170px -38px #85e367, -340px -76px #85e367, -340px -114px #85e367, -340px -152px #85e367, -340px -190px #85e367, -340px -228px #85e367, -340px -266px #85e367, -340px -304px #85e367, -340px -342px #85e367, -340px -380px #85e367, -340px -418px #85e367, -340px -456px #85e367, -340px -494px #85e367, -340px -532px #85e367;
  }
  40.875% {
    box-shadow: 0 -38px #85e367, -170px -76px #85e367, -340px -114px #85e367, -340px -152px #85e367, -340px -190px #85e367, -340px -228px #85e367, -340px -266px #85e367, -340px -304px #85e367, -340px -342px #85e367, -340px -380px #85e367, -340px -418px #85e367, -340px -456px #85e367, -340px -494px #85e367, -340px -532px #85e367;
  }
  41.5% {
    box-shadow: 0 -38px #85e367, 0 -76px #85e367, -170px -114px #85e367, -340px -152px #85e367, -340px -190px #85e367, -340px -228px #85e367, -340px -266px #85e367, -340px -304px #85e367, -340px -342px #85e367, -340px -380px #85e367, -340px -418px #85e367, -340px -456px #85e367, -340px -494px #85e367, -340px -532px #85e367;
  }
  42.125% {
    box-shadow: 0 -38px #85e367, 0 -76px #85e367, 0 -114px #85e367, -170px -152px #85e367, -340px -190px #85e367, -340px -228px #85e367, -340px -266px #85e367, -340px -304px #85e367, -340px -342px #85e367, -340px -380px #85e367, -340px -418px #85e367, -340px -456px #85e367, -340px -494px #85e367, -340px -532px #85e367;
  }
  42.75% {
    box-shadow: 0 -38px #85e367, 0 -76px #85e367, 0 -114px #85e367, 0 -152px #85e367, -170px -190px #85e367, -340px -228px #85e367, -340px -266px #85e367, -340px -304px #85e367, -340px -342px #85e367, -340px -380px #85e367, -340px -418px #85e367, -340px -456px #85e367, -340px -494px #85e367, -340px -532px #85e367;
  }
  43.375% {
    box-shadow: 0 -38px #85e367, 0 -76px #85e367, 0 -114px #85e367, 0 -152px #85e367, 0 -190px #85e367, -170px -228px #85e367, -340px -266px #85e367, -340px -304px #85e367, -340px -342px #85e367, -340px -380px #85e367, -340px -418px #85e367, -340px -456px #85e367, -340px -494px #85e367, -340px -532px #85e367;
  }
  44% {
    box-shadow: 0 -38px #85e367, 0 -76px #85e367, 0 -114px #85e367, 0 -152px #85e367, 0 -190px #85e367, 0 -228px #85e367, -170px -266px #85e367, -340px -304px #85e367, -340px -342px #85e367, -340px -380px #85e367, -340px -418px #85e367, -340px -456px #85e367, -340px -494px #85e367, -340px -532px #85e367;
  }
  44.625% {
    box-shadow: 0 -38px #85e367, 0 -76px #85e367, 0 -114px #85e367, 0 -152px #85e367, 0 -190px #85e367, 0 -228px #85e367, 0 -266px #85e367, -170px -304px #85e367, -340px -342px #85e367, -340px -380px #85e367, -340px -418px #85e367, -340px -456px #85e367, -340px -494px #85e367, -340px -532px #85e367;
  }
  45.25% {
    box-shadow: 0 -38px #85e367, 0 -76px #85e367, 0 -114px #85e367, 0 -152px #85e367, 0 -190px #85e367, 0 -228px #85e367, 0 -266px #85e367, 0 -304px #85e367, -170px -342px #85e367, -340px -380px #85e367, -340px -418px #85e367, -340px -456px #85e367, -340px -494px #85e367, -340px -532px #85e367;
  }
  45.875% {
    box-shadow: 0 -38px #85e367, 0 -76px #85e367, 0 -114px #85e367, 0 -152px #85e367, 0 -190px #85e367, 0 -228px #85e367, 0 -266px #85e367, 0 -304px #85e367, 0 -342px #85e367, -170px -380px #85e367, -340px -418px #85e367, -340px -456px #85e367, -340px -494px #85e367, -340px -532px #85e367;
  }
  46.5% {
    box-shadow: 0 -38px #85e367, 0 -76px #85e367, 0 -114px #85e367, 0 -152px #85e367, 0 -190px #85e367, 0 -228px #85e367, 0 -266px #85e367, 0 -304px #85e367, 0 -342px #85e367, 0 -380px #85e367, -170px -418px #85e367, -340px -456px #85e367, -340px -494px #85e367, -340px -532px #85e367;
  }
  47.125% {
    box-shadow: 0 -38px #85e367, 0 -76px #85e367, 0 -114px #85e367, 0 -152px #85e367, 0 -190px #85e367, 0 -228px #85e367, 0 -266px #85e367, 0 -304px #85e367, 0 -342px #85e367, 0 -380px #85e367, 0 -418px #85e367, -170px -456px #85e367, -340px -494px #85e367, -340px -532px #85e367;
  }
  47.75% {
    box-shadow: 0 -38px #85e367, 0 -76px #85e367, 0 -114px #85e367, 0 -152px #85e367, 0 -190px #85e367, 0 -228px #85e367, 0 -266px #85e367, 0 -304px #85e367, 0 -342px #85e367, 0 -380px #85e367, 0 -418px #85e367, 0 -456px #85e367, -170px -494px #85e367, -340px -532px #85e367;
  }
  48.375% {
    box-shadow: 0 -38px #85e367, 0 -76px #85e367, 0 -114px #85e367, 0 -152px #85e367, 0 -190px #85e367, 0 -228px #85e367, 0 -266px #85e367, 0 -304px #85e367, 0 -342px #85e367, 0 -380px #85e367, 0 -418px #85e367, 0 -456px #85e367, 0 -494px #85e367, -170px -532px #85e367;
  }
  49% {
    box-shadow: 0 -38px #85e367, 0 -76px #85e367, 0 -114px #85e367, 0 -152px #85e367, 0 -190px #85e367, 0 -228px #85e367, 0 -266px #85e367, 0 -304px #85e367, 0 -342px #85e367, 0 -380px #85e367, 0 -418px #85e367, 0 -456px #85e367, 0 -494px #85e367, 0 -532px #85e367;
  }
}
@keyframes cover-horizontal-green {
  0% {
    width: 0;
    height: 0;
    box-shadow: none;
  }
  39% {
    width: 0;
    height: 38px;
  }
  39.625% {
    width: 170px;
    box-shadow: -340px -38px #85e367, -340px -76px #85e367, -340px -114px #85e367, -340px -152px #85e367, -340px -190px #85e367, -340px -228px #85e367, -340px -266px #85e367, -340px -304px #85e367, -340px -342px #85e367, -340px -380px #85e367, -340px -418px #85e367, -340px -456px #85e367, -340px -494px #85e367, -340px -532px #85e367;
  }
  40.25% {
    width: 340px;
    box-shadow: -170px -38px #85e367, -340px -76px #85e367, -340px -114px #85e367, -340px -152px #85e367, -340px -190px #85e367, -340px -228px #85e367, -340px -266px #85e367, -340px -304px #85e367, -340px -342px #85e367, -340px -380px #85e367, -340px -418px #85e367, -340px -456px #85e367, -340px -494px #85e367, -340px -532px #85e367;
  }
  40.875% {
    box-shadow: 0 -38px #85e367, -170px -76px #85e367, -340px -114px #85e367, -340px -152px #85e367, -340px -190px #85e367, -340px -228px #85e367, -340px -266px #85e367, -340px -304px #85e367, -340px -342px #85e367, -340px -380px #85e367, -340px -418px #85e367, -340px -456px #85e367, -340px -494px #85e367, -340px -532px #85e367;
  }
  41.5% {
    box-shadow: 0 -38px #85e367, 0 -76px #85e367, -170px -114px #85e367, -340px -152px #85e367, -340px -190px #85e367, -340px -228px #85e367, -340px -266px #85e367, -340px -304px #85e367, -340px -342px #85e367, -340px -380px #85e367, -340px -418px #85e367, -340px -456px #85e367, -340px -494px #85e367, -340px -532px #85e367;
  }
  42.125% {
    box-shadow: 0 -38px #85e367, 0 -76px #85e367, 0 -114px #85e367, -170px -152px #85e367, -340px -190px #85e367, -340px -228px #85e367, -340px -266px #85e367, -340px -304px #85e367, -340px -342px #85e367, -340px -380px #85e367, -340px -418px #85e367, -340px -456px #85e367, -340px -494px #85e367, -340px -532px #85e367;
  }
  42.75% {
    box-shadow: 0 -38px #85e367, 0 -76px #85e367, 0 -114px #85e367, 0 -152px #85e367, -170px -190px #85e367, -340px -228px #85e367, -340px -266px #85e367, -340px -304px #85e367, -340px -342px #85e367, -340px -380px #85e367, -340px -418px #85e367, -340px -456px #85e367, -340px -494px #85e367, -340px -532px #85e367;
  }
  43.375% {
    box-shadow: 0 -38px #85e367, 0 -76px #85e367, 0 -114px #85e367, 0 -152px #85e367, 0 -190px #85e367, -170px -228px #85e367, -340px -266px #85e367, -340px -304px #85e367, -340px -342px #85e367, -340px -380px #85e367, -340px -418px #85e367, -340px -456px #85e367, -340px -494px #85e367, -340px -532px #85e367;
  }
  44% {
    box-shadow: 0 -38px #85e367, 0 -76px #85e367, 0 -114px #85e367, 0 -152px #85e367, 0 -190px #85e367, 0 -228px #85e367, -170px -266px #85e367, -340px -304px #85e367, -340px -342px #85e367, -340px -380px #85e367, -340px -418px #85e367, -340px -456px #85e367, -340px -494px #85e367, -340px -532px #85e367;
  }
  44.625% {
    box-shadow: 0 -38px #85e367, 0 -76px #85e367, 0 -114px #85e367, 0 -152px #85e367, 0 -190px #85e367, 0 -228px #85e367, 0 -266px #85e367, -170px -304px #85e367, -340px -342px #85e367, -340px -380px #85e367, -340px -418px #85e367, -340px -456px #85e367, -340px -494px #85e367, -340px -532px #85e367;
  }
  45.25% {
    box-shadow: 0 -38px #85e367, 0 -76px #85e367, 0 -114px #85e367, 0 -152px #85e367, 0 -190px #85e367, 0 -228px #85e367, 0 -266px #85e367, 0 -304px #85e367, -170px -342px #85e367, -340px -380px #85e367, -340px -418px #85e367, -340px -456px #85e367, -340px -494px #85e367, -340px -532px #85e367;
  }
  45.875% {
    box-shadow: 0 -38px #85e367, 0 -76px #85e367, 0 -114px #85e367, 0 -152px #85e367, 0 -190px #85e367, 0 -228px #85e367, 0 -266px #85e367, 0 -304px #85e367, 0 -342px #85e367, -170px -380px #85e367, -340px -418px #85e367, -340px -456px #85e367, -340px -494px #85e367, -340px -532px #85e367;
  }
  46.5% {
    box-shadow: 0 -38px #85e367, 0 -76px #85e367, 0 -114px #85e367, 0 -152px #85e367, 0 -190px #85e367, 0 -228px #85e367, 0 -266px #85e367, 0 -304px #85e367, 0 -342px #85e367, 0 -380px #85e367, -170px -418px #85e367, -340px -456px #85e367, -340px -494px #85e367, -340px -532px #85e367;
  }
  47.125% {
    box-shadow: 0 -38px #85e367, 0 -76px #85e367, 0 -114px #85e367, 0 -152px #85e367, 0 -190px #85e367, 0 -228px #85e367, 0 -266px #85e367, 0 -304px #85e367, 0 -342px #85e367, 0 -380px #85e367, 0 -418px #85e367, -170px -456px #85e367, -340px -494px #85e367, -340px -532px #85e367;
  }
  47.75% {
    box-shadow: 0 -38px #85e367, 0 -76px #85e367, 0 -114px #85e367, 0 -152px #85e367, 0 -190px #85e367, 0 -228px #85e367, 0 -266px #85e367, 0 -304px #85e367, 0 -342px #85e367, 0 -380px #85e367, 0 -418px #85e367, 0 -456px #85e367, -170px -494px #85e367, -340px -532px #85e367;
  }
  48.375% {
    box-shadow: 0 -38px #85e367, 0 -76px #85e367, 0 -114px #85e367, 0 -152px #85e367, 0 -190px #85e367, 0 -228px #85e367, 0 -266px #85e367, 0 -304px #85e367, 0 -342px #85e367, 0 -380px #85e367, 0 -418px #85e367, 0 -456px #85e367, 0 -494px #85e367, -170px -532px #85e367;
  }
  49% {
    box-shadow: 0 -38px #85e367, 0 -76px #85e367, 0 -114px #85e367, 0 -152px #85e367, 0 -190px #85e367, 0 -228px #85e367, 0 -266px #85e367, 0 -304px #85e367, 0 -342px #85e367, 0 -380px #85e367, 0 -418px #85e367, 0 -456px #85e367, 0 -494px #85e367, 0 -532px #85e367;
  }
}
#gameboy.green #cover-horizontal {
  background-color: #85e367;
  -webkit-animation: cover-horizontal-green 8s   linear;
  animation: cover-horizontal-green 8s   linear;
  box-shadow: 0 -38px #85e367, 0 -76px #85e367, 0 -114px #85e367, 0 -152px #85e367, 0 -190px #85e367, 0 -228px #85e367, 0 -266px #85e367, 0 -304px #85e367, 0 -342px #85e367, 0 -380px #85e367, 0 -418px #85e367, 0 -456px #85e367, 0 -494px #85e367, 0 -532px #85e367;
}
@-moz-keyframes cover-horizontal-purple {
  0% {
    width: 0;
    height: 0;
    box-shadow: none;
  }
  39% {
    width: 0;
    height: 38px;
  }
  39.625% {
    width: 170px;
    box-shadow: -340px -38px #5151dd, -340px -76px #5151dd, -340px -114px #5151dd, -340px -152px #5151dd, -340px -190px #5151dd, -340px -228px #5151dd, -340px -266px #5151dd, -340px -304px #5151dd, -340px -342px #5151dd, -340px -380px #5151dd, -340px -418px #5151dd, -340px -456px #5151dd, -340px -494px #5151dd, -340px -532px #5151dd;
  }
  40.25% {
    width: 340px;
    box-shadow: -170px -38px #5151dd, -340px -76px #5151dd, -340px -114px #5151dd, -340px -152px #5151dd, -340px -190px #5151dd, -340px -228px #5151dd, -340px -266px #5151dd, -340px -304px #5151dd, -340px -342px #5151dd, -340px -380px #5151dd, -340px -418px #5151dd, -340px -456px #5151dd, -340px -494px #5151dd, -340px -532px #5151dd;
  }
  40.875% {
    box-shadow: 0 -38px #5151dd, -170px -76px #5151dd, -340px -114px #5151dd, -340px -152px #5151dd, -340px -190px #5151dd, -340px -228px #5151dd, -340px -266px #5151dd, -340px -304px #5151dd, -340px -342px #5151dd, -340px -380px #5151dd, -340px -418px #5151dd, -340px -456px #5151dd, -340px -494px #5151dd, -340px -532px #5151dd;
  }
  41.5% {
    box-shadow: 0 -38px #5151dd, 0 -76px #5151dd, -170px -114px #5151dd, -340px -152px #5151dd, -340px -190px #5151dd, -340px -228px #5151dd, -340px -266px #5151dd, -340px -304px #5151dd, -340px -342px #5151dd, -340px -380px #5151dd, -340px -418px #5151dd, -340px -456px #5151dd, -340px -494px #5151dd, -340px -532px #5151dd;
  }
  42.125% {
    box-shadow: 0 -38px #5151dd, 0 -76px #5151dd, 0 -114px #5151dd, -170px -152px #5151dd, -340px -190px #5151dd, -340px -228px #5151dd, -340px -266px #5151dd, -340px -304px #5151dd, -340px -342px #5151dd, -340px -380px #5151dd, -340px -418px #5151dd, -340px -456px #5151dd, -340px -494px #5151dd, -340px -532px #5151dd;
  }
  42.75% {
    box-shadow: 0 -38px #5151dd, 0 -76px #5151dd, 0 -114px #5151dd, 0 -152px #5151dd, -170px -190px #5151dd, -340px -228px #5151dd, -340px -266px #5151dd, -340px -304px #5151dd, -340px -342px #5151dd, -340px -380px #5151dd, -340px -418px #5151dd, -340px -456px #5151dd, -340px -494px #5151dd, -340px -532px #5151dd;
  }
  43.375% {
    box-shadow: 0 -38px #5151dd, 0 -76px #5151dd, 0 -114px #5151dd, 0 -152px #5151dd, 0 -190px #5151dd, -170px -228px #5151dd, -340px -266px #5151dd, -340px -304px #5151dd, -340px -342px #5151dd, -340px -380px #5151dd, -340px -418px #5151dd, -340px -456px #5151dd, -340px -494px #5151dd, -340px -532px #5151dd;
  }
  44% {
    box-shadow: 0 -38px #5151dd, 0 -76px #5151dd, 0 -114px #5151dd, 0 -152px #5151dd, 0 -190px #5151dd, 0 -228px #5151dd, -170px -266px #5151dd, -340px -304px #5151dd, -340px -342px #5151dd, -340px -380px #5151dd, -340px -418px #5151dd, -340px -456px #5151dd, -340px -494px #5151dd, -340px -532px #5151dd;
  }
  44.625% {
    box-shadow: 0 -38px #5151dd, 0 -76px #5151dd, 0 -114px #5151dd, 0 -152px #5151dd, 0 -190px #5151dd, 0 -228px #5151dd, 0 -266px #5151dd, -170px -304px #5151dd, -340px -342px #5151dd, -340px -380px #5151dd, -340px -418px #5151dd, -340px -456px #5151dd, -340px -494px #5151dd, -340px -532px #5151dd;
  }
  45.25% {
    box-shadow: 0 -38px #5151dd, 0 -76px #5151dd, 0 -114px #5151dd, 0 -152px #5151dd, 0 -190px #5151dd, 0 -228px #5151dd, 0 -266px #5151dd, 0 -304px #5151dd, -170px -342px #5151dd, -340px -380px #5151dd, -340px -418px #5151dd, -340px -456px #5151dd, -340px -494px #5151dd, -340px -532px #5151dd;
  }
  45.875% {
    box-shadow: 0 -38px #5151dd, 0 -76px #5151dd, 0 -114px #5151dd, 0 -152px #5151dd, 0 -190px #5151dd, 0 -228px #5151dd, 0 -266px #5151dd, 0 -304px #5151dd, 0 -342px #5151dd, -170px -380px #5151dd, -340px -418px #5151dd, -340px -456px #5151dd, -340px -494px #5151dd, -340px -532px #5151dd;
  }
  46.5% {
    box-shadow: 0 -38px #5151dd, 0 -76px #5151dd, 0 -114px #5151dd, 0 -152px #5151dd, 0 -190px #5151dd, 0 -228px #5151dd, 0 -266px #5151dd, 0 -304px #5151dd, 0 -342px #5151dd, 0 -380px #5151dd, -170px -418px #5151dd, -340px -456px #5151dd, -340px -494px #5151dd, -340px -532px #5151dd;
  }
  47.125% {
    box-shadow: 0 -38px #5151dd, 0 -76px #5151dd, 0 -114px #5151dd, 0 -152px #5151dd, 0 -190px #5151dd, 0 -228px #5151dd, 0 -266px #5151dd, 0 -304px #5151dd, 0 -342px #5151dd, 0 -380px #5151dd, 0 -418px #5151dd, -170px -456px #5151dd, -340px -494px #5151dd, -340px -532px #5151dd;
  }
  47.75% {
    box-shadow: 0 -38px #5151dd, 0 -76px #5151dd, 0 -114px #5151dd, 0 -152px #5151dd, 0 -190px #5151dd, 0 -228px #5151dd, 0 -266px #5151dd, 0 -304px #5151dd, 0 -342px #5151dd, 0 -380px #5151dd, 0 -418px #5151dd, 0 -456px #5151dd, -170px -494px #5151dd, -340px -532px #5151dd;
  }
  48.375% {
    box-shadow: 0 -38px #5151dd, 0 -76px #5151dd, 0 -114px #5151dd, 0 -152px #5151dd, 0 -190px #5151dd, 0 -228px #5151dd, 0 -266px #5151dd, 0 -304px #5151dd, 0 -342px #5151dd, 0 -380px #5151dd, 0 -418px #5151dd, 0 -456px #5151dd, 0 -494px #5151dd, -170px -532px #5151dd;
  }
  49% {
    box-shadow: 0 -38px #5151dd, 0 -76px #5151dd, 0 -114px #5151dd, 0 -152px #5151dd, 0 -190px #5151dd, 0 -228px #5151dd, 0 -266px #5151dd, 0 -304px #5151dd, 0 -342px #5151dd, 0 -380px #5151dd, 0 -418px #5151dd, 0 -456px #5151dd, 0 -494px #5151dd, 0 -532px #5151dd;
  }
}
@-webkit-keyframes cover-horizontal-purple {
  0% {
    width: 0;
    height: 0;
    box-shadow: none;
  }
  39% {
    width: 0;
    height: 38px;
  }
  39.625% {
    width: 170px;
    box-shadow: -340px -38px #5151dd, -340px -76px #5151dd, -340px -114px #5151dd, -340px -152px #5151dd, -340px -190px #5151dd, -340px -228px #5151dd, -340px -266px #5151dd, -340px -304px #5151dd, -340px -342px #5151dd, -340px -380px #5151dd, -340px -418px #5151dd, -340px -456px #5151dd, -340px -494px #5151dd, -340px -532px #5151dd;
  }
  40.25% {
    width: 340px;
    box-shadow: -170px -38px #5151dd, -340px -76px #5151dd, -340px -114px #5151dd, -340px -152px #5151dd, -340px -190px #5151dd, -340px -228px #5151dd, -340px -266px #5151dd, -340px -304px #5151dd, -340px -342px #5151dd, -340px -380px #5151dd, -340px -418px #5151dd, -340px -456px #5151dd, -340px -494px #5151dd, -340px -532px #5151dd;
  }
  40.875% {
    box-shadow: 0 -38px #5151dd, -170px -76px #5151dd, -340px -114px #5151dd, -340px -152px #5151dd, -340px -190px #5151dd, -340px -228px #5151dd, -340px -266px #5151dd, -340px -304px #5151dd, -340px -342px #5151dd, -340px -380px #5151dd, -340px -418px #5151dd, -340px -456px #5151dd, -340px -494px #5151dd, -340px -532px #5151dd;
  }
  41.5% {
    box-shadow: 0 -38px #5151dd, 0 -76px #5151dd, -170px -114px #5151dd, -340px -152px #5151dd, -340px -190px #5151dd, -340px -228px #5151dd, -340px -266px #5151dd, -340px -304px #5151dd, -340px -342px #5151dd, -340px -380px #5151dd, -340px -418px #5151dd, -340px -456px #5151dd, -340px -494px #5151dd, -340px -532px #5151dd;
  }
  42.125% {
    box-shadow: 0 -38px #5151dd, 0 -76px #5151dd, 0 -114px #5151dd, -170px -152px #5151dd, -340px -190px #5151dd, -340px -228px #5151dd, -340px -266px #5151dd, -340px -304px #5151dd, -340px -342px #5151dd, -340px -380px #5151dd, -340px -418px #5151dd, -340px -456px #5151dd, -340px -494px #5151dd, -340px -532px #5151dd;
  }
  42.75% {
    box-shadow: 0 -38px #5151dd, 0 -76px #5151dd, 0 -114px #5151dd, 0 -152px #5151dd, -170px -190px #5151dd, -340px -228px #5151dd, -340px -266px #5151dd, -340px -304px #5151dd, -340px -342px #5151dd, -340px -380px #5151dd, -340px -418px #5151dd, -340px -456px #5151dd, -340px -494px #5151dd, -340px -532px #5151dd;
  }
  43.375% {
    box-shadow: 0 -38px #5151dd, 0 -76px #5151dd, 0 -114px #5151dd, 0 -152px #5151dd, 0 -190px #5151dd, -170px -228px #5151dd, -340px -266px #5151dd, -340px -304px #5151dd, -340px -342px #5151dd, -340px -380px #5151dd, -340px -418px #5151dd, -340px -456px #5151dd, -340px -494px #5151dd, -340px -532px #5151dd;
  }
  44% {
    box-shadow: 0 -38px #5151dd, 0 -76px #5151dd, 0 -114px #5151dd, 0 -152px #5151dd, 0 -190px #5151dd, 0 -228px #5151dd, -170px -266px #5151dd, -340px -304px #5151dd, -340px -342px #5151dd, -340px -380px #5151dd, -340px -418px #5151dd, -340px -456px #5151dd, -340px -494px #5151dd, -340px -532px #5151dd;
  }
  44.625% {
    box-shadow: 0 -38px #5151dd, 0 -76px #5151dd, 0 -114px #5151dd, 0 -152px #5151dd, 0 -190px #5151dd, 0 -228px #5151dd, 0 -266px #5151dd, -170px -304px #5151dd, -340px -342px #5151dd, -340px -380px #5151dd, -340px -418px #5151dd, -340px -456px #5151dd, -340px -494px #5151dd, -340px -532px #5151dd;
  }
  45.25% {
    box-shadow: 0 -38px #5151dd, 0 -76px #5151dd, 0 -114px #5151dd, 0 -152px #5151dd, 0 -190px #5151dd, 0 -228px #5151dd, 0 -266px #5151dd, 0 -304px #5151dd, -170px -342px #5151dd, -340px -380px #5151dd, -340px -418px #5151dd, -340px -456px #5151dd, -340px -494px #5151dd, -340px -532px #5151dd;
  }
  45.875% {
    box-shadow: 0 -38px #5151dd, 0 -76px #5151dd, 0 -114px #5151dd, 0 -152px #5151dd, 0 -190px #5151dd, 0 -228px #5151dd, 0 -266px #5151dd, 0 -304px #5151dd, 0 -342px #5151dd, -170px -380px #5151dd, -340px -418px #5151dd, -340px -456px #5151dd, -340px -494px #5151dd, -340px -532px #5151dd;
  }
  46.5% {
    box-shadow: 0 -38px #5151dd, 0 -76px #5151dd, 0 -114px #5151dd, 0 -152px #5151dd, 0 -190px #5151dd, 0 -228px #5151dd, 0 -266px #5151dd, 0 -304px #5151dd, 0 -342px #5151dd, 0 -380px #5151dd, -170px -418px #5151dd, -340px -456px #5151dd, -340px -494px #5151dd, -340px -532px #5151dd;
  }
  47.125% {
    box-shadow: 0 -38px #5151dd, 0 -76px #5151dd, 0 -114px #5151dd, 0 -152px #5151dd, 0 -190px #5151dd, 0 -228px #5151dd, 0 -266px #5151dd, 0 -304px #5151dd, 0 -342px #5151dd, 0 -380px #5151dd, 0 -418px #5151dd, -170px -456px #5151dd, -340px -494px #5151dd, -340px -532px #5151dd;
  }
  47.75% {
    box-shadow: 0 -38px #5151dd, 0 -76px #5151dd, 0 -114px #5151dd, 0 -152px #5151dd, 0 -190px #5151dd, 0 -228px #5151dd, 0 -266px #5151dd, 0 -304px #5151dd, 0 -342px #5151dd, 0 -380px #5151dd, 0 -418px #5151dd, 0 -456px #5151dd, -170px -494px #5151dd, -340px -532px #5151dd;
  }
  48.375% {
    box-shadow: 0 -38px #5151dd, 0 -76px #5151dd, 0 -114px #5151dd, 0 -152px #5151dd, 0 -190px #5151dd, 0 -228px #5151dd, 0 -266px #5151dd, 0 -304px #5151dd, 0 -342px #5151dd, 0 -380px #5151dd, 0 -418px #5151dd, 0 -456px #5151dd, 0 -494px #5151dd, -170px -532px #5151dd;
  }
  49% {
    box-shadow: 0 -38px #5151dd, 0 -76px #5151dd, 0 -114px #5151dd, 0 -152px #5151dd, 0 -190px #5151dd, 0 -228px #5151dd, 0 -266px #5151dd, 0 -304px #5151dd, 0 -342px #5151dd, 0 -380px #5151dd, 0 -418px #5151dd, 0 -456px #5151dd, 0 -494px #5151dd, 0 -532px #5151dd;
  }
}
@keyframes cover-horizontal-purple {
  0% {
    width: 0;
    height: 0;
    box-shadow: none;
  }
  39% {
    width: 0;
    height: 38px;
  }
  39.625% {
    width: 170px;
    box-shadow: -340px -38px #5151dd, -340px -76px #5151dd, -340px -114px #5151dd, -340px -152px #5151dd, -340px -190px #5151dd, -340px -228px #5151dd, -340px -266px #5151dd, -340px -304px #5151dd, -340px -342px #5151dd, -340px -380px #5151dd, -340px -418px #5151dd, -340px -456px #5151dd, -340px -494px #5151dd, -340px -532px #5151dd;
  }
  40.25% {
    width: 340px;
    box-shadow: -170px -38px #5151dd, -340px -76px #5151dd, -340px -114px #5151dd, -340px -152px #5151dd, -340px -190px #5151dd, -340px -228px #5151dd, -340px -266px #5151dd, -340px -304px #5151dd, -340px -342px #5151dd, -340px -380px #5151dd, -340px -418px #5151dd, -340px -456px #5151dd, -340px -494px #5151dd, -340px -532px #5151dd;
  }
  40.875% {
    box-shadow: 0 -38px #5151dd, -170px -76px #5151dd, -340px -114px #5151dd, -340px -152px #5151dd, -340px -190px #5151dd, -340px -228px #5151dd, -340px -266px #5151dd, -340px -304px #5151dd, -340px -342px #5151dd, -340px -380px #5151dd, -340px -418px #5151dd, -340px -456px #5151dd, -340px -494px #5151dd, -340px -532px #5151dd;
  }
  41.5% {
    box-shadow: 0 -38px #5151dd, 0 -76px #5151dd, -170px -114px #5151dd, -340px -152px #5151dd, -340px -190px #5151dd, -340px -228px #5151dd, -340px -266px #5151dd, -340px -304px #5151dd, -340px -342px #5151dd, -340px -380px #5151dd, -340px -418px #5151dd, -340px -456px #5151dd, -340px -494px #5151dd, -340px -532px #5151dd;
  }
  42.125% {
    box-shadow: 0 -38px #5151dd, 0 -76px #5151dd, 0 -114px #5151dd, -170px -152px #5151dd, -340px -190px #5151dd, -340px -228px #5151dd, -340px -266px #5151dd, -340px -304px #5151dd, -340px -342px #5151dd, -340px -380px #5151dd, -340px -418px #5151dd, -340px -456px #5151dd, -340px -494px #5151dd, -340px -532px #5151dd;
  }
  42.75% {
    box-shadow: 0 -38px #5151dd, 0 -76px #5151dd, 0 -114px #5151dd, 0 -152px #5151dd, -170px -190px #5151dd, -340px -228px #5151dd, -340px -266px #5151dd, -340px -304px #5151dd, -340px -342px #5151dd, -340px -380px #5151dd, -340px -418px #5151dd, -340px -456px #5151dd, -340px -494px #5151dd, -340px -532px #5151dd;
  }
  43.375% {
    box-shadow: 0 -38px #5151dd, 0 -76px #5151dd, 0 -114px #5151dd, 0 -152px #5151dd, 0 -190px #5151dd, -170px -228px #5151dd, -340px -266px #5151dd, -340px -304px #5151dd, -340px -342px #5151dd, -340px -380px #5151dd, -340px -418px #5151dd, -340px -456px #5151dd, -340px -494px #5151dd, -340px -532px #5151dd;
  }
  44% {
    box-shadow: 0 -38px #5151dd, 0 -76px #5151dd, 0 -114px #5151dd, 0 -152px #5151dd, 0 -190px #5151dd, 0 -228px #5151dd, -170px -266px #5151dd, -340px -304px #5151dd, -340px -342px #5151dd, -340px -380px #5151dd, -340px -418px #5151dd, -340px -456px #5151dd, -340px -494px #5151dd, -340px -532px #5151dd;
  }
  44.625% {
    box-shadow: 0 -38px #5151dd, 0 -76px #5151dd, 0 -114px #5151dd, 0 -152px #5151dd, 0 -190px #5151dd, 0 -228px #5151dd, 0 -266px #5151dd, -170px -304px #5151dd, -340px -342px #5151dd, -340px -380px #5151dd, -340px -418px #5151dd, -340px -456px #5151dd, -340px -494px #5151dd, -340px -532px #5151dd;
  }
  45.25% {
    box-shadow: 0 -38px #5151dd, 0 -76px #5151dd, 0 -114px #5151dd, 0 -152px #5151dd, 0 -190px #5151dd, 0 -228px #5151dd, 0 -266px #5151dd, 0 -304px #5151dd, -170px -342px #5151dd, -340px -380px #5151dd, -340px -418px #5151dd, -340px -456px #5151dd, -340px -494px #5151dd, -340px -532px #5151dd;
  }
  45.875% {
    box-shadow: 0 -38px #5151dd, 0 -76px #5151dd, 0 -114px #5151dd, 0 -152px #5151dd, 0 -190px #5151dd, 0 -228px #5151dd, 0 -266px #5151dd, 0 -304px #5151dd, 0 -342px #5151dd, -170px -380px #5151dd, -340px -418px #5151dd, -340px -456px #5151dd, -340px -494px #5151dd, -340px -532px #5151dd;
  }
  46.5% {
    box-shadow: 0 -38px #5151dd, 0 -76px #5151dd, 0 -114px #5151dd, 0 -152px #5151dd, 0 -190px #5151dd, 0 -228px #5151dd, 0 -266px #5151dd, 0 -304px #5151dd, 0 -342px #5151dd, 0 -380px #5151dd, -170px -418px #5151dd, -340px -456px #5151dd, -340px -494px #5151dd, -340px -532px #5151dd;
  }
  47.125% {
    box-shadow: 0 -38px #5151dd, 0 -76px #5151dd, 0 -114px #5151dd, 0 -152px #5151dd, 0 -190px #5151dd, 0 -228px #5151dd, 0 -266px #5151dd, 0 -304px #5151dd, 0 -342px #5151dd, 0 -380px #5151dd, 0 -418px #5151dd, -170px -456px #5151dd, -340px -494px #5151dd, -340px -532px #5151dd;
  }
  47.75% {
    box-shadow: 0 -38px #5151dd, 0 -76px #5151dd, 0 -114px #5151dd, 0 -152px #5151dd, 0 -190px #5151dd, 0 -228px #5151dd, 0 -266px #5151dd, 0 -304px #5151dd, 0 -342px #5151dd, 0 -380px #5151dd, 0 -418px #5151dd, 0 -456px #5151dd, -170px -494px #5151dd, -340px -532px #5151dd;
  }
  48.375% {
    box-shadow: 0 -38px #5151dd, 0 -76px #5151dd, 0 -114px #5151dd, 0 -152px #5151dd, 0 -190px #5151dd, 0 -228px #5151dd, 0 -266px #5151dd, 0 -304px #5151dd, 0 -342px #5151dd, 0 -380px #5151dd, 0 -418px #5151dd, 0 -456px #5151dd, 0 -494px #5151dd, -170px -532px #5151dd;
  }
  49% {
    box-shadow: 0 -38px #5151dd, 0 -76px #5151dd, 0 -114px #5151dd, 0 -152px #5151dd, 0 -190px #5151dd, 0 -228px #5151dd, 0 -266px #5151dd, 0 -304px #5151dd, 0 -342px #5151dd, 0 -380px #5151dd, 0 -418px #5151dd, 0 -456px #5151dd, 0 -494px #5151dd, 0 -532px #5151dd;
  }
}
#gameboy.purple #cover-horizontal {
  background-color: #5151dd;
  -webkit-animation: cover-horizontal-purple 8s   linear;
  animation: cover-horizontal-purple 8s   linear;
  box-shadow: 0 -38px #5151dd, 0 -76px #5151dd, 0 -114px #5151dd, 0 -152px #5151dd, 0 -190px #5151dd, 0 -228px #5151dd, 0 -266px #5151dd, 0 -304px #5151dd, 0 -342px #5151dd, 0 -380px #5151dd, 0 -418px #5151dd, 0 -456px #5151dd, 0 -494px #5151dd, 0 -532px #5151dd;
}
@-moz-keyframes cover-horizontal-red {
  0% {
    width: 0;
    height: 0;
    box-shadow: none;
  }
  39% {
    width: 0;
    height: 38px;
  }
  39.625% {
    width: 170px;
    box-shadow: -340px -38px #ff0151, -340px -76px #ff0151, -340px -114px #ff0151, -340px -152px #ff0151, -340px -190px #ff0151, -340px -228px #ff0151, -340px -266px #ff0151, -340px -304px #ff0151, -340px -342px #ff0151, -340px -380px #ff0151, -340px -418px #ff0151, -340px -456px #ff0151, -340px -494px #ff0151, -340px -532px #ff0151;
  }
  40.25% {
    width: 340px;
    box-shadow: -170px -38px #ff0151, -340px -76px #ff0151, -340px -114px #ff0151, -340px -152px #ff0151, -340px -190px #ff0151, -340px -228px #ff0151, -340px -266px #ff0151, -340px -304px #ff0151, -340px -342px #ff0151, -340px -380px #ff0151, -340px -418px #ff0151, -340px -456px #ff0151, -340px -494px #ff0151, -340px -532px #ff0151;
  }
  40.875% {
    box-shadow: 0 -38px #ff0151, -170px -76px #ff0151, -340px -114px #ff0151, -340px -152px #ff0151, -340px -190px #ff0151, -340px -228px #ff0151, -340px -266px #ff0151, -340px -304px #ff0151, -340px -342px #ff0151, -340px -380px #ff0151, -340px -418px #ff0151, -340px -456px #ff0151, -340px -494px #ff0151, -340px -532px #ff0151;
  }
  41.5% {
    box-shadow: 0 -38px #ff0151, 0 -76px #ff0151, -170px -114px #ff0151, -340px -152px #ff0151, -340px -190px #ff0151, -340px -228px #ff0151, -340px -266px #ff0151, -340px -304px #ff0151, -340px -342px #ff0151, -340px -380px #ff0151, -340px -418px #ff0151, -340px -456px #ff0151, -340px -494px #ff0151, -340px -532px #ff0151;
  }
  42.125% {
    box-shadow: 0 -38px #ff0151, 0 -76px #ff0151, 0 -114px #ff0151, -170px -152px #ff0151, -340px -190px #ff0151, -340px -228px #ff0151, -340px -266px #ff0151, -340px -304px #ff0151, -340px -342px #ff0151, -340px -380px #ff0151, -340px -418px #ff0151, -340px -456px #ff0151, -340px -494px #ff0151, -340px -532px #ff0151;
  }
  42.75% {
    box-shadow: 0 -38px #ff0151, 0 -76px #ff0151, 0 -114px #ff0151, 0 -152px #ff0151, -170px -190px #ff0151, -340px -228px #ff0151, -340px -266px #ff0151, -340px -304px #ff0151, -340px -342px #ff0151, -340px -380px #ff0151, -340px -418px #ff0151, -340px -456px #ff0151, -340px -494px #ff0151, -340px -532px #ff0151;
  }
  43.375% {
    box-shadow: 0 -38px #ff0151, 0 -76px #ff0151, 0 -114px #ff0151, 0 -152px #ff0151, 0 -190px #ff0151, -170px -228px #ff0151, -340px -266px #ff0151, -340px -304px #ff0151, -340px -342px #ff0151, -340px -380px #ff0151, -340px -418px #ff0151, -340px -456px #ff0151, -340px -494px #ff0151, -340px -532px #ff0151;
  }
  44% {
    box-shadow: 0 -38px #ff0151, 0 -76px #ff0151, 0 -114px #ff0151, 0 -152px #ff0151, 0 -190px #ff0151, 0 -228px #ff0151, -170px -266px #ff0151, -340px -304px #ff0151, -340px -342px #ff0151, -340px -380px #ff0151, -340px -418px #ff0151, -340px -456px #ff0151, -340px -494px #ff0151, -340px -532px #ff0151;
  }
  44.625% {
    box-shadow: 0 -38px #ff0151, 0 -76px #ff0151, 0 -114px #ff0151, 0 -152px #ff0151, 0 -190px #ff0151, 0 -228px #ff0151, 0 -266px #ff0151, -170px -304px #ff0151, -340px -342px #ff0151, -340px -380px #ff0151, -340px -418px #ff0151, -340px -456px #ff0151, -340px -494px #ff0151, -340px -532px #ff0151;
  }
  45.25% {
    box-shadow: 0 -38px #ff0151, 0 -76px #ff0151, 0 -114px #ff0151, 0 -152px #ff0151, 0 -190px #ff0151, 0 -228px #ff0151, 0 -266px #ff0151, 0 -304px #ff0151, -170px -342px #ff0151, -340px -380px #ff0151, -340px -418px #ff0151, -340px -456px #ff0151, -340px -494px #ff0151, -340px -532px #ff0151;
  }
  45.875% {
    box-shadow: 0 -38px #ff0151, 0 -76px #ff0151, 0 -114px #ff0151, 0 -152px #ff0151, 0 -190px #ff0151, 0 -228px #ff0151, 0 -266px #ff0151, 0 -304px #ff0151, 0 -342px #ff0151, -170px -380px #ff0151, -340px -418px #ff0151, -340px -456px #ff0151, -340px -494px #ff0151, -340px -532px #ff0151;
  }
  46.5% {
    box-shadow: 0 -38px #ff0151, 0 -76px #ff0151, 0 -114px #ff0151, 0 -152px #ff0151, 0 -190px #ff0151, 0 -228px #ff0151, 0 -266px #ff0151, 0 -304px #ff0151, 0 -342px #ff0151, 0 -380px #ff0151, -170px -418px #ff0151, -340px -456px #ff0151, -340px -494px #ff0151, -340px -532px #ff0151;
  }
  47.125% {
    box-shadow: 0 -38px #ff0151, 0 -76px #ff0151, 0 -114px #ff0151, 0 -152px #ff0151, 0 -190px #ff0151, 0 -228px #ff0151, 0 -266px #ff0151, 0 -304px #ff0151, 0 -342px #ff0151, 0 -380px #ff0151, 0 -418px #ff0151, -170px -456px #ff0151, -340px -494px #ff0151, -340px -532px #ff0151;
  }
  47.75% {
    box-shadow: 0 -38px #ff0151, 0 -76px #ff0151, 0 -114px #ff0151, 0 -152px #ff0151, 0 -190px #ff0151, 0 -228px #ff0151, 0 -266px #ff0151, 0 -304px #ff0151, 0 -342px #ff0151, 0 -380px #ff0151, 0 -418px #ff0151, 0 -456px #ff0151, -170px -494px #ff0151, -340px -532px #ff0151;
  }
  48.375% {
    box-shadow: 0 -38px #ff0151, 0 -76px #ff0151, 0 -114px #ff0151, 0 -152px #ff0151, 0 -190px #ff0151, 0 -228px #ff0151, 0 -266px #ff0151, 0 -304px #ff0151, 0 -342px #ff0151, 0 -380px #ff0151, 0 -418px #ff0151, 0 -456px #ff0151, 0 -494px #ff0151, -170px -532px #ff0151;
  }
  49% {
    box-shadow: 0 -38px #ff0151, 0 -76px #ff0151, 0 -114px #ff0151, 0 -152px #ff0151, 0 -190px #ff0151, 0 -228px #ff0151, 0 -266px #ff0151, 0 -304px #ff0151, 0 -342px #ff0151, 0 -380px #ff0151, 0 -418px #ff0151, 0 -456px #ff0151, 0 -494px #ff0151, 0 -532px #ff0151;
  }
}
@-webkit-keyframes cover-horizontal-red {
  0% {
    width: 0;
    height: 0;
    box-shadow: none;
  }
  39% {
    width: 0;
    height: 38px;
  }
  39.625% {
    width: 170px;
    box-shadow: -340px -38px #ff0151, -340px -76px #ff0151, -340px -114px #ff0151, -340px -152px #ff0151, -340px -190px #ff0151, -340px -228px #ff0151, -340px -266px #ff0151, -340px -304px #ff0151, -340px -342px #ff0151, -340px -380px #ff0151, -340px -418px #ff0151, -340px -456px #ff0151, -340px -494px #ff0151, -340px -532px #ff0151;
  }
  40.25% {
    width: 340px;
    box-shadow: -170px -38px #ff0151, -340px -76px #ff0151, -340px -114px #ff0151, -340px -152px #ff0151, -340px -190px #ff0151, -340px -228px #ff0151, -340px -266px #ff0151, -340px -304px #ff0151, -340px -342px #ff0151, -340px -380px #ff0151, -340px -418px #ff0151, -340px -456px #ff0151, -340px -494px #ff0151, -340px -532px #ff0151;
  }
  40.875% {
    box-shadow: 0 -38px #ff0151, -170px -76px #ff0151, -340px -114px #ff0151, -340px -152px #ff0151, -340px -190px #ff0151, -340px -228px #ff0151, -340px -266px #ff0151, -340px -304px #ff0151, -340px -342px #ff0151, -340px -380px #ff0151, -340px -418px #ff0151, -340px -456px #ff0151, -340px -494px #ff0151, -340px -532px #ff0151;
  }
  41.5% {
    box-shadow: 0 -38px #ff0151, 0 -76px #ff0151, -170px -114px #ff0151, -340px -152px #ff0151, -340px -190px #ff0151, -340px -228px #ff0151, -340px -266px #ff0151, -340px -304px #ff0151, -340px -342px #ff0151, -340px -380px #ff0151, -340px -418px #ff0151, -340px -456px #ff0151, -340px -494px #ff0151, -340px -532px #ff0151;
  }
  42.125% {
    box-shadow: 0 -38px #ff0151, 0 -76px #ff0151, 0 -114px #ff0151, -170px -152px #ff0151, -340px -190px #ff0151, -340px -228px #ff0151, -340px -266px #ff0151, -340px -304px #ff0151, -340px -342px #ff0151, -340px -380px #ff0151, -340px -418px #ff0151, -340px -456px #ff0151, -340px -494px #ff0151, -340px -532px #ff0151;
  }
  42.75% {
    box-shadow: 0 -38px #ff0151, 0 -76px #ff0151, 0 -114px #ff0151, 0 -152px #ff0151, -170px -190px #ff0151, -340px -228px #ff0151, -340px -266px #ff0151, -340px -304px #ff0151, -340px -342px #ff0151, -340px -380px #ff0151, -340px -418px #ff0151, -340px -456px #ff0151, -340px -494px #ff0151, -340px -532px #ff0151;
  }
  43.375% {
    box-shadow: 0 -38px #ff0151, 0 -76px #ff0151, 0 -114px #ff0151, 0 -152px #ff0151, 0 -190px #ff0151, -170px -228px #ff0151, -340px -266px #ff0151, -340px -304px #ff0151, -340px -342px #ff0151, -340px -380px #ff0151, -340px -418px #ff0151, -340px -456px #ff0151, -340px -494px #ff0151, -340px -532px #ff0151;
  }
  44% {
    box-shadow: 0 -38px #ff0151, 0 -76px #ff0151, 0 -114px #ff0151, 0 -152px #ff0151, 0 -190px #ff0151, 0 -228px #ff0151, -170px -266px #ff0151, -340px -304px #ff0151, -340px -342px #ff0151, -340px -380px #ff0151, -340px -418px #ff0151, -340px -456px #ff0151, -340px -494px #ff0151, -340px -532px #ff0151;
  }
  44.625% {
    box-shadow: 0 -38px #ff0151, 0 -76px #ff0151, 0 -114px #ff0151, 0 -152px #ff0151, 0 -190px #ff0151, 0 -228px #ff0151, 0 -266px #ff0151, -170px -304px #ff0151, -340px -342px #ff0151, -340px -380px #ff0151, -340px -418px #ff0151, -340px -456px #ff0151, -340px -494px #ff0151, -340px -532px #ff0151;
  }
  45.25% {
    box-shadow: 0 -38px #ff0151, 0 -76px #ff0151, 0 -114px #ff0151, 0 -152px #ff0151, 0 -190px #ff0151, 0 -228px #ff0151, 0 -266px #ff0151, 0 -304px #ff0151, -170px -342px #ff0151, -340px -380px #ff0151, -340px -418px #ff0151, -340px -456px #ff0151, -340px -494px #ff0151, -340px -532px #ff0151;
  }
  45.875% {
    box-shadow: 0 -38px #ff0151, 0 -76px #ff0151, 0 -114px #ff0151, 0 -152px #ff0151, 0 -190px #ff0151, 0 -228px #ff0151, 0 -266px #ff0151, 0 -304px #ff0151, 0 -342px #ff0151, -170px -380px #ff0151, -340px -418px #ff0151, -340px -456px #ff0151, -340px -494px #ff0151, -340px -532px #ff0151;
  }
  46.5% {
    box-shadow: 0 -38px #ff0151, 0 -76px #ff0151, 0 -114px #ff0151, 0 -152px #ff0151, 0 -190px #ff0151, 0 -228px #ff0151, 0 -266px #ff0151, 0 -304px #ff0151, 0 -342px #ff0151, 0 -380px #ff0151, -170px -418px #ff0151, -340px -456px #ff0151, -340px -494px #ff0151, -340px -532px #ff0151;
  }
  47.125% {
    box-shadow: 0 -38px #ff0151, 0 -76px #ff0151, 0 -114px #ff0151, 0 -152px #ff0151, 0 -190px #ff0151, 0 -228px #ff0151, 0 -266px #ff0151, 0 -304px #ff0151, 0 -342px #ff0151, 0 -380px #ff0151, 0 -418px #ff0151, -170px -456px #ff0151, -340px -494px #ff0151, -340px -532px #ff0151;
  }
  47.75% {
    box-shadow: 0 -38px #ff0151, 0 -76px #ff0151, 0 -114px #ff0151, 0 -152px #ff0151, 0 -190px #ff0151, 0 -228px #ff0151, 0 -266px #ff0151, 0 -304px #ff0151, 0 -342px #ff0151, 0 -380px #ff0151, 0 -418px #ff0151, 0 -456px #ff0151, -170px -494px #ff0151, -340px -532px #ff0151;
  }
  48.375% {
    box-shadow: 0 -38px #ff0151, 0 -76px #ff0151, 0 -114px #ff0151, 0 -152px #ff0151, 0 -190px #ff0151, 0 -228px #ff0151, 0 -266px #ff0151, 0 -304px #ff0151, 0 -342px #ff0151, 0 -380px #ff0151, 0 -418px #ff0151, 0 -456px #ff0151, 0 -494px #ff0151, -170px -532px #ff0151;
  }
  49% {
    box-shadow: 0 -38px #ff0151, 0 -76px #ff0151, 0 -114px #ff0151, 0 -152px #ff0151, 0 -190px #ff0151, 0 -228px #ff0151, 0 -266px #ff0151, 0 -304px #ff0151, 0 -342px #ff0151, 0 -380px #ff0151, 0 -418px #ff0151, 0 -456px #ff0151, 0 -494px #ff0151, 0 -532px #ff0151;
  }
}
@keyframes cover-horizontal-red {
  0% {
    width: 0;
    height: 0;
    box-shadow: none;
  }
  39% {
    width: 0;
    height: 38px;
  }
  39.625% {
    width: 170px;
    box-shadow: -340px -38px #ff0151, -340px -76px #ff0151, -340px -114px #ff0151, -340px -152px #ff0151, -340px -190px #ff0151, -340px -228px #ff0151, -340px -266px #ff0151, -340px -304px #ff0151, -340px -342px #ff0151, -340px -380px #ff0151, -340px -418px #ff0151, -340px -456px #ff0151, -340px -494px #ff0151, -340px -532px #ff0151;
  }
  40.25% {
    width: 340px;
    box-shadow: -170px -38px #ff0151, -340px -76px #ff0151, -340px -114px #ff0151, -340px -152px #ff0151, -340px -190px #ff0151, -340px -228px #ff0151, -340px -266px #ff0151, -340px -304px #ff0151, -340px -342px #ff0151, -340px -380px #ff0151, -340px -418px #ff0151, -340px -456px #ff0151, -340px -494px #ff0151, -340px -532px #ff0151;
  }
  40.875% {
    box-shadow: 0 -38px #ff0151, -170px -76px #ff0151, -340px -114px #ff0151, -340px -152px #ff0151, -340px -190px #ff0151, -340px -228px #ff0151, -340px -266px #ff0151, -340px -304px #ff0151, -340px -342px #ff0151, -340px -380px #ff0151, -340px -418px #ff0151, -340px -456px #ff0151, -340px -494px #ff0151, -340px -532px #ff0151;
  }
  41.5% {
    box-shadow: 0 -38px #ff0151, 0 -76px #ff0151, -170px -114px #ff0151, -340px -152px #ff0151, -340px -190px #ff0151, -340px -228px #ff0151, -340px -266px #ff0151, -340px -304px #ff0151, -340px -342px #ff0151, -340px -380px #ff0151, -340px -418px #ff0151, -340px -456px #ff0151, -340px -494px #ff0151, -340px -532px #ff0151;
  }
  42.125% {
    box-shadow: 0 -38px #ff0151, 0 -76px #ff0151, 0 -114px #ff0151, -170px -152px #ff0151, -340px -190px #ff0151, -340px -228px #ff0151, -340px -266px #ff0151, -340px -304px #ff0151, -340px -342px #ff0151, -340px -380px #ff0151, -340px -418px #ff0151, -340px -456px #ff0151, -340px -494px #ff0151, -340px -532px #ff0151;
  }
  42.75% {
    box-shadow: 0 -38px #ff0151, 0 -76px #ff0151, 0 -114px #ff0151, 0 -152px #ff0151, -170px -190px #ff0151, -340px -228px #ff0151, -340px -266px #ff0151, -340px -304px #ff0151, -340px -342px #ff0151, -340px -380px #ff0151, -340px -418px #ff0151, -340px -456px #ff0151, -340px -494px #ff0151, -340px -532px #ff0151;
  }
  43.375% {
    box-shadow: 0 -38px #ff0151, 0 -76px #ff0151, 0 -114px #ff0151, 0 -152px #ff0151, 0 -190px #ff0151, -170px -228px #ff0151, -340px -266px #ff0151, -340px -304px #ff0151, -340px -342px #ff0151, -340px -380px #ff0151, -340px -418px #ff0151, -340px -456px #ff0151, -340px -494px #ff0151, -340px -532px #ff0151;
  }
  44% {
    box-shadow: 0 -38px #ff0151, 0 -76px #ff0151, 0 -114px #ff0151, 0 -152px #ff0151, 0 -190px #ff0151, 0 -228px #ff0151, -170px -266px #ff0151, -340px -304px #ff0151, -340px -342px #ff0151, -340px -380px #ff0151, -340px -418px #ff0151, -340px -456px #ff0151, -340px -494px #ff0151, -340px -532px #ff0151;
  }
  44.625% {
    box-shadow: 0 -38px #ff0151, 0 -76px #ff0151, 0 -114px #ff0151, 0 -152px #ff0151, 0 -190px #ff0151, 0 -228px #ff0151, 0 -266px #ff0151, -170px -304px #ff0151, -340px -342px #ff0151, -340px -380px #ff0151, -340px -418px #ff0151, -340px -456px #ff0151, -340px -494px #ff0151, -340px -532px #ff0151;
  }
  45.25% {
    box-shadow: 0 -38px #ff0151, 0 -76px #ff0151, 0 -114px #ff0151, 0 -152px #ff0151, 0 -190px #ff0151, 0 -228px #ff0151, 0 -266px #ff0151, 0 -304px #ff0151, -170px -342px #ff0151, -340px -380px #ff0151, -340px -418px #ff0151, -340px -456px #ff0151, -340px -494px #ff0151, -340px -532px #ff0151;
  }
  45.875% {
    box-shadow: 0 -38px #ff0151, 0 -76px #ff0151, 0 -114px #ff0151, 0 -152px #ff0151, 0 -190px #ff0151, 0 -228px #ff0151, 0 -266px #ff0151, 0 -304px #ff0151, 0 -342px #ff0151, -170px -380px #ff0151, -340px -418px #ff0151, -340px -456px #ff0151, -340px -494px #ff0151, -340px -532px #ff0151;
  }
  46.5% {
    box-shadow: 0 -38px #ff0151, 0 -76px #ff0151, 0 -114px #ff0151, 0 -152px #ff0151, 0 -190px #ff0151, 0 -228px #ff0151, 0 -266px #ff0151, 0 -304px #ff0151, 0 -342px #ff0151, 0 -380px #ff0151, -170px -418px #ff0151, -340px -456px #ff0151, -340px -494px #ff0151, -340px -532px #ff0151;
  }
  47.125% {
    box-shadow: 0 -38px #ff0151, 0 -76px #ff0151, 0 -114px #ff0151, 0 -152px #ff0151, 0 -190px #ff0151, 0 -228px #ff0151, 0 -266px #ff0151, 0 -304px #ff0151, 0 -342px #ff0151, 0 -380px #ff0151, 0 -418px #ff0151, -170px -456px #ff0151, -340px -494px #ff0151, -340px -532px #ff0151;
  }
  47.75% {
    box-shadow: 0 -38px #ff0151, 0 -76px #ff0151, 0 -114px #ff0151, 0 -152px #ff0151, 0 -190px #ff0151, 0 -228px #ff0151, 0 -266px #ff0151, 0 -304px #ff0151, 0 -342px #ff0151, 0 -380px #ff0151, 0 -418px #ff0151, 0 -456px #ff0151, -170px -494px #ff0151, -340px -532px #ff0151;
  }
  48.375% {
    box-shadow: 0 -38px #ff0151, 0 -76px #ff0151, 0 -114px #ff0151, 0 -152px #ff0151, 0 -190px #ff0151, 0 -228px #ff0151, 0 -266px #ff0151, 0 -304px #ff0151, 0 -342px #ff0151, 0 -380px #ff0151, 0 -418px #ff0151, 0 -456px #ff0151, 0 -494px #ff0151, -170px -532px #ff0151;
  }
  49% {
    box-shadow: 0 -38px #ff0151, 0 -76px #ff0151, 0 -114px #ff0151, 0 -152px #ff0151, 0 -190px #ff0151, 0 -228px #ff0151, 0 -266px #ff0151, 0 -304px #ff0151, 0 -342px #ff0151, 0 -380px #ff0151, 0 -418px #ff0151, 0 -456px #ff0151, 0 -494px #ff0151, 0 -532px #ff0151;
  }
}
#gameboy.red #cover-horizontal {
  background-color: #ff0151;
  -webkit-animation: cover-horizontal-red 8s   linear;
  animation: cover-horizontal-red 8s   linear;
  box-shadow: 0 -38px #ff0151, 0 -76px #ff0151, 0 -114px #ff0151, 0 -152px #ff0151, 0 -190px #ff0151, 0 -228px #ff0151, 0 -266px #ff0151, 0 -304px #ff0151, 0 -342px #ff0151, 0 -380px #ff0151, 0 -418px #ff0151, 0 -456px #ff0151, 0 -494px #ff0151, 0 -532px #ff0151;
}
@-moz-keyframes gloss {
  0% {
    width: 0;
    height: 0;
    left: 170px;
  }
  46% {
    width: 0;
    height: 0;
    left: 170px;
  }
  52% {
    width: 232px;
    height: 250px;
    left: 54px;
  }
}
@-webkit-keyframes gloss {
  0% {
    width: 0;
    height: 0;
    left: 170px;
  }
  46% {
    width: 0;
    height: 0;
    left: 170px;
  }
  52% {
    width: 232px;
    height: 250px;
    left: 54px;
  }
}
@keyframes gloss {
  0% {
    width: 0;
    height: 0;
    left: 170px;
  }
  46% {
    width: 0;
    height: 0;
    left: 170px;
  }
  52% {
    width: 232px;
    height: 250px;
    left: 54px;
  }
}
#gloss {
  position: absolute;
  z-index: 13;
  width: 232px;
  height: 250px;
  top: 275px;
  left: 54px;
  border-bottom-left-radius: 116px 20px;
  border-bottom-right-radius: 116px 20px;
  -webkit-animation: gloss 8s   linear;
  animation: gloss 8s   linear;
}
#gameboy.transparent #gloss {
  background-color: rgba(242, 232, 251, 0.43125);
}
#gameboy.teal #gloss {
  background-color: #1abce0;
}
#gameboy.yellow #gloss {
  background-color: #faea58;
}
#gameboy.green #gloss {
  background-color: #97e77e;
}
#gameboy.purple #gloss {
  background-color: #5a5adf;
}
#gameboy.red #gloss {
  background-color: #ff1a62;
}
@-moz-keyframes speaker-holes {
  0% {
    width: 0;
  }
  41% {
    width: 0;
  }
  42% {
    width: 5px;
  }
}
@-webkit-keyframes speaker-holes {
  0% {
    width: 0;
  }
  41% {
    width: 0;
  }
  42% {
    width: 5px;
  }
}
@keyframes speaker-holes {
  0% {
    width: 0;
  }
  41% {
    width: 0;
  }
  42% {
    width: 5px;
  }
}
#speaker-holes {
  background-color: #222222;
  width: 5px;
  height: 5px;
  position: absolute;
  bottom: 42px;
  left: 244px;
  z-index: 14;
  border-radius: 50%;
  box-shadow: 9.5px 8px #222222, 19px 16px #222222, 0px -19px #222222, 9.5px -11px #222222, 19px -3px #222222, 28.5px 5px #222222, 38px 13px #222222, 0px -38px #222222, 9.5px -30px #222222, 19px -22px #222222, 28.5px -14px #222222, 38px -6px #222222, 47.5px 2px #222222, 57px 10px #222222, 9.5px -49px #222222, 19px -41px #222222, 28.5px -33px #222222, 38px -25px #222222, 47.5px -17px #222222, 57px -9px #222222, 66.5px -1px #222222, 28px -52px #222222, 37.5px -44px #222222, 47px -36px #222222, 56.5px -28px #222222, 66px -20px #222222, 47px -56px #222222, 56.5px -48px #222222, 66px -40px #222222;
  -webkit-animation: speaker-holes 8s   linear;
  animation: speaker-holes 8s   linear;
}
@-moz-keyframes power {
  0% {
    opacity: 0;
    background-color: #303030;
    box-shadow: none;
  }
  36% {
    opacity: 0;
  }
  37% {
    opacity: 1;
  }
  39% {
    box-shadow: 5px 0 #222222, 8px 0 #222222, 12px 0 #222222, 15px 0 #222222, 19px 0 #222222, 22px 0 #222222;
  }
  40% {
    box-shadow: 5px 0 #222222, 8px 0 #b0b0b0, 12px 0 #222222, 15px 0 #b0b0b0, 19px 0 #222222, 22px 0 #b0b0b0;
  }
  54% {
    background-color: #303030;
  }
  55% {
    background-color: #ff0151;
  }
}
@-webkit-keyframes power {
  0% {
    opacity: 0;
    background-color: #303030;
    box-shadow: none;
  }
  36% {
    opacity: 0;
  }
  37% {
    opacity: 1;
  }
  39% {
    box-shadow: 5px 0 #222222, 8px 0 #222222, 12px 0 #222222, 15px 0 #222222, 19px 0 #222222, 22px 0 #222222;
  }
  40% {
    box-shadow: 5px 0 #222222, 8px 0 #b0b0b0, 12px 0 #222222, 15px 0 #b0b0b0, 19px 0 #222222, 22px 0 #b0b0b0;
  }
  54% {
    background-color: #303030;
  }
  55% {
    background-color: #ff0151;
  }
}
@keyframes power {
  0% {
    opacity: 0;
    background-color: #303030;
    box-shadow: none;
  }
  36% {
    opacity: 0;
  }
  37% {
    opacity: 1;
  }
  39% {
    box-shadow: 5px 0 #222222, 8px 0 #222222, 12px 0 #222222, 15px 0 #222222, 19px 0 #222222, 22px 0 #222222;
  }
  40% {
    box-shadow: 5px 0 #222222, 8px 0 #b0b0b0, 12px 0 #222222, 15px 0 #b0b0b0, 19px 0 #222222, 22px 0 #b0b0b0;
  }
  54% {
    background-color: #303030;
  }
  55% {
    background-color: #ff0151;
  }
}
#power {
  position: absolute;
  z-index: 15;
  top: 95px;
  left: 35px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #ff0151;
  box-shadow: 5px 0 #222222, 8px 0 #b0b0b0, 12px 0 #222222, 15px 0 #b0b0b0, 19px 0 #222222, 22px 0 #b0b0b0;
  -webkit-animation: power 8s   linear;
  animation: power 8s   linear;
}
#colors {
  font-family: 'Oxygen', Helvetica, arial, sans-serif;
  font-weight: 300;
  width: 100%;
  margin: 0 auto 40px;
  text-align: center;
}
#colors span {
  vertical-align: top;
}
#colors .color {
  cursor: pointer;
  width: 20px;
  height: 20px;
  display: inline-block;
  margin-left: 5px;
  border-radius: 3px;
}
#colors .color.active,
#colors .color:hover {
  opacity: 1 !important;
}
#colors .color[data-color="transparent"] {
  border: 1px #9d9d9d dotted;
  opacity: 0.4;
  background: rgba(140, 46, 217, 0.125);
}
#colors .color[data-color="red"] {
  opacity: 0.1;
  background: #ff0151;
}
#colors .color[data-color="purple"] {
  opacity: 0.1;
  background: #5151dd;
}
#colors .color[data-color="green"] {
  opacity: 0.2;
  background: #85e367;
}
#colors .color[data-color="yellow"] {
  opacity: 0.3;
  background: #f9e52e;
}
#colors .color[data-color="teal"] {
  opacity: 0.15;
  background: #01b4dd;
}

.black-screen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: 0;
    z-index: 500; /* Ajuster le z-index pour ne pas couvrir les lettres */
    animation: powerOn 1s forwards; /* Applique l'animation d'allumage */
  }
  
.black-screen.show {
    opacity: 1;
}

@keyframes powerOn {
    0% {
      opacity: 0;
      background-color: white;
    }
    5% {
      opacity: 0.1;
      background-color: #f2f2f2;
    }
    10% {
      opacity: 0.2;
      background-color: #e6e6e6;
    }
    15% {
      opacity: 0.3;
      background-color: #d9d9d9;
    }
    20% {
      opacity: 0.4;
      background-color: #cccccc;
    }
    25% {
      opacity: 0.5;
      background-color: #bfbfbf;
    }
    30% {
      opacity: 0.6;
      background-color: #b3b3b3;
    }
    35% {
      opacity: 0.7;
      background-color: #a6a6a6;
    }
    40% {
      opacity: 0.8;
      background-color: #999999;
    }
    45% {
      opacity: 0.9;
      background-color: #8c8c8c;
    }
    50% {
      opacity: 1;
      background-color: #808080;
    }
    55% {
      opacity: 1;
      background-color: #737373;
    }
    60% {
      opacity: 1;
      background-color: #666666;
    }
    65% {
      opacity: 1;
      background-color: #595959;
    }
    70% {
      opacity: 1;
      background-color: #4d4d4d;
    }
    75% {
      opacity: 1;
      background-color: #404040;
    }
    80% {
      opacity: 1;
      background-color: #333333;
    }
    85% {
      opacity: 1;
      background-color: #262626;
    }
    90% {
      opacity: 1;
      background-color: #1a1a1a;
    }
    95% {
      opacity: 1;
      background-color: #0d0d0d;
    }
    100% {
      opacity: 1;
      background-color: black;
    }
  }


.conteiner {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000; /* S'assurer que les lettres apparaissent au-dessus de tout */
  }
  
  .conteiner ul {
    padding: 0;
    margin: 0;
  }
  
  .conteiner ul li {
    list-style: none;
    display: inline-block;
    font-family: "Press Start 2P", system-ui;
    font-weight: 400;
    font-size: 40pt;
    color: #ffffff;
  }
  
  /* Media query pour les petits écrans */
  @media only screen and (max-width: 600px) {
    .conteiner ul li {
      display: block; /* Les éléments s'affichent en bloc (une sous l'autre) */
      font-size: 20pt; /* Taille de la police réduite */
    }
  }
  
  .f {
    animation: color 5s linear, mover_g 1s linear;
  }
  
  .r {
    animation: color 5s .1s linear, mover_g 1s .1s linear;
  }
  
  .o {
    animation: color 5s .2s linear, mover_g 1s .2s linear;
  }
  
  .n {
    animation: color 5s .3s linear, mover_g 1s .3s linear;
  }
  
  .t {
    animation: color 5s .4s linear, mover_g 1s .4s linear;
  }
  
  .h {
    animation: color 5s .5s linear, mover_g 1s .5s linear;
  }
  
  .e {
    animation: color 5s linear, mover_g 1s linear;
  }
  
  .nn {
    animation: color 5s .1s linear, mover_g 1s .1s linear;
  }
  
  .d {
    animation: color 5s .2s linear, mover_g 1s .2s linear;
  }
  
  .x {
    animation: color 5s .3s linear, mover_g 1s .3s linear;
  }
  
  .w {
    animation: color 5s .4s linear, mover_g 1s .4s linear;
  }
  
  .p {
    animation: color 5s .5s linear, mover_g 1s .5s linear;
  }
  
  .u {
    animation: color 5s .6s linear, mover_g 1s .6s linear;
  }
  
  @keyframes color {
    10% {
      color: pink;
    }
    20% {
      color: red;
    }
    30% {
      color: orange;
    }
    40% {
      color: yellow;
    }
    50% {
      color: green;
    }
    60% {
      color: #66ffff;
    }
    80% {
      color: #000066;
    }
    100% {
      color: #000066;
    }
  }
  
  
  @keyframes mover_g {
    0% {
      transform: translate(-50px, 100px) scale(3, 3);
      opacity: 0;
    }
    10% {
      transform: translate(-40px, -50px) scale(9, 9);
    }
    20% {
      transform: translate(-30px, -40px) scale(8, 8);
    }
    30% {
      transform: translate(-20px, -30px) scale(7, 7);
    }
    40% {
      transform: translate(-10px, -20px) scale(6, 6);
      opacity: 1;
    }
    50% {
      transform: translate(0px, -10px) scale(5, 5);
    }
    60% {
      transform: translate(0px, 0px) scale(4, 4);
    }
    70% {
      transform: translate(0px, 0px) scale(1, 1);
    }
    100% {
      transform: translate(0px, 0px) scale(1, 1);
    }
  }
  