/* Img accueil */
.gameboydmg{
  width: 130px;
  height: auto;
  position: relative;
}

.gameboyadvence{
  width: 300px;  
  height: auto; 
  position: relative;
}

.gameboyadvencesp{
  width: 300px;  
  height: auto; 
  position: relative;
}

.gameboycolor{
  width: 400px;  
  height: auto;  
  position: relative;
}


/* Page panier */

.entete{
  color: black;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;

}

.cadre {
  border: 2px solid black;
  padding: 50px;
  width: 300px;
  height: 130px;
  border-radius: 35px;
}


/* Espace a modifier */
.section1 .espace{
  border: 3px #ffffff;
  padding: 10px;
}

.section1{
  border: 2px solid ;
  height: 100;
  padding-left: 40px;


}



/* Panier */

.cadre1{
  border: 2px solid black;
  padding-left: 30px;
  border-radius: 35px;
  width: 100px;
  height: 50px;
}

.separation {
  width: 2px;
  background-color: black;
}

.bar-noir {
  background-color: black;
  height: 2px;  
  width: 83%;
  position: absolute;
  top: 20%;
  left: 9%;
}
/* Bouton commande */

.boutoncom link{
  background-color: white;
  border: none;
  color: black;
  text-align: center; 
  text-decoration: none;
}


/* Page commande */

.container-xl{
  max-width: 1200px; 
  margin: 0 auto;
}


/* Largeur de la page */
.containerbase {
  width: 80%;
  max-width: 1200px;
  margin: 0 auto;
}

/* Text pas coller aux side */
.container-sidespace{
  padding: 50px;
}
